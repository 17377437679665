

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { WebsiteService } from '../websiteservice/website.service';

import { DanpheCareContact } from '../../cms/models/danphecare.cms.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DanpheCareReachUsQuicklyModel } from '../../cms/models/danphecare/danphecare.model';
import { DanphecareService } from '../services/danphe.service';
import { NotificationService } from '../services/notification.service';
import { MetaService } from '@ngx-meta/core';
/*import { trigger } from '@angular/animations';*/


@Component({
  selector: 'app-swasthpalika-page',
  templateUrl: './swasthpalika.component.html',
  styleUrls: ["./swasthpalika.component.css"]
})
export class SwasthpalikaComponent implements OnInit, AfterViewInit {
  public DhcareRuq: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public DhcareRuq1: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public contact: DanpheCareContact = new DanpheCareContact();
  public loading: boolean = false;
  public loading1: boolean = false;
  public submitted: boolean = false;
  public submitted1: boolean = false;
  public isCompany: boolean = false;
  public form: FormGroup;
  public form1: FormGroup;
  public selServices: any;
  public selServices1: any;
  filteredPage = [
    { id: 1, title: "Kathmandu" },
    { id: 2, title: "Pokhara" },
    { id: 3, title: "Butwal" },
    { id: 4, title: "Chitwan" },
    { id: 5, title: "Dhangadi" }
  ];
  filteredPage1 = [
    { id: 1, title: "Kathmandu" },
    { id: 2, title: "Pokhara" },
    { id: 3, title: "Butwal" },
    { id: 4, title: "Chitwan" },
    { id: 5, title: "Dhangadi" }
  ];
  constructor(public websiteService: WebsiteService, private notifyService: NotificationService, private formBuilder: FormBuilder,
    public danphecareservice: DanphecareService, private meta: MetaService) {
    //
  }

  ngOnInit() {
    //this.meta.setTitle("An initiative by Danphe Care and Hamro Patro");
    //this.meta.setTag("description", "Committed to your Health and Wellbeing. Now offering tele medicine services for covid patients");
    //this.meta.setTag('og:title', "An initiative by Danphe Care and Hamro Patro");
    //this.meta.setTag('og:image', "https://cms.danphecare.com/assets/images/hamropatrosocialimage.jpg");
    //this.meta.setTag('og:image:width', "800");
    //this.meta.setTag('og:image:height', "800");
    //this.meta.setTag('og:url', location.href);
    //this.meta.setTag('og:type', "Hamro Patro");
    //this.meta.setTag('og:description', "Committed to your Health and Wellbeing. Now offering tele medicine services for covid patients");
    //this.meta.setTag('twitter:card', location.href);
    //this.meta.setTag('twitter:title', "An initiative by Danphe Care and Hamro Patro");
    //this.meta.setTag('twitter:description', "Committed to your Health and Wellbeing. Now offering tele medicine services for covid patients");
    //this.meta.setTag('twitter:image', "https://cms.danphecare.com/assets/images/hamropatrosocialimage.jpg");
    //this.meta.setTag('og:app_id', "260103905625004");
    this.GetContact();
    this.selServices = this.filteredPage[0].title;
    this.selServices1 = this.filteredPage1[0].title;

    this.form = this.formBuilder.group({
      phone: ['', [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)],
      ]
    });

    this.form1 = this.formBuilder.group({
      phone: ['', [Validators.required]],
      name: ["", [Validators.required]]
    });


  }
  ngAfterViewInit() {
    //
  }

  get phoneControl1() {
    return this.form.get("phone");
  }
  get emailControl1() {
    return this.form.get("email");
  }

  get phoneControl2() {
    return this.form1.get("phone");
  }
  get nameControl2() {
    return this.form1.get("name");
  }


  GetContact() {
    this.websiteService.GetContact().subscribe(res => {
      if (res && res.length > 0) {
        this.contact = Object.assign(this.contact, res[0]);

      }
    },
      res => {
        //this.notifyService.showError("Error", "Internal Error")
      });
  }

  onSubmitReachUsQuickly() {

    if (this.form.valid) {
      this.loading = true;
      this.DhcareRuq.company = this.selServices.title
      this.danphecareservice.reachUsQuickly(this.DhcareRuq)
        .subscribe(res => this.SuccessPostReachUsQuickly(res),
          res => this.Error(res));

    }
    else {
      this.loading = false;
      this.submitted = true;
      this.notifyService.showInfo("Info", "Please enter the required field");
      (this.submitted) ? setTimeout(() => { this.submitted = false }, 5250) : null
    }

  }
  SuccessPostReachUsQuickly(res) {
    this.loading = false;
    this.DhcareRuq = new DanpheCareReachUsQuicklyModel();
    this.notifyService.showSuccess('Success', 'application submitted successfully ');
    this.form.reset();
  }

  onSubmitReachUsQuickly1() {

    if (this.form1.valid) {
      this.loading1 = true;
      this.DhcareRuq1.company = this.selServices1.title
      this.danphecareservice.reachUsQuickly(this.DhcareRuq1)
        .subscribe(res => this.SuccessPostReachUsQuickly1(res),
          res => this.Error(res));

    }
    else {
      this.loading1 = false;
      this.submitted1 = true;
      this.notifyService.showInfo("Info", "Please enter the required field");
      (this.submitted1) ? setTimeout(() => { this.submitted1 = false }, 5250) : null
    }

  }
  SuccessPostReachUsQuickly1(res) {
    this.loading1 = false;
    this.DhcareRuq1 = new DanpheCareReachUsQuicklyModel();
    this.notifyService.showSuccess('Success', 'application submitted successfully ');
    this.form1.reset();
  }

  Error(res) {
    this.loading = false;
    const response = res;
    this.notifyService.showError("Error", " Please fill up the required field")
  }
}
