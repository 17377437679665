
<div class="section page-top">
  <div class="ver-2 text-center">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="justify-content-center hero-text-center">
            <div class="hero-text mt-0"><b>Department</b></div>
            <h4></h4>
          </div>
        </div>
        <div class="col text-right">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="[ '/', 'homepage' ]">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Blog</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="blog-wrapper pt-5 pb-5">
  <div class="container">
    <div class="carousel slide multi-item-carousel" id="theCarousel">
      <div class="carousel-inner row w-100 mx-auto">
        <div class="carousel-items col-md-3" *ngFor="let department of departmentList">
          <a [routerLink]="['/department', department.permaLink]" >
          <div class="special-item text-center" >
            <div class="icon"><img [src]="department.iconPath" alt="logo" /> </div>
            <h3>{{department.departmentName}}</h3>
            <a class="links">Consult Now <i class="fa fa-angle-right"></i></a>
          </div>
          </a>
        </div>
      </div>
      <a class="carousel-control-prev" href="#theCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#theCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</section>
<style>
  .light-over-pages {
    position: absolute;
    background: linear-gradient(180deg, rgb(255 255 255 / 70%), rgb(189 189 189 / 40%));
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    top: 0;
    left: 0;
  }

  .list-style {
    padding: 0;
  }

    .list-style li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin: 5px 0;
    }

      .list-style li span {
        display: block;
      }

      .list-style li:before {
        content: "\f00c";
        font-family: FontAwesome;
        background-color: #0db696;
        color: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 15px;
        height: 15px;
        align-items: center;
        font-size: 0.7rem;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 0;
      }

  .home-isolation .sub-heading, .emmergency-info .sub-heading {
    font-weight: 700;
    font-size: 1.5rem;
  }

  .center-title {
  }

    .center-title h4 {
    }
</style>

