import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { Career } from "../../cms/models/career.model/career.model";
import { DanphecareService } from "../services/danphe.service";
import { NotificationService } from "../services/notification.service";
import { TeleMedService } from "../websiteservice/teleMedService";
import { WebsiteService } from "../websiteservice/website.service";

@Component({
  templateUrl: './career-list.html',
  styleUrls: ['./career-list.css']

})

export class CareerListComponent implements OnInit {

  public CareerList: Array<Career> = [];

  constructor(public websiteService: WebsiteService, private notifyService: NotificationService, public router: Router,
    public danphecareservice: DanphecareService) {

  }

  ngOnInit(): void {
    this.GetAllCareerList();
  }

  public GetAllCareerList() {
    this.websiteService.GetCareerDetailsList().subscribe(res => {
      if (res) {
        this.CareerList = [];
        this.CareerList = Object.assign(this.CareerList, res);
        this.CareerList.forEach(career => career.picturePath = environment.url + career.picturePath.substr(2));
      }
    },
      err => {
        console.log("Failed to load career. Error Details:" + err.toString())
      });
  }

  ReadMore(permalink) {
    this.router.navigate(['/careerDetails', permalink]);
  }


}
