<div class="modal-box" *ngIf="popUpContent.showPopUp" (keyup.esc)="closePopUp()">
  <div class=" box">
    <div class="button cross" (click)="closePopUp()"><em class="fa fa-times"></em></div>
    <div class="header" *ngIf="showHeading">
      <h1>{{popUpContent.Heading}}</h1>
    </div>
    <div class="content">
      <aside aria-label="pop up image" *ngIf="popUpContent.showImage">
        <img [src]="popUpContent.imagePath" alt="pop-image">
      </aside>
      <aside aria-label="pop up texts" class="pop-text" *ngIf="showTextContent">
        {{popUpContent.PrimaryInformation}}
        <p>
          {{popUpContent.SecondaryInformation}}
        </p>
      </aside>
    </div>
    <div class="buttons">
      <div class="button ok" (click)="closePopUp()">OK</div>
    </div>
  </div>
</div>
