
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from "jquery";
import { WebsiteService } from '../websiteservice/website.service';

import { DanpheCareDepartment, MetaTag } from '../../cms/models/danphecare.cms.model';
import { Title, Meta } from '@angular/platform-browser';
import { NotificationService } from '../services/notification.service';
import { MetaService } from '@ngx-meta/core';
// declare var $: any;

@Component({
  selector: 'app-department-list',
  templateUrl: './departmentdetails-list.component.html'
})

export class DepartmentDetailsListComponent implements OnInit {
  public departmentList: Array<DanpheCareDepartment> = new Array<DanpheCareDepartment>();
  public departmentId: number;
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public tag: MetaTag = new MetaTag();
  constructor(public routing: Router, public websiteService: WebsiteService, public notifyService: NotificationService, private titleService: Title, private metaService: Meta,
    private  meta: MetaService) {



  }
  ngOnInit() {   
    this.websiteService.GetDepartment().subscribe(res => {
      if (res) {
        this.departmentList = [];

        this.departmentList = Object.assign(this.departmentList, res);
        this.departmentList.forEach((item, index) => {
          if (item.imagePath !== null) {
            item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
          }
          if (item.iconPath !== null) {
            item.iconPath = "https://cms.danphecare.com" + item.iconPath.substr(2);
          }

        });

        this.meta.setTitle(this.departmentList[0].metaTitle);
        this.meta.setTag("description", this.departmentList[0].metaContent);
        this.meta.setTag("keywords", this.departmentList[0].metaKeywords);
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Blogs Found!");
      });
  }
  DepartmentDetails(permaLink) {
    //this.departmentId = id;
    this.routing.navigate(['/department', permaLink]);
  }

}
