import { Component, OnInit } from "@angular/core";

@Component({
    templateUrl: './billing-final.html'
})

export class FinalBillingComponent implements OnInit {
    
    constructor(){

    }
    ngOnInit(){

    }
}