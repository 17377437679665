

import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WebsiteService } from '../websiteservice/website.service';

import { DanpheCareDepartment, DanpheCareDoctor, DanpheCareContact, ResourcefulArticles, DepartmentSubHeading, Blogs, DepartmentTabcontent, MetaTag } from '../../cms/models/danphecare.cms.model';
import { OurServiceModel } from '../../cms/models/OurServices.model';
import { Title, Meta } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MetaService } from '@ngx-meta/core';
import { DanpheCareReachUsQuicklyModel } from '../../cms/models/danphecare/danphecare.model';
import { NotificationService } from '../services/notification.service';
import { DanphecareService } from '../services/danphe.service';
import { isPlatformBrowser } from '@angular/common';



@Component({
  selector: 'app-department-details-page',
  templateUrl: './departmentdetails-page.component.html'
})
export class DepartmentDetailsPageComponent implements OnInit, AfterViewInit {
  public departmentdetailId: string;
  public departmentId: number;
  public departmentPageId: number;
  public departmentDetails: any;
  public departmentFaq: Array<DepartmentTabcontent> = new Array<DepartmentTabcontent>();
  public deptFaq= true;
  public departmentComponents: any;
  public departmentSetup: any;
  public departmentWarranty: any;
  public departmentService: any;
  public servicesList: Array<OurServiceModel> = new Array<OurServiceModel>();
  public servicesId: number;
  public articlesId: number;
  public doctorId: number;
  public reachForm1: FormGroup;
  public submitForm1: boolean = false;
  public reachForm2 = new FormGroup({ phonenumber2: new FormControl(), name2: new FormControl()});
  public submitForm2: boolean = false;
  public reachForm3 = new FormGroup({ phonenumber3: new FormControl(), name3: new FormControl(), email3: new FormControl() });;
  public submitForm3: boolean = false;
  public departmentList: Array<DanpheCareDepartment> = new Array<DanpheCareDepartment>();
  public subheadingList: Array<DepartmentSubHeading> = new Array<DepartmentSubHeading>();
  public doctorList: Array<DanpheCareDoctor> = new Array<DanpheCareDoctor>();
  public articlesList: Array<ResourcefulArticles> = new Array<ResourcefulArticles>();
  public contact: DanpheCareContact = new DanpheCareContact();
  public department: DanpheCareDepartment = new DanpheCareDepartment();
  public loading1: boolean = false;
  public loading2: boolean = false;
  public loading3: boolean = false;
  public content: string;

  //public departmentId: number;
  //expert = require("../content/img/about-expert.png");
  // isolation = require("../content/img/home-isolation.png");
  // initiation1 = require("../content/img/initiation1.png");
  // safety = require("../content/img/initiation2.png");
  // learning = require("../content/img/initiation3.png");
  public blogsList: Array<Blogs> = new Array<Blogs>();
  public blogId: number;
  public IsSubHeading = true;
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public tag: MetaTag = new MetaTag();
  public showModelBox: boolean = false;
  public DhcareRuq: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public DhcareRuq1: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public DhcareRuq2: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public permaLink: any;

  constructor(public routing: Router, private route: ActivatedRoute, public websiteService: WebsiteService, public formBuilder: FormBuilder,
    private notifyService: NotificationService, private titleService: Title, private metaService: Meta,
    public danphecareservice: DanphecareService, private meta: MetaService, @Inject(PLATFORM_ID) private platformId) {
   
      this.route.params.subscribe((params: Params) => {
        this.permaLink = params.permalink;
       
      });
   
  }

  ngOnInit() {

    this.websiteService.GetDepartmentById(this.permaLink).subscribe(res => {
      this.department = res;
      if (this.department.imagePath !== null) {
        this.department.imagePath = "https://cms.danphecare.com" + this.department.imagePath.substr(2);
      }
      if (this.department.iconPath !== null) {
        this.department.iconPath = "https://cms.danphecare.com" + this.department.iconPath.substr(2);
      }
      this.content = "";
/*      this.content = this.department.introduction.replace(/<[^>]*>/g, '').trim();*/
    /*  this.content = this.department.introduction.replace(/(?:^(?:&nbsp;)+)| (?:^(?:<p>)+) |(?:(?:&nbsp;)+$)/g, '').trim();*/
      //this.content = this.department.introduction.replace(/(<([^>]+)>)/gi, "");
      //this.content = this.content.slice(0, 150);
      this.meta.setTitle(this.department.metaTitle);
      this.meta.setTag("description", this.department.metaContent);
      this.meta.setTag("keywords", this.department.metaKeywords);
      this.meta.setTag('og:title', this.department.title);
      this.meta.setTag('og:image', this.department.imagePath);
      //this.meta.setTag('og:image:width', "800");
      //this.meta.setTag('og:image:height', "600");
      this.meta.setTag('og:url', location.href);
      this.meta.setTag('og:type', "Department");
      this.meta.setTag('og:description', this.department.metaContent);
      this.meta.setTag('twitter:card', location.href);
      this.meta.setTag('twitter:title', this.department.title);
      this.meta.setTag('twitter:description', this.department.metaContent);
      this.meta.setTag('twitter:image', this.department.imagePath);
      this.meta.setTag('og:app_id', "260103905625004"); 

    },
      res => {
        //this.notifyService.showError("Info", "No Blogs Found!");
      }); 

    this.GetDepartmentTabContent(this.permaLink);
    this.GetDepartmentById(this.permaLink);
    this.GetDoctorByDepartmentId(this.permaLink);
    this.GetDepartment();
    this.GetContact();
    this.GetBlogs();
    this.GetDepartmentSubHeading();

    this.routing.routeReuseStrategy.shouldReuseRoute = () => false;

    this.IninitialValidation();
  }
  ngAfterViewInit() {
    //
  }

  IninitialValidation() {
    this.reachForm2 = this.formBuilder.group({
      phonenumber2: ['', [Validators.required]],
      name2: ['', [Validators.required]]
    });
    this.reachForm3 = this.formBuilder.group({
      phonenumber3: ['', [Validators.required]],
      name3: ['', [Validators.required]],
      email3: ["", [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)],
      ]
    });
  }
  
  
  GetDepartmentTabContent(permalink) {
    this.websiteService.GetDepartmentTabContentById(permalink)
      .subscribe(res => this.SuccessTabContent(res),
        res => this.Error(res));
  }
  SuccessTabContent(res) {
    this.departmentDetails = res;
    this.departmentFaq = res.filter(x => x.tabName === 'FAQs');
    if (this.departmentFaq.length === 0) {
      this.deptFaq = false;
    }
    this.departmentComponents = res.filter(x => x.tabName === 'Components');
    this.departmentSetup = res.filter(x => x.tabName === 'Shock Setup');
    this.departmentWarranty = res.filter(x => x.tabName === 'Warranty and Registration');
  }
  Error(res) {
    //this.notifyService.showError("Info", "No Details Found!");
  }

  GetServiceByDepartment(permalink) {
    this.websiteService.GetServiceByDepartmentId(permalink)
      .subscribe(res => this.SuccessServices(res),
        res => this.ErrorServices(res));
  }
  SuccessServices(res) {

    this.departmentService = res;
    this.departmentService.forEach((item, index) => {
      if (item.iconPath !== null) {
        item.iconPath = "https://cms.danphecare.com" + item.iconPath.substr(2);
      }
      if (item.imagePath !== null) {
        item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
      }
      if (item.coverImage !== null) {
        item.coverImage = "https://cms.danphecare.com" + item.coverImage.substr(2);
      }
      if (item.subscriptionImage !== null) {
        item.subscriptionImage = "https://cms.danphecare.com" + item.subscriptionImage.substr(2);
      }
    });
  }
  ErrorServices(res) {
    //this.notifyService.showError("Info", "No Services Found!");
  }

  GetDoctorByDepartmentId(permalink) {
    this.websiteService.GetDoctorByDepartmentId(permalink)
      .subscribe(res => this.SuccessDoctor(res),
        res => this.ErrorDoctor(res));
  }
  SuccessDoctor(res) {
    this.doctorList = res;
    this.doctorList.forEach((item, index) => {
      //if (item.coverPhoto !== null) {
      //  item.coverPhoto = "assets" + item.coverPhoto.substr(2);
      //}
      if (item.imagePath !== null) {
        item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
      }
     
    });
  }
  ErrorDoctor(res) {
    //this.notifyService.showError("Info", "No Doctor Found!");
  }

  GetDepartmentById(permalink) {
    this.websiteService.GetDepartmentById(permalink)
      .subscribe(res => this.SuccessDepartmentById(res),
        res => this.ErrorDepartment(res));
  }
  SuccessDepartmentById(res) {

    this.department = res;
    if (this.department.imagePath !== null) {
      this.department.imagePath = "https://cms.danphecare.com" + this.department.imagePath.substr(2);
    }
    if (this.department.iconPath !== null) {
      this.department.iconPath = "https://cms.danphecare.com" + this.department.iconPath.substr(2);
    }
   
  }

  GetDepartment() {
    this.websiteService.GetDepartment()
      .subscribe(res => this.SuccessDepartment(res),
        res => this.ErrorDepartment(res));
  }
  SuccessDepartment(res) {
    this.departmentList = res;
    this.departmentList = res.filter((dep, idx) => idx < 4);
    this.departmentList.forEach((item, index) => {
      if (item.iconPath !== null) {
        item.iconPath = "https://cms.danphecare.com" + item.iconPath.substr(2);
      }
      if (item.imagePath !== null) {
        item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
      }
     
    });
  }

  ErrorDepartment(res) {
    //this.notifyService.showError("Info", "No Department Found!");
  }

  GetDepartmentSubHeading() {
    this.websiteService.GetSubHeadingByDepartmentId(this.permaLink)
      .subscribe(res => this.SuccessDepartmentSubHeading(res),
        res => this.ErrorDepartmentSubHeading(res));
  }
  SuccessDepartmentSubHeading(res) {
    this.subheadingList = res;
    this.subheadingList.forEach((item, index) => {
      item.iconPath = "https://cms.danphecare.com" + item.iconPath.substr(2);
    });
    if (res.length === 0) {
      this.IsSubHeading = false;
    }
  }
  ErrorDepartmentSubHeading(res) {
    //this.notifyService.showError("Info", "No Department SubHeading Found!");
  }

  GetServices() {
    this.websiteService.GetOurServices()
      .subscribe(res => this.SuccessServicesList(res),
        res => this.ErrorServicesList(res));
  }
  SuccessServicesList(res) {
  
    //this.servicesList = res;
    this.servicesList = res.filter((dep, idx) => idx < 3);
    this.servicesList.forEach((item, index) => {
      if (item.iconPath !== null) {
        item.iconPath = "https://cms.danphecare.com" + item.iconPath.substr(2);
      }
      if (item.imagePath !== null) {
        item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
      }
      if (item.coverImage !== null) {
        item.coverImage = "https://cms.danphecare.com" + item.coverImage.substr(2);
      }
      if (item.subscriptionImage !== null) {
        item.subscriptionImage = "https://cms.danphecare.com" + item.subscriptionImage.substr(2);
      }
    });
  }
  ErrorServicesList(res) {
    //this.notifyService.showError("Info", "No Services Found!");
  }
  GetContact() {
    this.websiteService.GetContact().subscribe(res => {
      if (res && res.length > 0) {
        this.contact = Object.assign(this.contact, res[0]);

      }
    },
      res => {
        //this.notifyService.showError("Error", "Internal Error")
      });
  }
  GetResourcefulArticles() {
    this.websiteService.GetArticles()
      .subscribe(res => this.SuccessArticles(res),
        res => this.ErrorArticles(res));
  }
  SuccessArticles(res) {
    this.articlesList = res;
    this.articlesList.forEach((item, index) => {
      item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
      
    });
  }
  ErrorArticles(res) {
    //this.notifyService.showError("Info", "No Articles Found!");
  }

  GoToArticlesPage(id) {
    this.articlesId = id;
    this.routing.navigate(['/articlespage'], { queryParams: { id: this.articlesId } });
  }
  GoToProfile(permaLink) {
   /* this.doctorId = id;*/

    this.routing.navigate(['/profilepage', permaLink]);
  }
  GotToService(id) {
    this.servicesId = id;
    this.routing.navigate(['/service'], { queryParams: { id: this.servicesId } });
  }
  DepartmentDetails(permaLink) {
    this.routing.navigate(['/department', permaLink]);
    this.GetDepartmentTabContent(this.departmentPageId);
    this.GetServices();
    this.GetDepartment();
    this.GetDoctorByDepartmentId(permaLink);
    this.GetContact();
    this.GetResourcefulArticles();
  }

  GetBlogs() {
    this.websiteService.GetBlogs().subscribe(res => {
      if (res) {
        this.blogsList = [];
        this.blogsList = Object.assign(this.blogsList, res);
        this.blogsList.forEach((item, index) => {
          item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
        });
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Blogs Found!");
      });
  }
  GoToBlogDetails(permaLink) {
    //this.blogId = id;
    this.routing.navigate(['/blog', permaLink]);


  }
  get PhoneNumberControl2() {
    if (this.reachForm2) {
      return this.reachForm2.get('phonenumber2') as FormControl;
    }
    /*return this.reachForm2.get("phonenumber2") ;*/
  }
  
  get NameControl2() {
    if (this.reachForm2) {
      return this.reachForm2.get('name2') as FormControl;
    }
  }

  get PhoneNumberControl3() {
    if (this.reachForm3) {
      return this.reachForm3.get('phonenumber3') as FormControl;
    }
  }

  get nameControl3() {
    if (this.reachForm3) {
      return this.reachForm3.get('name3') as FormControl;
    }
  }
  get EmailControl3() {
    if (this.reachForm3) {
      return this.reachForm3.get('email3') as FormControl;
    }
  }

  onSubmitBook() {
    if (this.reachForm2.valid) {
      this.submitForm2 = true;
      this.loading2 = true;
      this.danphecareservice.reachUsQuickly(this.DhcareRuq1)
        .subscribe(res => this.SuccessPostBook(res),
          res => this.Error1(res));
    }
    else {
      this.submitForm2 = true;
      this.loading2 = false;
      this.notifyService.showInfo("Info", "Please enter the required field");
      //this.reachForm2.reset();
      (this.submitForm2) ? setTimeout(() => { this.submitForm2 = false }, 5250) : null 
    }
      
    
  }
  SuccessPostBook(res) {
    this.loading2 = false;
    this.DhcareRuq1 = new DanpheCareReachUsQuicklyModel();
    this.notifyService.showSuccess('success', 'application submitted successfully ');
    this.submitForm2 = false;
    this.reachForm2.reset();
  }
  hide() {
    this.showModelBox = false;
    this.submitForm2 = false;
    this.loading2 = false;
    this.reachForm2.reset();
  }
  open() {
    this.showModelBox = true;
  }
  Error1(res) {
    const response = res;
    this.loading2 = false;
    this.notifyService.showError("Error", " Please fill up the required field")
  }

  onSubmitReachUsQuickly() {
    if (this.reachForm1.valid) {
      this.submitForm1 = true;
      this.loading1 = true;
      this.danphecareservice.reachUsQuickly(this.DhcareRuq)
        .subscribe(res => this.SuccessPostReachUsQuickly(res),
          res => this.Error1(res));
    }
    else {
      this.submitForm1 = true;
      this.loading1 = true;
      //this.reachForm1.reset();
      this.notifyService.showInfo("Info", "Please enter the required field");
      (this.submitForm1) ? setTimeout(() => { this.submitForm1 = false }, 5250) : null 
    }
  }
  SuccessPostReachUsQuickly(res) {
    this.loading1 = false;
    this.DhcareRuq = new DanpheCareReachUsQuicklyModel();
    this.notifyService.showSuccess('success', 'application submitted successfully ');
    this.submitForm1 = false;
    this.reachForm1.reset();
  }
  onSubmitReachUsQuickly1() {
    
    if (this.reachForm3.valid) {
      this.submitForm3 = true;
      this.loading3 = true;
      this.danphecareservice.reachUsQuickly(this.DhcareRuq2)
        .subscribe(res => this.SuccessPostReachUsQuickly1(res),
          res => this.Error1(res));
    }
    else {
      this.submitForm3 = true;
      this.loading3 = false;
      
      this.notifyService.showInfo("Info", "Please enter the required field");
      (this.submitForm3) ? setTimeout(() => { this.submitForm3 = false }, 5250) : null 
      //this.reachForm3.reset();
    }
  }
  SuccessPostReachUsQuickly1(res) {
    this.loading3 = false;
    this.DhcareRuq2 = new DanpheCareReachUsQuicklyModel();
    this.notifyService.showSuccess('success', 'application submitted successfully ');
    this.submitForm3 = false;
    this.reachForm3.reset();
  }
}
