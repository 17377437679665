<div id="ceo-message">
  <div class="section-title main-title">
    <b style="color: #04c4ff;">
      <h3>Founders Desk</h3>
    </b>
  </div>
  <div class="ceo-message-container">
    <aside>
      <img src="/assets/images/ceo/ceo.jpeg" alt="ceo-image">
    </aside>

    <h3>“SHE DID NOT STAND ALONE, BUT WHAT STOOD BEHIND HER,THE MOST POTENT MORAL FORCE IN HER LIFE, WAS THE LOVE AND FAITH OF HER FATHER”</h3>
    <p>I recall my childhood incidence when I was about four and half years old. We were in a
      market when my eyes fell on an ice cream parlour. As always I asked my dad to get me a
      cone to which he said if I wanted it, I had to get it myself. I was quite nervous since it was
      the first time I was approaching a stranger. I hesitantly went for it and after I got it, I was
      very proud of myself and told my father “ See dad!! I’ve got it!!” From that day I realised
      that you have to face fears of yourself, and step-up when the situation arises.</p>
    <p>It was during the last lockdown that idea of We Care Health Center and Studio Dentale was
      conceptualized. Just out of college after completing my postgraduation with a Gold medal
      from the prestigious Manipal University , I wasn’t sure if I was ready to take this massive
      project head on. My father being an entrepreneur asked me “Who according to you is an
      entrepreneur?” I was quite taken back and was searching for answers. He said “it is
      someone who has enough courage to do things – courage to undertake what others only
      dream of ” .From that day I realised that you have to face fears of yourself, and step-up no
      matter what. My dads presence in my nascent yet fascinating journey has been an
      invaluable asset for me as I know that he is there to guide me through my path with his vast
      entrepreneurial experiences whenever I need it.
    </p>
    <p>I hold a special place in my heart for the name “Studio Dentale “ as I always wanted to
      create a dental studio for designer digital smiles and also because of my love for dental
      photography.The name also mirrors my passion to ensure a surreal dental experience to all
      my patients who have placed their trust in my team to deliver quality care.</p>
    <p>From there on, We Care Health Center and Studio Dentale have come a long way with
      expansion in the terms of machinery, space and manpower with about 42 team members
      currently , from only my father and me. With many sleepless nights and covid disruptions to
      the entire process over the past year ,we are finally living our dream being fulfilled. As we
      reflect upon our journey, it has been an amazing experience of passion, dedication, grit and
      a sense of desire to achieve the unthinkable that has driven us so far.
    </p>
    <p>As we move into the future ,we move forward with a solemn promise that we will stand by
      and deliver on every bit of our promise and make sure we are truly here to make a
      difference to each one of your health and wellness.The value and the ethos that I want my
      company to stand for is to not just create dazzling designer smiles but also to improve the
      quality of life to the deserving and the needy in our society by providing them affordable
      health care as a part of its long term vision.</p>
    <p>I am eternally grateful to my dad for the hunger he has instilled in me and also been a
      motivation to keep working hard to continuously improve everyday , where money is not
      the driving force, but our desire to achieve our visions are. I understand that this is just the
      beginning of my journey and I am committed to lifelong learning for I have not come this far
      to only come this far……</p>
    <p><b>"Your overall health is our greatest wealth"</b></p>
  </div>
</div>