

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../services/notification.service';

import { WebsiteService } from '../websiteservice/website.service';


@Component({
  selector: 'app-article-detail-page',
  templateUrl: './articles-page.component.html'
})

export class ArticlesPageComponent implements OnInit, AfterViewInit {

  public articledetailsId: string;
  public articleId: number;
  public articles: any;
  constructor(public routing: Router, private route: ActivatedRoute, public websiteService: WebsiteService, private notifyService: NotificationService) {
    this.articledetailsId = this.route.snapshot.queryParamMap.get('id');
    this.articleId = parseInt(this.articledetailsId, 10);
  }

  ngOnInit() {
    this.articleId = parseInt(this.articledetailsId, 10);
    this.GetArticles(this.articleId);
    //this.loadScripts();

  }
  ngAfterViewInit() {
    //this.loadScripts();
    //this.blogId = parseInt(this.blogdetailId, 10);
    //this.GetBlogs(this.blogId);
  }
  GetArticles(id) {
    this.websiteService.GetArticlesId(id)
      .subscribe(res => this.SuccessArticles(res),
        res => this.Error(res));
    
  }
  SuccessArticles(res) {
    this.articles = res;

    this.articles.imagePath = "https://cms.danphecare.com" + this.articles.imagePath.substr(2);

    
  }
  Error(res) {
    this.notifyService.showError("Info", "No Articles Found!");
  }

  loadScripts() {
    const dynamicScripts = [
      //'../content/js/jquery.min.js',
      './content/js/custom.js',
      './content/js/move-img-effect.js',
      './content/js/plugins.js',
      //'../content/js/popper.min.js',
      './content/js/slick.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }


}
