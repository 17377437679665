
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EndpointBase } from './endpoint-base.service';
import { environment } from '../../../../environments/environment';
import { BookPackageModel } from '../buy-package/book-package.model';
import { HomeSampleCollectionForm } from '../interface/home-sample-collection-form';
import { Base64ImageString } from '../interface/base64Image';
import { HealthAtHomeRequestViewModel } from '../interface/HealthAtHomeRequestViewModel';


@Injectable()
export class DanphecareEndpoint extends EndpointBase {

  private readonly _GetAppliedUsrlistUrl: string = "api/danphecare/GetAppliedUsrlist";
  private readonly _applytoIsolationUrl: string = "api/danphecare/ApplyToIsolation";
  private readonly _reachUsQuicklyUrl: string = "api/danphecare/ReachUsQuickly";
  private readonly _buyPackage: string = "api/danphecare/BuyPackage";

  private readonly _requestHealthAtHome: string = "api/danphecare/RequestHealthAtHome"

  constructor(http: HttpClient) {
    super(http);
  }

  GetAppliedUsrlist<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._GetAppliedUsrlistUrl}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetAppliedUsrlist()));
      }));
  }

  applytoIsolation<T>(userObject: any): Observable<T> {

    return this.http.post<T>(`${environment.url}/${this._applytoIsolationUrl}`, JSON.stringify(userObject), this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.applytoIsolation(userObject)));
      }));
  }
  reachUsQuickly<T>(userObject: any): Observable<T> {
    return this.http.post<T>(`${environment.url}/${this._reachUsQuicklyUrl}`, JSON.stringify(userObject), this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.reachUsQuickly(userObject)));
      }));
  }

  BuyPackage<T>(userObject: BookPackageModel): Observable<T> {
    return this.http.post<T>(`${environment.url}/${this._buyPackage}`, JSON.stringify(userObject), this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.BuyPackage(userObject)));
      }));
  }

  RequestHealthAtHome<T>(request: HealthAtHomeRequestViewModel): Observable<T> {
    return this.http.post<T>(`${environment.url}/${this._requestHealthAtHome}`, request , this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.RequestHealthAtHome(request)));
      }));
  }
}
