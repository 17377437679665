
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import * as $ from "jquery";
import { WebsiteService } from '../websiteservice/website.service';
import { OurServiceModel } from '../../cms/models/OurServices.model';

import { DanpheCareDepartment } from '../../cms/models/danphecare.cms.model';
import { NotificationService } from '../services/notification.service';
import { isPlatformBrowser } from '@angular/common';
// declare var $: any;
import * as $ from 'jquery';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ["./header.component.css"]
})

export class HeaderComponent implements OnInit {
  public telemedUrl = environment.telMedUrl;
  // icon = require("../../../assets/images/danphecarelogo.png");
  public servicesList: Array<OurServiceModel> = new Array<OurServiceModel>();
  public servicesId: number;
  public departmentList: Array<DanpheCareDepartment> = new Array<DanpheCareDepartment>();
  public departmentId: number;
  department1: any;
  department2: any;
  department3: any;
  department4: any;
  department5: any;
  department6: any;
  service1: any;
  service2: any;
  service3: any;
  service4: any;
  service5: any;
  service6: any;
  isdepartment4: boolean = false;
  isservice2: boolean = false;
  constructor(public routing: Router, public websiteService: WebsiteService,
    @Inject(PLATFORM_ID) private platformId, public notifyService: NotificationService) {
    this.GetServices();
    this.GetDepartment();


  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {

      this.loadmenu();

    }
    this.closeNavMenuWhenRouteIsChanged();

  }

  navOpen: boolean = false;
  /** To close the navigation page in mobile screens when route is changed. */
  private closeNavMenuWhenRouteIsChanged() {
    this.routing.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.navOpen = false;
    });
  }

  clickEvent() {
    this.navOpen = !this.navOpen;
  }
  //
  //this.loadScripts();

  loadmenu() {
    $('.menu > ul > li:has( > ul)').addClass('menu-dropdown');
    //Checks if li has sub (ul) and adds class for toggle icon - just an UI


    $('.menu > ul > li > ul:not(:has(ul))').addClass('normal-sub');
    //Checks if drodown menu's li elements have anothere level (ul), if not the dropdown is shown as regular dropdown, not a mega menu (thanks Luka Kladaric)

    $(".menu > ul").before("<a href=\"#\" class=\"menu-mobile\"></a>");

    //Adds menu-mobile class (for mobile toggle menu) before the normal menu
    //Mobile menu is hidden if width is more then 1199px, but normal menu is displayed
    //Normal menu is hidden if width is below 1199px, and $ adds mobile menu
    //Done this way so it can be used with wordpress without any trouble

    // $(".menu > ul > li").hover(function (e) {
    //     if ($(window).width() > 1170) {
    //         $(this).children("ul").stop(true, false).fadeToggle(300);
    //         e.preventDefault();
    //     }
    // });
    //If width is more than 1170px dropdowns are displayed on hover

    $(".menu > ul > li").on('click', function () {
      if ($(window).width() <= 1170) {
        $(this).children("ul").fadeToggle(300);
      }
    });
    //If width is less or equal to 1170px dropdowns are displayed on click

    $(".menu-mobile").on('click', function (e) {
      $(".menu > ul").toggleClass('show-on-mobile');
      e.preventDefault();
    });
    //when clicked on mobile-menu, normal menu is shown as a list, classic rwd menu story
  }
  loadScripts() {
    const dynamicScripts = [
      '../content/js/jquery.min.js',
      '../content/js/bootstrap.min.js',
      '../content/js/custom.js',
      '../content/js/move-img-effect.js',
      '../content/js/plugins.js',
      '../content/js/popper.min.js',
      '../content/js/slick.min.js'

    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
  GetServices() {
    this.websiteService.GetOurServices().subscribe(res => {
      if (res) {

        //this.servicesList = [];
        //if (res.length < 6) {
        //  this.service1 = res.filter((dep, idx) => idx < 6);
        //  this.service2 = null;
        //  this.service3 = null;
        //  this.service4 = null;
        //  this.service5 = null;
        //}
        //if (res.length < 12 && res.length > 5) {
        //  this.service1 = res.filter((dep, idx) => idx < 6);
        //  this.service2 = res.filter((dep, idx) => idx > 5 && idx < 12);
        //  this.service3 = null;
        //  this.service4 = null;
        //  this.service5 = null;
        //}
        //if (res.length < 18 && res.length > 11) {
        //  this.service1 = res.filter((dep, idx) => idx < 6);
        //  this.service2 = res.filter((dep, idx) => idx > 5 && idx < 12);
        //  this.service3 = res.filter((dep, idx) => idx > 11 && idx < 18);
        //  this.service4 = null;
        //  this.service5 = null;
        //}
        //if (res.length < 24 && res.length > 17) {
        //  this.service1 = res.filter((dep, idx) => idx < 6);
        //  this.service2 = res.filter((dep, idx) => idx > 5 && idx < 12);
        //  this.service3 = res.filter((dep, idx) => idx > 11 && idx < 18);
        //  this.service4 = res.filter((dep, idx) => idx > 17 && idx < 25);
        //  this.service5 = null;
        //}
        //if (res.length < 30 && res.length > 23) {
        //  this.service1 = res.filter((dep, idx) => idx < 6);
        //  this.service2 = res.filter((dep, idx) => idx > 5 && idx < 12);
        //  this.service3 = res.filter((dep, idx) => idx > 11 && idx < 18);
        //  this.service4 = res.filter((dep, idx) => idx > 17 && idx < 24);
        //  this.service5 = res.filter((dep, idx) => idx > 23 && idx < 30);
        //}
        if (res.length < 4) {
          this.service1 = res.filter((dep, idx) => idx < 4);
          this.service2 = null;
          this.service3 = null;
          this.service4 = null;
          this.service5 = null;
        }
        if (res.length < 8 && res.length > 3) {
          this.service1 = res.filter((dep, idx) => idx < 4);
          this.service2 = res.filter((dep, idx) => idx > 3 && idx < 8);
          this.service3 = null;
          this.service4 = null;
          this.service5 = null;
        }
        if (res.length < 13 && res.length > 7) {
          this.service1 = res.filter((dep, idx) => idx < 4);
          this.service2 = res.filter((dep, idx) => idx > 3 && idx < 8);
          this.service3 = res.filter((dep, idx) => idx > 7 && idx < 13);
          this.service4 = null;
          this.service5 = null;
        }

        if (this.service2.length !== 0) {
          this.isservice2 = true;
        }

        this.servicesList = Object.assign(this.servicesList, res);
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Services Found!");
      });
  }
  GoToServiceDetails(permaLink) {
    //this.servicesId = id;
    this.routing.navigate(['/service', permaLink]);


  }
  GetDepartment() {
    this.websiteService.GetDepartment().subscribe(res => {
      if (res) {
        this.departmentList = [];
        if (res.length < 6) {
          this.department1 = res.filter((dep, idx) => idx < 6);
          this.department2 = null;
          this.department3 = null;
          this.department4 = null;
          this.department5 = null;
        }
        if (res.length < 12 && res.length > 5) {
          this.department1 = res.filter((dep, idx) => idx < 6);
          this.department2 = res.filter((dep, idx) => idx > 5 && idx < 12);
          this.department3 = null;
          this.department4 = null;
          this.department5 = null;
        }
        if (res.length < 18 && res.length > 11) {
          this.department1 = res.filter((dep, idx) => idx < 6);
          this.department2 = res.filter((dep, idx) => idx > 5 && idx < 12);
          this.department3 = res.filter((dep, idx) => idx > 11 && idx < 18);
          this.department4 = null;
          this.department5 = null;
        }
        if (res.length < 24 && res.length > 17) {
          this.department1 = res.filter((dep, idx) => idx < 6);
          this.department2 = res.filter((dep, idx) => idx > 5 && idx < 12);
          this.department3 = res.filter((dep, idx) => idx > 11 && idx < 18);
          this.department4 = res.filter((dep, idx) => idx > 17 && idx < 25);
          this.department5 = null;
        }
        if (res.length < 30 && res.length > 23) {
          this.department1 = res.filter((dep, idx) => idx < 6);
          this.department2 = res.filter((dep, idx) => idx > 5 && idx < 12);
          this.department3 = res.filter((dep, idx) => idx > 11 && idx < 18);
          this.department4 = res.filter((dep, idx) => idx > 17 && idx < 24);
          this.department5 = res.filter((dep, idx) => idx > 23 && idx < 30);
        }
        if (this.department4.length !== 0) {
          this.isdepartment4 = true;
        }
        this.departmentList = Object.assign(this.departmentList, res);
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Department Found!");
      });
  }
  DepartmentDetails(permaLink) {
    //this.departmentId = id;
    this.routing.navigate(['/department', permaLink]);
  }
  GotoTelemedicine() {
    this.routing.navigate(['/login']);
  }
}
