<div class="ccm-page ccm-page-id-1 page-type-page page-template-full" id="wrapper-outer">
  <div id="wrapper">
    <div id="single-product" style="padding-top: 200px;">
      <ng-container *ngIf="showProductNotFoundMessage == true; else singleProductPage">
        <h3 class="m-5">The product you are searching for is unavailable.</h3>
      </ng-container>
      <ng-template #singleProductPage>

        <section class="single-product-page pb-5">
          <div class="custom-container pt-0">
            <div class="row">
              <div class="col-md-7 ">
                <div class="product-item-details pr-5">
                  <div>
                    <div class="section-title-wrapper d-flex justify-content-between align-items-center mt-3">
                      <div>
                        <h2>{{ this.ProductDetails.Name}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="product-desc pt-2 mt-3 position-relative">
                    <p>{{this.ProductDetails.ShortDescription}}</p>
                    <!-- <hr> -->
                    <p>{{this.ProductDetails.FullDescription}}</p>
                    <ng-container *ngFor="let productGroup of ProductDetails.ChildGroups">
                      <h5><u>{{productGroup.GroupingLabel}}</u></h5>
                      <ul class="my-3 ml-5" *ngFor="let product of productGroup.ChildProducts">
                        <li>
                          <em class="fas fa-check-circle text-primary"></em> {{product.Name}}
                        </li>
                      </ul>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="product-image">
                  <img [src]="previewImgSrc" alt="Covid-19">
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12">
                <div class="buy-now-section">
                  <div class="row no-gutters h-100">
                    <div class="col-md-3 m-auto">
                      <div class="item-name-rate d-flex ml-3">
                        <div class="hexagon hexagon2 ">
                          <div class="hexagon-in1">
                            <div class="hexagon-in2 bg-primary d-flex align-items-center justify-content-center">
                              <div class="product-image-wrapper">
                                <img alt="buynow" [src]="previewImgSrc" width="120" height="85" style=" margin-left: 2500%; margin-bottom: 50px;" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="item-name pl-2">
                          <div>
                            <em class="fas fa-star text-warning"></em>
                            <em class="fas fa-star text-warning"></em>
                            <em class="fas fa-star text-warning"></em>
                            <em class="far fa-star text-warning"></em>
                            <em class="far fa-star text-warning"></em>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div id="pointer" class="">
                        <div class="buy-now-content ml-5 h-100">
                          <div class="row no-gutters h-100">
                            <div class="col-md-4 m-auto">
                            </div>
                            <div class="col-md-4 m-auto">
                              <div class="buy-now-price">
                                <h6 *ngIf="ProductDetails.OldPrice"> {{this.ProductDetails.OldPrice}}</h6>
                                <h3>NPR. <span>{{this.ProductDetails.Price}}</span></h3>
                              </div>
                            </div>
                            <div class="col-md-4 m-auto">
                              <div>
                                <button class="btn btn-buy-now" data-toggle="modal" data-target="#packageFormModal">Book Now</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="discount-badge" *ngIf="false">
                  <h3><span>{{productDiscoundPercent | number : '1.1-1' }}%</span> OFF</h3>
                </div>
              </div>
            </div>
          </div>
        </section>

      </ng-template>
      <div id="lightboxOverlay" class="lightboxOverlay" style="display: none;"></div>
      <div id="lightbox" class="lightbox" style="display: none;">
        <div class="lb-outerContainer">
          <div class="lb-container">
            <img alt="wecare-logo" class="lb-image" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==">
            <div class="lb-nav"><a class="lb-prev" href="http://wecarehealthcenter.org/"></a><a class="lb-next" routerLink="/"></a></div>
            <div class="lb-loader"><a class="lb-cancel"></a></div>
          </div>
        </div>
        <div class="lb-dataContainer">
          <div class="lb-data">
            <div class="lb-details"><span class="lb-caption"></span><span class="lb-number"></span></div>
            <div class="lb-closeContainer"><a class="lb-close"></a></div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="packageFormModal" tabindex="-1" role="dialog" aria-labelledby="packageFormModalTitle" aria-hidden="true" *ngIf="showPopup">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content packageForm">
            <div class="modal-header">
              <h5 class="modal-title PackageSelected text-white" id="Title">Selected Package: {{ProductDetails.Name}}</h5>
              <button type="button"  class="close" data-dismiss="modal" (click)="resetError()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <app-book-now #formComponent (close)="closePopup($event)" [PackageName]=ProductDetails.Name></app-book-now>
            </div>
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-primary" >Submit</button>
            </div> -->
          </div>
        </div>
      </div>