

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WebsiteService } from '../websiteservice/website.service';

import { ResourcefulArticles, DepartmentConsultation, DanpheCareDoctor, DepartmentSubHeading, Blogs, MetaTag } from '../../cms/models/danphecare.cms.model';
import { Title, Meta } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DanpheCareReachUsQuicklyModel } from '../../cms/models/danphecare/danphecare.model';
import { DanphecareService } from '../services/danphe.service';
import { NotificationService } from '../services/notification.service';
import { MetaService } from '@ngx-meta/core';
import { environment } from '../../../../environments/environment';



@Component({
  selector: 'app-profile-page',
  templateUrl: './profile.component.html'
})
export class ProfilePageComponent implements OnInit, AfterViewInit {
  public profiledetailId: string;
  public departmentdetailId: string;
  public profileId: number;
  public profile: DanpheCareDoctor = new DanpheCareDoctor();
  public articlesList: Array<ResourcefulArticles> = new Array<ResourcefulArticles>();
  public articlesId: number;
  public departmentList: Array<DepartmentConsultation> = new Array<DepartmentConsultation>();
  public departmentId: any;
  articleId: any;
  public blogsList: Array<Blogs> = new Array<Blogs>();
  public blogId: number;
  public IsSubHeading = true;
  public subheadingList: Array<DepartmentSubHeading> = new Array<DepartmentSubHeading>();
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public tag: MetaTag = new MetaTag();
  public reachForm2: FormGroup;
  public submitForm2: boolean = false;
  public loading2: boolean = false;
  public showModelBox: boolean = false;
  public DhcareRuq1: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public content: string;
  public permaLink: any;

  constructor(public routing: Router, private route: ActivatedRoute, public websiteService: WebsiteService, private notifyService: NotificationService, private titleService: Title, private metaService: Meta,
    public formBuilder: FormBuilder, public danphecareservice: DanphecareService, private readonly meta: MetaService) {
    this.route.params.subscribe((params: Params) => {
      this.permaLink = params.permalink;
    });

  }

  ngOnInit() {
    this.websiteService.GetDoctorById(this.permaLink).subscribe(res => {
      this.profile = res;
      if (this.profile.imagePath !== null) {
        this.profile.imagePath = environment.url + this.profile.imagePath.substr(2);
      }
      if (this.profile.coverPhoto !== null) {
        this.profile.coverPhoto = environment.url + this.profile.coverPhoto.substr(2);
      }
      //this.content = this.profile.content.replace(/(<([^>]+)>)/gi, "");
      //this.content = this.content.slice(0, 150);
      this.meta.setTitle(this.profile.metaTitle);
      this.meta.setTag("description", this.profile.metaContent);
      this.meta.setTag("keywords", this.profile.metaKeywords);
      this.meta.setTag('og:title', this.profile.fullName + " - " + this.profile.departmentName);
      this.meta.setTag('og:image', this.profile.imagePath);
      //this.meta.setTag('og:image:width', "800");
      //this.meta.setTag('og:image:height', "1200");
      this.meta.setTag('og:url', location.href);
      this.meta.setTag('og:type', "Doctor Profile");
      this.meta.setTag('og:description', this.profile.metaContent);
      this.meta.setTag('twitter:card', location.href);
      this.meta.setTag('twitter:title', this.profile.fullName + " - " + this.profile.departmentName);
      this.meta.setTag('twitter:description', this.profile.metaContent);
      this.meta.setTag('twitter:image', this.profile.imagePath);
      this.meta.setTag('og:app_id', "260103905625004");

    },
      res => {
        //this.notifyService.showError("Info", "No Blogs Found!");
      });
    this.GetBlogs();
    this.GetArticles();
    this.GetDepartment();
    this.GetDepartmentSubHeading();
    this.reachForm2 = this.formBuilder.group({
      phonenumber2: ['', [Validators.required]],
      name2: ['', [Validators.required]]
    });
    //this.loadScripts();

  }
  ngAfterViewInit() {
    //this.loadScripts();
  }

  GetArticles() {
    this.websiteService.GetArticles()
      .subscribe(res => this.SuccessArticles(res),
        res => this.ErrorArticles(res));
  }
  SuccessArticles(res) {
    this.articlesList = res;
    this.articlesList.forEach((item, index) => {
      if (item.imagePath !== null) {
        item.imagePath = environment.url + item.imagePath.substr(2);
      }
      if (item.artImage !== null) {
        item.artImage = environment.url + item.artImage.substr(2);
      }

    });
  }
  ErrorArticles(res) {
    //this.notifyService.showError("Info", "No Articles Found!");
  }
  GetDepartmentSubHeading() {
    this.websiteService.GetSubHeadingByDoctorId(this.permaLink)
      .subscribe(res => this.SuccessDepartmentSubHeading(res),
        res => this.ErrorDepartmentSubHeading(res));
  }
  SuccessDepartmentSubHeading(res) {
    this.subheadingList = res;
    this.subheadingList.forEach((item, index) => {
      item.iconPath = environment.url + item.iconPath.substr(2);
    });
    if (res.length === 0) {
      this.IsSubHeading = false;
    }
  }
  ErrorDepartmentSubHeading(res) {
    //this.notifyService.showError("Info", "No Department SubHeading Found!");
  }

  GetDepartment() {
    this.websiteService.GetDepConsultation()
      .subscribe(res => this.SuccessDepartment(res),
        res => this.ErrorDepartment(res));
  }
  SuccessDepartment(res) {
    this.departmentList = res;
    this.departmentList.forEach((item, index) => {
      item.iconPath = environment.url + item.iconPath.substr(2);
    });
  }
  ErrorDepartment(res) {
    //this.notifyService.showError("Info", "No Department Found!");
  }

  GoToArticle(id) {
    this.articleId = id;
    this.routing.navigate(['/articlespage'], { queryParams: { id: this.articleId } });


  }
  GetBlogs() {
    this.websiteService.GetBlogs().subscribe(res => {
      if (res) {
        this.blogsList = [];
        this.blogsList = Object.assign(this.blogsList, res);
        this.blogsList.forEach((item, index) => {
          item.imagePath = environment.url + item.imagePath.substr(2);
        });
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Blogs Found!");
      });
  }
  GoToBlogDetails(permaLink) {
    //this.blogId = id;
    this.routing.navigate(['/blog', permaLink]);


  }

  onSubmitBook() {
    if (this.reachForm2.valid) {
      this.submitForm2 = true;
      this.loading2 = true;
      this.danphecareservice.reachUsQuickly(this.DhcareRuq1)
        .subscribe(res => this.SuccessPostBook(res),
          res => this.Error1(res));
    }
    else {
      this.submitForm2 = true;
      this.loading2 = false;
      this.notifyService.showInfo("Info", "Please enter the required field");
      //this.reachForm2.reset();
      (this.submitForm2) ? setTimeout(() => { this.submitForm2 = false }, 5250) : null
    }


  }
  SuccessPostBook(res) {
    this.loading2 = false;
    this.DhcareRuq1 = new DanpheCareReachUsQuicklyModel();
    this.notifyService.showSuccess('success', 'application submitted successfully ');
    this.submitForm2 = false;
    this.reachForm2.reset();
  }
  hide() {
    this.showModelBox = false;
    this.submitForm2 = false;
    this.loading2 = false;
    this.reachForm2.reset();
  }
  open() {
    this.showModelBox = true;
  }
  Error1(res) {
    const response = res;
    this.loading2 = false;
    this.notifyService.showError("Error", " Please fill up the required field")
  }
  get NameControl2() {
    return this.reachForm2.get("name2") ;
  }
  get PhoneNumberControl2() {
    return this.reachForm2.get("phonenumber2") ;
  }

}
