

<div class="banner-image-bg background-image">
  <section class="content-section hero-section">
    <div class="container">
      <div class="row">
        <div class="hero-content-wrapper">
          <div class="col-md-6 col-sm-6 col-xs-12 col-6">
            <div class="hero-content d-none d-sm-block d-lg-block">
              <h2 class="hero-content-title content-title">
                Committed to your <br> <span>Health and <br> Wellbeing</span>
              </h2>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-xs-12 col-6">
            <p class="hero-desc">An initiative by Danphe Care and Hamro Patro</p>
            <div class="hero-partners">
              <div class="partners-logo">
                <img src="assets/images/img.png" alt="">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
  <section class="content-section request-call-section d-block d-sm-none d-lg-none">
    <div class="container">
      <div class="request-call-bg background-image">
        <div class="request-call-title section-title text-center">
          <h2 class="title">Request a<span> Call</span></h2>
        </div>

        <div class="form-section">
          <form [formGroup]="form" (ngSubmit)="onSubmitReachUsQuickly()" novalidate #myForm="ngForm">
            <div class="col-md-10 col-lg-8 m-auto">
              <div class="request-call-form">
                <div class="form-group mb-0">
                  <i class="fa fa-envelope" style="font-size: 20px;"></i>
                  <!--<input type="email"
                  class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Enter your email">-->
                  <input type="text" class="form-control " id="email" name="email" value="" [(ngModel)]="DhcareRuq.email" required placeholder="Enter your email *" formControlName="email">
                  <!--<div *ngIf="EmailControl.invalid && (EmailControl.dirty || EmailControl.touched)|| (EmailControl.untouched && submitted)" class="alert-sm alert-danger">
                    <div *ngIf="EmailControl.errors.required">Email is required</div>
                    <div *ngIf="EmailControl.errors.pattern">Enter valid email address</div>
                  </div>-->
                </div>
                <div class="form-group phone-no mb-0">
                  <i class="fa fa-mobile"></i>
                  <!--<input type="text"
                          class="form-control" id="exampleInputPassword1" placeholder="Enter your Phone Number">-->
                  <input type="text" class="form-control " id="phone" name="phone" [(ngModel)]="DhcareRuq.phone" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" required value="" placeholder="Enter your phone *" formControlName="phone">
                  <!--<div *ngIf="PhoneNumberControl.invalid && (PhoneNumberControl.dirty || PhoneNumberControl.touched ||(PhoneNumberControl.untouched && submitted) )" class="alert-sm alert-danger">
                    <div *ngIf="PhoneNumberControl.errors.required">Phone Number is required</div>
                  </div>-->

                </div>
                <button type="submit" class="ml-1 btn btn-primary submit-button">
                  <span *ngIf="loading" class="spinner-border" role="status">Submitting ...</span>
                  <span *ngIf="!loading"><i class="material-icons mdi mdi-message-outline"></i> Submit</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- our services section -->
  <section class="content-section services-section pt-0">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-md-12 ">
          <div class="services-title section-title">
            <h2 class="title mb-4">Our <span>Services</span></h2>
          </div>
        </div>

        <div class="services-content w-100">
          <div class="row">
            <div class=" col-xl-3 col-lg-3  col-md-6 col-sm-6 col-xs-12">
              <div class="services-list">
                <div class="services-name">
                  <h3>Covid Care <br><span>Hotline Number</span></h3>
                </div>
                <a href="tel:{{contact.phone}}">
                  <div class="know-more">
                    Know More &nbsp; <span><img src="assets/images/arrow-green.svg" alt=""></span>
                  </div>
                </a>
              </div>
            </div>
            <div class=" col-xl-3 col-lg-3  col-md-6 col-sm-6 col-xs-12">
              <div class="services-list">
                <div class="services-name">
                  <h3>Danphe <br><span>Covid Care</span></h3>
                </div>
                <a href="https://danphecare.com/" target="_blank">
                  <div class="know-more">
                    Know More &nbsp; <span><img src="assets/images/arrow-green.svg" alt=""></span>
                  </div>
                </a>
              </div>
            </div>
            <div class=" col-xl-3 col-lg-3  col-md-6 col-sm-6 col-xs-12">
              <div class="services-list">
                <div class="services-name">
                  <h3>Contact Us <br><span></span></h3>
                </div>
                <a [routerLink]="[ '/', 'contactpage' ]">
                  <div class="know-more">
                    Know More &nbsp; <span><img src="assets/images/arrow-green.svg" alt=""></span>
                  </div>
                </a>
              </div>
            </div>
            <div class=" col-xl-3 col-lg-3  col-md-6 col-sm-6 col-xs-12">
              <div class="services-list">
                <div class="services-name">
                  <h3>Danphe <br> <span>Telemedicine</span></h3>
                </div>
                <a href="https://tele.danphecare.com" target="_blank">
                  <div class="know-more">
                    Know More &nbsp; <span><img src="assets/images/arrow-green.svg" alt=""></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

</div>
<!-- request a call section -->
<section class="content-section request-call-section d-none d-lg-block">
  <div class="container">
    <div class="request-call-bg background-image">
      <div class="request-call-title section-title text-center">
        <h2 class="title">Request a<span> Call</span></h2>
      </div>

      <div class="form-section">
        <form [formGroup]="form" (ngSubmit)="onSubmitReachUsQuickly()" novalidate #myForm="ngForm">
          <div class="col-md-10 col-lg-8 m-auto">
            <div class="request-call-form">
              <div class="form-group mb-0">
                <i class="fa fa-envelope" style="font-size: 20px;"></i>
                <!--<input type="email"
                class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                placeholder="Enter your email">-->
                <input type="text" class="form-control " id="email" name="email" value="" [(ngModel)]="DhcareRuq.email" required placeholder="Enter your email *" formControlName="email">
                <!--<div *ngIf="EmailControl.invalid && (EmailControl.dirty || EmailControl.touched)|| (EmailControl.untouched && submitted)" class="alert-sm alert-danger">
                  <div *ngIf="EmailControl.errors.required">Email is required</div>
                  <div *ngIf="EmailControl.errors.pattern">Enter valid email address</div>
                </div>-->
              </div>
              <div class="form-group phone-no mb-0">
                <i class="fa fa-mobile"></i>
                <!--<input type="text"
                        class="form-control" id="exampleInputPassword1" placeholder="Enter your Phone Number">-->
                <input type="text" class="form-control " id="phone" name="phone" [(ngModel)]="DhcareRuq.phone" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" required value="" placeholder="Enter your phone *" formControlName="phone">
                <!--<div *ngIf="PhoneNumberControl.invalid && (PhoneNumberControl.dirty || PhoneNumberControl.touched ||(PhoneNumberControl.untouched && submitted) )" class="alert-sm alert-danger">
                  <div *ngIf="PhoneNumberControl.errors.required">Phone Number is required</div>
                </div>-->

              </div>
              <button type="submit" class="ml-1 btn btn-primary submit-button">
                <span *ngIf="loading" class="spinner-border" role="status">Submitting ...</span>
                <span *ngIf="!loading"><i class="material-icons mdi mdi-message-outline"></i> Submit</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>


<!-- consult with our doctors -->
<section class="content-section consult-with-doctors d-none d-lg-block">
  <div class="container">
    <div class="row no-gutters">
      <div class="col-lg-3 col-xl-3">

      </div>

      <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <div class="consult-with-doctor">
          <h2 class="consult-with-doctors-title content-title">Consult with <br> <span>Our Top Doctors</span></h2>
        </div>

        <div class="doctors-profession mt-5">
          <div class="row">
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Physiotherapy</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Cardiology</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Mental Health</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Diet and Nutrition</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Orthopedic</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Neurology</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Oncology</a>
              </div>
            </div>

            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Endocrinology</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Pediatrics</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Ear, Nose and throat</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Internal Medicine</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Laboratory</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Infectious Disease</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Nursing Care</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Nephrology</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Ophthalmology</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Medical Officer</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Surgery</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Clinical Research</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Pulmonology</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Anesthesiology</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-3 col-lg-3 col-xl-3">
              <div class="profession-list">
                <a href="">Urology</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-6 col-lg-6 col-xl-6">
              <div class="profession-list">
                <a href="">Gynecology and Obstetrics</a>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6  col-md-6 col-lg-6 col-xl-6">
              <div class="profession-list">
                <a href="">Dermatology and Venereology</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>

<!-- Our core team section -->
<div class="banner-image-bg background-image d-none">
  <section class="content-section core-team-section">
    <div class="container">
      <div class="core-team-bg background-image">
        <div class="core-team-title section-title text-center mb-5">
          <h2 class="title">Our core <span> Team</span></h2>
        </div>

        <div class="row">

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
            <div class="team-list h-100 mb-2">
              <div class="team-image">
                <img src="assets/images/team1.png" alt="">
              </div>

              <div class="team-name">
                <h2 class="doctor-name">Dr.Prabhat Adhikari</h2>
                <p>Infectious Disease</p>
              </div>
            </div>
          </div>


          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
            <div class="team-list h-100 mb-2">
              <div class="team-image">
                <img src="assets/images/team2.png" alt="">
              </div>

              <div class="team-name">
                <h2 class="doctor-name">Binod Dhungana</h2>
                <p>Internal Medicine</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
            <div class="team-list h-100 mb-2">
              <div class="team-image">
                <img src="assets/images/team3.png" alt="">
              </div>

              <div class="team-name">
                <h2 class="doctor-name">Phr. Kabin Maleku</h2>
                <p>Healthcare Technology specialist</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-3">
            <div class="team-list h-100 mb-2">
              <div class="team-image">
                <img src="assets/images/team4.png" alt="">
              </div>

              <div class="team-name">
                <h2 class="doctor-name">RN. Samikshya Koirala</h2>
                <p>Nursing in charge</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>


<!-- talk our doctors -->
<section class="content-section talk-our-doctors d-none d-lg-block">
  <div class="container">
    <div class="row no-gutters">
      <div class="col-lg-8 col-md-10">
        <div class="talk-our-doctor">
          <h2 class="talk-our-doctors-title content-title">
            We are here to Help,<br> <span>
              Talk to our Doctors
            </span>
          </h2>

          <p>
            We provide online counselling through our Tele Care platform. Now anyone
            can easily book an appointment from the convenience of your home or your
            office. Our specialist will obtain all your detailed history, review your records
            and give you the best treatment. We can also provide lab services, and
            prescriptions at your home.
          </p>
        </div>
        <div class="other-actions mt-5">
          <div class="row">
            <div class="col-md-6">
              <h2>1. Register </h2>
            </div>
            <div class="col-md-6">
              <h2>2. Book an Appointment </h2>
            </div>
            <div class="col-md-6">
              <h2>3. Consult a Doctor </h2>
            </div>
            <div class="col-md-6">
              <h2>4. Generate Report </h2>
            </div>
          </div>

        </div>

      </div>

      <div class="col-md-4">
        <div class="talk-our-doctors-bg">

        </div>
      </div>
    </div>
  </div>
</section>


<div class="banner-image-bg background-image d-none d-lg-block">
  <section class="content-section contact-us">
    <div class="container">
      <div class="core-team-bg background-image">
        <div class="row d-flex flex-sm-column-reverse flex-md-row">
          <div class="col-md-5">
            <div class="contact">
              <div class="">
                <span class="icon-sec">
                  <i class="fa fa-phone" style="font-size:40px"></i>
                </span>
                <h1>+977-9802314740/42</h1>
                <p>or email us: <a href="" class="text-success"><strong>info@danphecare.com</strong></a></p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="team-name">
              <h1 class="content-title">
                For queries <br>
                <span>Call us now !</span>
              </h1>
              <p>
                If you have any health-related queries, please contact us. We will provide you with guidance and
                support to solve your
                health concern. For emergencies, we suggest you contact the nearest ambulance service or go to a
                nearby hospital for
                immediate attention. If you are in quarantine or home isolation, our team of nurses, medical officers,
                and specialists
                also provide physical, mental, and social support to improve your well being and overcome your
                anxiety.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>



