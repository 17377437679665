<div id="content-wrapper">

  <div id="packages-page">


    <section class="packages-section">
      <div class="custom-container">
        <div class="section-title main-title">


          <h5>Best of our services</h5>

          <h1>Health packages</h1>

        </div>

        <div class="section-content">









          <div class="common-box-container">



            <div class="box-item">
              <div class="inner">
                <h5>Basic Health Package</h5>
                <ul>
                  <li><strong>Routine pathology test</strong></li>
                  <li>Complete blood count(CBC)</li>
                  <li>ESR</li>
                  <li>Urine Routine</li>
                  <li>Stool Routine</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><strong>Diabetic Evaluation</strong></li>
                  <li>Blood Sugar Fasting</li>
                  <li>Blood Sugar PP</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><strong>Liver Test</strong></li>
                  <li>Total Bilirubin</li>
                  <li>SGPT</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><strong>Lipid Profile</strong></li>
                  <li>Triglycerides</li>
                  <li>Cholesterol</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><strong>Renal Tests</strong></li>
                  <li>BUN</li>
                  <li>Creatinine</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><strong>Cardiac Evaluation</strong></li>
                  <li>ECG</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><strong>Radiaology and Imaging</strong></li>
                  <li>X-Ray Chest P.A. View</li>
                  <li>Body Composition Analysis</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><strong>Dental Radiology and Imaging</strong></li>
                  <li>Digital Dental X-Ray</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><strong>Consultations</strong></li>
                  <li>Report Review and Consultaion by Physician</li>
                  <li>Dental Screening and Consultation</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>



            <div class="box-item">
              <div class="inner">
                <h5>Comprehensive Package</h5>
                <ul>
                  <li><b>Routine pathology test</b></li>
                  <li>Complete blood count(CBC)</li>
                  <li>Blood Group</li>
                  <li>ESR</li>
                  <li>Urine Routine</li>
                  <li>Stool Routine</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Diabetic Evaluation</b></li>
                  <li>Blood Sugar Fasting</li>
                  <li>Blood Sugar PP</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Liver Profile</b></li>
                  <li>Total Bilirubin</li>
                  <li>SGPT</li>
                  <li>SGOT</li>
                  <li>Alkaline phosphate</li>
                  <li>Total Protein</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lipid Profile</b></li>
                  <li>Triglycerides</li>
                  <li>Cholesterol</li>
                  <li>HDL Cholesterol</li>
                  <li>LDL Cholesterol</li>
                  <li>VLDL Cholesterol</li>
                  <li>LDL/HDL Ratio</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Renal Tests</b></li>
                  <li>BUN</li>
                  <li>Creatinine</li>
                  <li>Uric Acid</li>
                  <li>Calcium</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>



            <div class="box-item">
              <div class="inner">
                <h5>VIP package</h5>
                <ul>
                  <li><b>Routine pathology test</b></li>
                  <li>Complete blood count(CBC)</li>
                  <li>Blood Group</li>
                  <li>ESR</li>
                  <li>Urine Routine</li>
                  <li>Stool Routine</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Diabetic Profile</b></li>
                  <li>Blood Sugar Fasting</li>
                  <li>Blood Sugar PP</li>
                  <li>Glycosylated HB (HBA1C)</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Liver Profile</b></li>
                  <li>Total Bilirubin</li>
                  <li>SGPT</li>
                  <li>SGOT</li>
                  <li>Alkaline phosphate</li>
                  <li>GGTP</li>
                  <li>Albumin</li>
                  <li>Globulin</li>
                  <li>A/G Ratio</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lipid Profile</b></li>
                  <li>Triglycerides</li>
                  <li>Cholesterol</li>
                  <li>HDL Cholesterol</li>
                  <li>LDL Cholesterol</li>
                  <li>VLDL Cholesterol</li>
                  <li>LDL/HDL Ratio</li>
                  <li>Total Cholestrol/HDL Ratio</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Renal Profile</b></li>
                  <li>BUN</li>
                  <li>Creatinine</li>
                  <li>Uric Acid</li>
                  <li>Electrolytes</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Thyroid Profile</b></li>
                  <li>TSH</li>
                  <li>T3,T4</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Cardiac Profile</b></li>
                  <li>ECG</li>
                  <li>ECHO</li>
                  <li>TMT</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Radiaology and Imaging</b></li>
                  <li>X-Ray Chest P.A. View</li>
                  <li>USG Abdomin and Pelvis</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lung Function test</b></li>
                  <li>Pulmonary Function Test</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Special Test</b></li>
                  <li>PSA (for Men)</li>
                  <li>PAP Smear (for Female)</li>
                  <li>Body Composition Analysis</li>
                  <li>Vitamin D3</li>
                  <li>Vitamin B12</li>
                  <li>Serum Calcium</li>
                  <li>HIV</li>
                  <li>HbsAg</li>
                  <li>Serum Phosphorus</li>
                  <li>Ca 125 Cancer Marker (for Female)</li>
                  <li>&nbsp;</li>
                  <li><b>Dental Profile</b></li>
                  <li>2D Dental OPG (X-Ray)</li>
                  <li>Scaling and Polishing</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Consultations</b></li>
                  <li>Report Review and Consultaion by Physician</li>
                  <li>Dental Screening and Consultation</li>
                  <li>Gaenecology Consultation (For Female)</li>
                  <li>Nutritionist Consultation</li>
                  <li>Cardiology Consultation</li>
                  <li>Examination and Consultation by Physiotherapist</li>
                  <li>Diabetic and Endocrinology Consultation</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>



            <div class="box-item">
              <div class="inner">
                <h5>Senior Care Package</h5>
                <ul>
                  <li><b>Routine pathology test</b></li>
                  <li>Complete blood count(CBC)</li>
                  <li>ESR</li>
                  <li>Urine Routine</li>
                  <li>Stool Routine</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Diabetic Evaluation</b></li>
                  <li>Blood Sugar Fasting</li>
                  <li>Blood Sugar PP</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Liver Profile</b></li>
                  <li>GGTP</li>
                  <li>SGPT</li>
                  <li>SGOT</li>
                  <li>Alkaline phosphate</li>
                  <li>Total Protein</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lipid Profile</b></li>
                  <li>Triglycerides</li>
                  <li>Cholesterol</li>
                  <li>HDL Cholesterol</li>
                  <li>LDL Cholesterol</li>
                  <li>VLDL Cholesterol</li>
                  <li>LDL/HDL Ratio</li>
                  <li>Total Cholesterol/HDL Ratio</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Renal Profile</b></li>
                  <li>BUN</li>
                  <li>Creatinine</li>
                  <li>Uric Acid</li>
                  <li>Calcium</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Thyroid Profile</b></li>
                  <li>TSH</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Cardiac Profile</b></li>
                  <li>ECG</li>
                  <li>ECHO</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Radiaology and Imaging</b></li>
                  <li>X-Ray Chest P.A. View</li>
                  <li>USG Abdomin and Pelvis</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Special Test</b></li>
                  <li>PSA (for Men)</li>
                  <li>PAP Smear (for Female)</li>
                  <li>Vitamin B12</li>
                  <li>Vitamin D3</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Dental Radiology and Imaging</b></li>
                  <li>2D Dental OPG (X-Ray)</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Consultations</b></li>
                  <li>Report Review and Consultaion by Physician</li>
                  <li>Dental Screening and Consultation</li>
                  <li>Gaenecology Consultation (For Female)</li>
                  <li>Nutritionist Consultation</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>



            <div class="box-item">
              <div class="inner">
                <h5>Healthy Her Package</h5>
                <ul>
                  <li><b>Routine pathology test</b></li>
                  <li>Complete blood count(CBC)</li>
                  <li>ESR</li>
                  <li>Urine Routine</li>
                  <li>Stool Routine</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Diabetic Evaluation</b></li>
                  <li>Blood Sugar Fasting</li>
                  <li>Blood Sugar PP</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Liver Profile</b></li>
                  <li>GGTP</li>
                  <li>SGPT</li>
                  <li>SGOT</li>
                  <li>Alkaline phosphate</li>
                  <li>Total Protein</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lipid Profile</b></li>
                  <li>Triglycerides</li>
                  <li>Cholesterol</li>
                  <li>HDL Cholesterol</li>
                  <li>LDL Cholesterol</li>
                  <li>VLDL Cholesterol</li>
                  <li>LDL/HDL Ratio</li>
                  <li>Total Cholesterol/HDL Ratio</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Renal Profile</b></li>
                  <li>BUN</li>
                  <li>Creatinine</li>
                  <li>Uric Acid</li>
                  <li>Calcium</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Thyroid Profile</b></li>
                  <li>TSH</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Cardiac Profile</b></li>
                  <li>ECG</li>
                  <li>ECHO</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Radiaology and Imaging</b></li>
                  <li>X-Ray Chest P.A. View</li>
                  <li>USG Abdomin and Pelvis</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><strong>Special Test</strong></li>
                  <li>PAP Smear</li>
                  <li>Vitamin B12</li>
                  <li>Vitamin D3</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Dental Radiology and Imaging</b></li>
                  <li>Digital Dental X-Ray</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Consultations</b></li>
                  <li>Report Review and Consultaion by Physician</li>
                  <li>Dental Screening and Consultation</li>
                  <li>Gaenecology Consultation (For Female)</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>



            <div class="box-item">
              <div class="inner">
                <h5>Advance Healthy Her Package</h5>
                <ul>
                  <li><b>Routine pathology test</b></li>
                  <li>Complete blood count(CBC)</li>
                  <li>ESR</li>
                  <li>Urine Routine</li>
                  <li>Stool Routine</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Diabetic Evaluation</b></li>
                  <li>Blood Sugar Fasting</li>
                  <li>Blood Sugar PP</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Liver Profile</b></li>
                  <li>GGTP</li>
                  <li>SGPT</li>
                  <li>SGOT</li>
                  <li>Alkaline phosphate</li>
                  <li>Total Protein</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lipid Profile</b></li>
                  <li>Triglycerides</li>
                  <li>Cholesterol</li>
                  <li>HDL Cholesterol</li>
                  <li>LDL Cholesterol</li>
                  <li>VLDL Cholesterol</li>
                  <li>LDL/HDL Ratio</li>
                  <li>Total Cholesterol/HDL Ratio</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Renal Profile</b></li>
                  <li>BUN</li>
                  <li>Creatinine</li>
                  <li>Uric Acid</li>
                  <li>Calcium</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Thyroid Profile</b></li>
                  <li>TSH</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Cardiac Profile</b></li>
                  <li>ECG</li>
                  <li>ECHO</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Radiaology and Imaging</b></li>
                  <li>X-Ray Chest P.A. View</li>
                  <li>USG Abdomin and Pelvis</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Special Test</b></li>
                  <li>PAP Smear</li>
                  <li>Vitamin B12</li>
                  <li>Vitamin D3</li>
                  <li>Hormone Profile</li>
                  <li>Testesterone</li>
                  <li>Progesterone</li>
                  <li>Estrogen</li>
                  <li>FSH</li>
                  <li>LH</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Dental Profile</b></li>
                  <li>2D Dental OPG (X-Ray)</li>
                  <li>Scaling and Polishing</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Consultations</b></li>
                  <li>Report Review and Consultaion by Physician</li>
                  <li>Dental Screening and Consultation</li>
                  <li>Gaenecology Consultation (For Female)</li>
                  <li>Nutritionist Consultation</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>



            <div class="box-item">
              <div class="inner">
                <h5>Healthy Him Package</h5>
                <ul>
                  <li><b>Routine pathology test</b></li>
                  <li>Complete blood count(CBC)</li>
                  <li>ESR</li>
                  <li>Urine Routine</li>
                  <li>Stool Routine</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Diabetic Evaluation</b></li>
                  <li>Blood Sugar Fasting</li>
                  <li>Blood Sugar PP</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Liver Profile</b></li>
                  <li>GGTP</li>
                  <li>SGPT</li>
                  <li>SGOT</li>
                  <li>Alkaline phosphate</li>
                  <li>Total Protein</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lipid Profile</b></li>
                  <li>Triglycerides</li>
                  <li>Cholesterol</li>
                  <li>HDL Cholesterol</li>
                  <li>LDL Cholesterol</li>
                  <li>VLDL Cholesterol</li>
                  <li>LDL/HDL Ratio</li>
                  <li>Total Cholesterol/HDL Ratio</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Renal Profile</b></li>
                  <li>BUN</li>
                  <li>Creatinine</li>
                  <li>Uric Acid</li>
                  <li>Calcium</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Thyroid Profile</b></li>
                  <li>TSH</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Cardiac Profile</b></li>
                  <li>ECG</li>
                  <li>ECHO</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Radiaology and Imaging</b></li>
                  <li>X-Ray Chest P.A. View</li>
                  <li>USG Abdomin and Pelvis</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Special Test</b></li>
                  <li>PSA</li>
                  <li>Vitamin B12</li>
                  <li>Vitamin D3</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Dental Radiology and Imaging</b></li>
                  <li>Digital Dental X-Ray</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Consultations</b></li>
                  <li>Report Review and Consultation by Physician</li>
                  <li>Dental Screening and Consultation</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>



            <div class="box-item">
              <div class="inner">
                <h5>Advance Healthy Him Package</h5>
                <ul>
                  <li><b>Routine pathology test</b></li>
                  <li>Complete blood count(CBC)</li>
                  <li>ESR</li>
                  <li>Urine Routine</li>
                  <li>Stool Routine</li>
                  <li>&nbsp;</li>
                  <li><b>Diabetic Evaluation</b></li>
                  <li>Blood Sugar Fasting</li>
                  <li>Blood Sugar PP</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Liver Profile</b></li>
                  <li>GGTP</li>
                  <li>SGPT</li>
                  <li>SGOT</li>
                  <li>Alkaline phosphate</li>
                  <li>Total Protein</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lipid Profile</b></li>
                  <li>Triglycerides</li>
                  <li>Cholesterol</li>
                  <li>HDL Cholesterol</li>
                  <li>LDL Cholesterol</li>
                  <li>VLDL Cholesterol</li>
                  <li>LDL/HDL Ratio</li>
                  <li>Total Cholesterol/HDL Ratio</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Renal Profile</b></li>
                  <li>BUN</li>
                  <li>Creatinine</li>
                  <li>Uric Acid</li>
                  <li>Calcium</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Thyroid Profile</b></li>
                  <li>TSH</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Cardiac Profile</b></li>
                  <li>ECG</li>
                  <li>ECHO</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Radiaology and Imaging</b></li>
                  <li>X-Ray Chest P.A. View</li>
                  <li>USG Abdomin and Pelvis</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Special Test</b></li>
                  <li>PSA</li>
                  <li>Vitamin B12</li>
                  <li>Vitamin D3</li>
                  <li>Hormone Profile</li>
                  <li>Testesterone</li>
                  <li>Uroflometry</li>
                  <li>Homocysteine</li>
                  <li>HsCRP</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Dental Profile</b></li>
                  <li>2D Dental OPG (X-Ray)</li>
                  <li>Scaling and Polishing</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Consultations</b></li>
                  <li>Report Review and Consultaion by Physician</li>
                  <li>Dental Screening and Consultation</li>
                  <li>Nutritionist Consultation</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>



            <div class="box-item">
              <div class="inner">
                <h5>Child Care Package</h5>
                <ul>
                  <li><b>Routine pathology test</b></li>
                  <li>Complete blood count(CBC)</li>
                  <li>ESR</li>
                  <li>Urine Routine</li>
                  <li>Stool Routine</li>
                  <li>Blood Grouping</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Liver Test</b></li>
                  <li>SGOT</li>
                  <li>SGPT</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Radiaology and Imaging</b></li>
                  <li>X-Ray Chest P.A. View</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Special Test</b></li>
                  <li>BMI</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Dental Profile</b></li>
                  <li>Digital Dental X-Ray</li>
                  <li>Dental Fluoride Gel Application</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Consultations</b></li>
                  <li>Report Review and Consultaion by Paediatrician</li>
                  <li>Dental Screening and Consultation</li>
                  <li>Nutritionist Consultation</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>



            <div class="box-item">
              <div class="inner">
                <h5>Healthy Heart (40+)</h5>
                <ul>
                  <li><b>Routine pathology test</b></li>
                  <li>Complete blood count(CBC)</li>
                  <li>ESR</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Diabetic Evaluation</b></li>
                  <li>Blood Sugar Fasting</li>
                  <li>Blood Sugar PP</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Liver Test</b></li>
                  <li>SGOT</li>
                  <li>SGPT</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lipid Profile</b></li>
                  <li>Triglycerides</li>
                  <li>Cholesterol</li>
                  <li>HDL Cholesterol</li>
                  <li>LDL Cholesterol</li>
                  <li>LDL/HDL Ratio</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Renal Profile</b></li>
                  <li>BUN</li>
                  <li>Creatinine</li>
                  <li>Uric Acid</li>
                  <li>Electrolytes</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Cardiac Evaluation</b></li>
                  <li>ECG</li>
                  <li>ECHO</li>
                  <li>TMT</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lungs Profile</b></li>
                  <li>PFT</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Radiaology and Imaging</b></li>
                  <li>X-Ray Chest P.A. View</li>
                  <li>Body Composition Analysis</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Consultations</b></li>
                  <li>Report Review and Consultaion by Cardiologist</li>
                  <li>Nutritionist Consultation</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>



            <div class="box-item">
              <div class="inner">
                <h5>Diabetic Screening Package</h5>
                <ul>
                  <li><b>Liver Test</b></li>
                  <li>SGOT</li>
                  <li>SGPT</li>
                  <li>Total Billirubin</li>
                  <li>Alkaline Phosphatase</li>
                  <li>S. Protein</li>
                  <li>G. G. T. P</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Lipid Profile</b></li>
                  <li>Triglycerides</li>
                  <li>Cholesterol</li>
                  <li>HDL Cholesterol</li>
                  <li>LDL Cholesterol</li>
                  <li>VLDL Cholesterol</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Renal Profile</b></li>
                  <li>BUN</li>
                  <li>S.Creatinine</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Cardiac Evaluation</b></li>
                  <li>ECG</li>
                  <li>Thyroid Profile</li>
                  <li>TSH</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Special Test</b></li>
                  <li>Diabetic Foot Vascular Assessment</li>
                  <li>Waist Hip Ratio</li>
                  <li>BMI</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Dental Radiology and Imaging</b></li>
                  <li>Digital Dental X-Ray</li>
                </ul>

                <p>&nbsp;</p>

                <ul>
                  <li><b>Consultations</b></li>
                  <li>Report Review and Consultaion by Endocrinologist</li>
                  <li>Dental Screening with Consultation</li>
                  <li>Nutritionist Consultation</li>
                </ul>
                <!--<div class="common-action">
                      <a href="" class="co-btn">Book Now</a>
                  </div>-->
              </div>
            </div>


          </div>




        </div>
      </div>
    </section>
  </div>





</div>