<!-- <style>
    .p {
        display: -webkit-box;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        max-width: 90%;
        position: sticky;
    }

    .btn btn-primary {
        max-width: 100px !important;
        width: 100px !important;
    }

    .help-wrapper .help-list {
        padding: 10px;
        margin: 10px 0px;
        border-radius: 10px;
        overflow: hidden;
        background: #04c4ff1c;
    }

    .help-wrapper .help-list .help-content .h2 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 15px;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    .help-wrapper .help-list .help-content .h3 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 15px;
        font-size: 15px;
    }

    .help-wrapper .help-list .help-list-image {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        overflow: hidden;
    }

    .help-wrapper .help-list .help-list-image img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .help-list-sm .help-content h2 {
        font-size: 20px;
        font-weight: 600;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-word;
        color: #1a1a1a;
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 2.4rem;
        margin-bottom: 0;
    }

    .bg-primary {
        background-color: #3ddb9b !important;
    }

    .bg-secondary {
        background-color: #04c4ff !important;
    }

    a.btn-primary {
        color: #fff !important;
    }

    .btn-primary {
        background: #3ddb9b;
        border: 2px solid #3ddb9b;
    }

    .btn-primary:hover {
        background: #04c4ff;
        border: 2px solid #04c4ff;
    }

    .price-section h2 {
        font-size: 25px;
        font-weight: 800;
        margin-bottom: 14px;
    }

    .product-item-content {
        width: 20%;
    }

    .product-item-text {
        width: 60%;
    }

    .product-add-to-cart-section {
        width: 20%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-add-to-cart-section .btn-primary {
        color: #fff;
    }

    .btn-primary {
        background: #3ddb9b;
        border: 2px solid #3ddb9b;
    }

    .btn-primary:hover {
        background: #04c4ff;
        border: 2px solid #04c4ff;
    }

    .book-an-appointment form .btn-primary {
        width: 100%;
        padding: 20px;
        border-radius: 100px;
        background: #3ddb9b;
        border: 1px solid #fff;
        margin-top: 15px;
    }

    .product-list-item {
        padding: 15px 15px !important;
        display: flex !important;
        justify-content: space-between;
    }

    @media (max-width: 800px) {
        .product-list-item {
            flex-direction: column;
            align-items: center;
        }

        .product-list-item div {
            width: 100%;
            text-align: center;
        }
    }
</style>

<div id="content-wrapper">

    <div id="hire-page">
        <section class="hire-section">
            <div class="custom-container">
                <div class="section-title" style="margin-top: 150px;">


                    <h5>Join our Team</h5>

                    <h1>HIRING NOW</h1>

                    <div class="content">


                        <div class="section-content">
                            <p *ngIf="CareerList.length==0">There are no vacancies at the moment.</p>
                            <div class="col-md-12" *ngFor="let data of CareerList; let index=i">
                                <div class="help-list help-list-sm d-flex product-list-item" style="width: 81rem;">
                                    <div class="product-item-content">
                                        <div class="help-list-image">
                                            <img [src]="data.picturePath">
                                            <img *ngIf="!data.picturePath" src="../../../../../assets/img/no-image.jpg"
                                                alt="noif">
                                        </div>
                                    </div>
                                    <div class="product-item-text">
                                        <div class="help-content">
                                            <div>
                                                <h2>{{data.title}}</h2>
                                                <p style="-webkit-line-clamp:3;" [innerHTML]="data.description">
                                                </p>
                                                <a (click)="ReadMore(data.Id)" class="link">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </section>
    </div>
</div> -->

<style>
  p {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    max-width: 90%;
    position: sticky;
  }

  .btn btn-primary {
    max-width: 100px !important;
    width: 100px !important;
  }
</style>
<div class="ccm-page ccm-page-id-1 page-type-page page-template-full" id="wrapper-outer">
  <div id="wrapper">
    <div class="common-appointment-container">
    </div>


    <div id="content-wrapper" class="mt-0">
      <div id="packages-page">
        <section class="packages-section">
          <div class="custom-container">
            <div class="section-title main-title" >
              <h5>Join our Team</h5>
              <h1>HIRING NOW</h1>
            </div>
            <div class="section-content">
              <div class="row mt-3" *ngIf="CareerList; else noVacancyContent">
                <div class="col-md-12" *ngFor="let data of CareerList; let index=i">
                  <div class="help-list help-list-sm d-flex product-list-item">
                    <div class="product-item-content">
                      <div class="help-list-image">
                        <img [src]="data.picturePath">
                        <img *ngIf="!data.picturePath" src="../../../../../assets/img/no-image.jpg" alt="noif">
                      </div>
                    </div>
                    <div class="product-item-text">
                      <div class="help-content">
                        <div>
                          <h2>{{data.title}}</h2>
                          <b>Qualification: </b> <b style="-webkit-line-clamp: 2 !important;" [innerHTML]="data.qualification"></b> <br>

                          <b>Description:</b>
                          <p style="-webkit-line-clamp: 3 !important;" [innerHTML]="data.description">
                          </p>
                          <a (click)="ReadMore(data.id)" class="link">Read More</a>
                        </div>
                      </div>
                    </div>
                    <div class="product-add-to-cart-section">
                      <div class="price-section">
                        <h2>DeadLine: <br> {{data.closingDate | date}}</h2>
                        <button title="This is a preview feature for Apply. Please contact admin." disabled [routerLink]="['/']" class="applyNow">
                          <i class="far fa-hand-point-right"></i> Apply Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #noVacancyContent>
                <div class="section-content">
                  <p>There are no vacancies at the moment.</p>
                </div>
              </ng-template>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
