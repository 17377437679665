

<body>
  <div class="background-image">
    <!-- <div class="lower-header text-center">
      <div class="top-message  d-none p-0 m-0 ">
        <p class="p-0 m-0">
          COVID-19 care, testing, and guidance. We're here to help. <a href="">Learn more</a> <i class="fas fa-chevron-right"></i>
        </p>
      </div>
    </div> -->

    <section class="content-section hero-section d-flex align-items-center pb-3 pt-5">
      <div class="container ">
        <div class="row">
          <div class=" col-md-12 col-lg-6  d-flex align-items-center">
            <div class="banner-content">
              <h1>Ward <span> Health Information </span>management.</h1>
              <div class="hero-list pt-md-4 pt-sm-2 pb-4">
                <ul>
                  <li>
                    <i class="fas fa-check-circle"></i>Streamline and change the healthcare scenario
                  </li>
                  <li>
                    <i class="fas fa-check-circle"></i>Reports are generate and maintained from the date of
                    registration
                  </li>
                  <li><i class="fas fa-check-circle"></i>Basic health services are given for free.</li>
                </ul>
              </div>
              <!-- <a href=""><button class="btn btn-primary d-none">Know More</button></a> -->
            </div>
          </div>
          <div class="col-md-6 d-sm-none d-md-none d-lg-block d-xl-block">
            <div class="d-flex w-100 justify-content-center">
              <div class="hero-signup-form hero-signup-form-large">
                <h2>Danphe <span>Swatha Paalika </span></h2>
                <form [formGroup]="form" (ngSubmit)="onSubmitReachUsQuickly()" novalidate #myForm="ngForm" class="mt-3">
                  <div class="form-group">
                    <i class="fas fa-user"></i>
                    <input type="text" class="form-control " id="email" name="email" value="" [(ngModel)]="DhcareRuq.email" required placeholder="Enter your email *" formControlName="email" aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted && emailControl1.errors }">
                    <div *ngIf="emailControl1.invalid && (emailControl1.dirty || emailControl1.touched || (emailControl1.untouched && submitted) )" class="alert-sm alert-danger">
                      <div *ngIf="emailControl1.errors.required">Email is required</div>
                    </div>
                  </div>
                  <!-- <div class="email-mobile"> -->
                  <div class="form-group">
                    <i class="fas fa-phone-alt"></i>

                    <input type="text" class="form-control " id="phone" name="phone" [(ngModel)]="DhcareRuq.phone" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" required value="" placeholder="Enter your phone *" formControlName="phone" aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted && phoneControl1.errors }">
                    <div *ngIf="phoneControl1.invalid && (phoneControl1.dirty || (phoneControl1.untouched && submitted))" class="alert-sm alert-danger">
                      <div *ngIf="phoneControl1.errors.required">Phone Number is required</div>

                    </div>
                  </div>
                  <div class="form-group">
                    <i class="fas fa-city"></i>
                    <select [(ngModel)]="selServices" [ngModelOptions]="{standalone: true}" class="form-control">
                      <option *ngFor="let row of filteredPage" [value]="row.title">{{row.title}}</option>
                    </select>
                    <!--<select class="form-control" placeholder="Kathmandu">
                      <option value="Kathmandu">Kathmandu</option>
                      <option value="Kathmandu">Pokhara</option>
                      <option value="Kathmandu">Butwal</option>
                      <option value="Kathmandu">Chitwan</option>
                      <option value="Kathmandu">Dhangadi</option>
                    </select>-->
                  </div>
                  <!-- </div> -->
                  <button type="submit" class="btn btn-primary mt-4">
                    <span *ngIf="loading" class="spinner-border" role="status">Booking ...</span>
                    <span *ngIf="!loading"><i class="material-icons mdi mdi-message-outline"></i>Book An Appointment</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
  </div>


  <section>
    <div class="container d-flex justify-content-center">
      <div class="row w-100">
        <div class="col-md-12 d-sm-block d-md-block d-lg-none d-xl-none">
          <div class="hero-signup-form hero-signup-form-small">
            <h2>Danphe <span>Swatha Paalika </span></h2>
            <form [formGroup]="form1" (ngSubmit)="onSubmitReachUsQuickly1()" novalidate #myForm1="ngForm" class="mt-3">
              <div class="form-group">
                <i class="fas fa-user"></i>
                <input type="text" class="form-control" id="name" name="name" [(ngModel)]="DhcareRuq1.name" aria-describedby="emailHelp"
                       placeholder="Enter your name *" [ngClass]="{ 'is-invalid': submitted1 && nameControl2.errors }">
                <div *ngIf="nameControl2.invalid && (nameControl2.dirty || (nameControl2.untouched && submitted1))" class="alert-sm alert-danger">
                  <div *ngIf="nameControl2.errors.required">Name is required</div>

                </div>
              </div>
              <!-- <div class="email-mobile"> -->
              <div class="form-group">
                <i class="fas fa-phone-alt"></i>
                <input type="text" class="form-control " id="phone" name="phone" [(ngModel)]="DhcareRuq1.phone" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" required value="" placeholder="Enter your phone *" formControlName="phone" aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted1 && phoneControl2.errors }">

                <div *ngIf="phoneControl2.invalid && (phoneControl2.dirty || (phoneControl2.untouched && submitted1))" class="alert-sm alert-danger">
                  <div *ngIf="phoneControl2.errors.required">Phone Number is required</div>

                </div>
              </div>

              <div class="form-group">
                <i class="fas fa-city"></i>
                <select [(ngModel)]="selServices1" [ngModelOptions]="{standalone: true}" class="form-control">
                  <option *ngFor="let row of filteredPage1" [value]="row.title">{{row.title}}</option>
                </select>
              </div>

              <!-- </div> -->
              <button type="submit" class="btn btn-primary mt-4">
                <span *ngIf="loading1" class="spinner-border" role="status">Booking ...</span>
                <span *ngIf="!loading1"><i class="material-icons mdi mdi-message-outline"></i>Book An Appointment</span>
             
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!-- Features -->
  <section class="content-section feature-section">
    <div class="container">
      <div class="content-title text-center">
        <h2>Features</h2>
      </div>
      <div class="row no-gutters">

        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12  d-flex align-items-center">
          <div class="feature-wrapper">
            <div class="row">

              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 my-2">
                <div class="feature-list py-4 my-2">
                  <div>
                    <div class="feature-icon"><i class="fal fa-digital-tachograph text-warning"></i></div>
                    <h6 class="mt-3">Record keeping of patient and the services</h6>
                  </div>
                  <div class="know-more d-flex align-items-center">
                    <a href="#">Know More <span><i class="far fa-long-arrow-right"></i> </span></a>
                  </div>
                </div>
              </div>

              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 my-2">
                <div class="feature-list py-4 my-2">
                  <div>
                    <div class="feature-icon"><i class="fal fa-file-alt text-danger"></i></div>
                    <h6 class="mt-3">Report generation according to the format of the HMIS reports.</h6>
                  </div>
                  <div class="know-more d-flex align-items-center">
                    <a href="#">Know More <span><i class="far fa-long-arrow-right"></i> </span></a>
                  </div>
                </div>
              </div>

              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 my-2">
                <div class="feature-list py-4 my-2">
                  <div>
                    <div class="feature-icon"><i class="fal fa-inventory text-info"></i></div>
                    <h6 class="mt-3">Medicine inventory management.</h6>
                  </div>
                  <div class="know-more d-flex align-items-center">
                    <a href="#">Know More <span><i class="far fa-long-arrow-right"></i> </span></a>
                  </div>
                </div>
              </div>

              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 my-2">
                <div class="feature-list py-4 my-2">
                  <div>
                    <div class="feature-icon"><i class="fal fa-store text-danger"></i></div>
                    <h6 class="mt-3">Track, store, and access patients’ vitals.</h6>
                  </div>
                  <div class="know-more d-flex align-items-center">
                    <a href="#">Know More <span><i class="far fa-long-arrow-right"></i> </span></a>
                  </div>
                </div>
              </div>

              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 my-2">
                <div class="feature-list py-4 my-2">
                  <div>
                    <div class="feature-icon"><i class="far fa-tasks text-success"></i></div>
                    <h6 class="mt-3">Manage registered patients and new services.</h6>
                  </div>
                  <div class="know-more d-flex align-items-center">
                    <a href="#">Know More <span><i class="far fa-long-arrow-right"></i> </span></a>
                  </div>
                </div>
              </div>

              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 my-2">
                <div class="feature-list py-4 my-2">
                  <div>
                    <div class="feature-icon"><i class="far fa-laptop-medical text-primary"></i></div>
                    <h6 class="mt-3">Manage the history of registered patients.</h6>
                  </div>
                  <div class="know-more d-flex align-items-center">
                    <a href="#">Know More <span><i class="far fa-long-arrow-right"></i> </span></a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- <div class="col-md-3">
            <div class="feature-image">
              <img src="assets/image/banner-bg.png" alt="">
            </div>
          </div>
        </div> -->

      </div>
      </div>
  </section>

  <!-- <section class="content-section why-sp">
    <div class="container ">

      <div class="row d-flex align-items-center justify-content-center">
        <div class="col-md-6 d-flex justify-content-center ">
          <div class="content-title text-center">
            <h2>Why ?</h2>
          </div>
          <div class="reason-image d-none d-md-block">
            <img src="./assets/image/question-mark.svg" alt="">
          </div>
        </div>
        <div class="col-sm-12 col-md-6 ">
          <div class="reason-list">
            <ul>
              <li><i class="fas fa-check-circle"></i> Health Post can generate and send reports to NHRC easily.
              </li>
              <li><i class="fas fa-check-circle"></i> NHRC will get real data without human error.</li>
              <li><i class="fas fa-check-circle"></i> Decrease in Data redundancy.</li>
              <li><i class="fas fa-check-circle"></i> Digitization of Patients' records.</li>
              <li><i class="fas fa-check-circle"></i> Reference History.</li>
              <li><i class="fas fa-check-circle"></i> Generate immediate data/information on various sectors.
              </li>
              <li><i class="fas fa-check-circle"></i> Time-saving and efficient.</li>
            </ul>
          </div>
        </div>
      </div>


    </div>
  </section> -->

  <div class="content-section feature-why">
    <div class="container">
      <div class="row">
        <!-- <div class="col-sm-12 col-md-12 col-lg-6  d-flex flex-column">
          <div class="content-title">
            <h2>Features</h2>
          </div>
          <div class="reason-list">
            <ul>
              <li><i class="fas fa-check-circle"></i> Record keeping of patient and the services.
              </li>
              <li><i class="fas fa-check-circle"></i> Report generation according to the format of the HMIS reports.
              </li>
              <li><i class="fas fa-check-circle"></i> Medicine inventory management.</li>
              <li><i class="fas fa-check-circle"></i> Track, store, and access patients’ vitals.</li>
              <li><i class="fas fa-check-circle"></i> Manage registered patients and new services.</li>
              <li><i class="fas fa-check-circle"></i> Manage the history of registered patients.
              </li>
            </ul>
          </div>
        </div> -->
        <div class="col-sm-12 col-md-12 col-lg-12 d-flex flex-column">
          <div class="content-title">
            <h2>Why ?</h2>
          </div>
          <div class="reason-list">
            <ul>
              <li>
                <i class="fas fa-check-circle"></i> Health Post can generate and send reports to NHRC easily.
              </li>
              <li><i class="fas fa-check-circle"></i> NHRC will get real data without human error.</li>
              <li><i class="fas fa-check-circle"></i> Decrease in Data redundancy.</li>
              <li><i class="fas fa-check-circle"></i> Digitization of Patients' records.</li>
              <li><i class="fas fa-check-circle"></i> Reference History.</li>
              <li>
                <i class="fas fa-check-circle"></i> Generate immediate data/information on various sectors.
              </li>
              <li><i class="fas fa-check-circle"></i> Time-saving and efficient.</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>




 


</body>


