import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Route } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { Career } from "../../../cms/models/career.model/career.model";
import { DanphecareService } from "../../services/danphe.service";
import { NotificationService } from "../../services/notification.service";
import { WebsiteService } from "../../websiteservice/website.service";

@Component({
    templateUrl: './careerDetails.html',
    styleUrls: ['./careerDetails.css']

})

export class CareerDetailComponent implements OnInit {

    public careerDetails: Career = new Career();
    public careerId: number;
    t: any;
    previewImgSrc: any;
    constructor(public websiteService: WebsiteService, private notifyService: NotificationService, public route: ActivatedRoute,
        public danphecareservice: DanphecareService) {
        this.route.params.subscribe((params: Params) => {
            this.careerId = params.permalink;
        })

    }

    ngOnInit(): void {
        this.GetCareerDetails();
    }
    public GetCareerDetails() {
        this.websiteService.GetCareerDetails(this.careerId).subscribe(
            res => {
                if (res) {
                    this.careerDetails = Object.assign(this.careerDetails, res);
                    this.previewImgSrc = environment.url + this.careerDetails.picturePath.substr(2);
                }
            },
            err=>{
                this.notifyService.showError("","Unable to get details");
            })
    }
}