<section class="product-page" style="background-image: url('../../../../../assets/img/6a9c11ad72582f58ae75e45856e93596.png');">
    <div class="container">
      <h2>Checkout</h2>
      <h4>Lorem ipsum, or lipsum as it is sometimes known. </h4>
      <i class="fas fa-shopping-cart"></i>
    </div>
  </section>
  <section class="main-section cart-listing cart  checkout-page">
    <div class="custom-container pt-5 pb-5">
      <div class="row gutter-lg mb-10">
        <div class="col-lg-8 pr-lg-4 mb-6">
          <h3 class="title billing-title text-uppercase ls-10 pt-1 pb-3 mb-0">
            My Order
          </h3>
          <table class="shop-table cart-table table table-striped">
            <thead>
              <tr>
                <th class="product-name" width="60px"><span>Product</span></th>
                <th></th>
                <th class="product-price"><span>Price</span></th>
                <th class="product-quantity"><span>Quantity</span></th>
                <th class="product-subtotal"><span>Subtotal</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="">
                  <div class="p-relative product-thumbnail">
                    <a href="#">
                     
                        <img src="../../../../../assets/img/oxymeter.jpg" alt="product" width="300" height="338">
                      
                    </a>
                    <button type="submit" class="btn btn-close"><i class="fas fa-times"></i></button>
                  </div>
                </td>
                <td class="product-name">
                  <a href="#">
                    Pulse Oxymeter
                  </a>
                </td>
                <td class="product-price"><span class="amount">Rs.2500/-</span></td>
                <td class="product-quantity">
                  <div class="input-group">
                    <input class="quantity form-control" type="number" min="1" max="100000" value="2">
                    <button class="quantity-plus fas fa-plus"></button>
                    <button class="quantity-minus fas fa-minus"></button>
                  </div>
                </td>
                <td class="product-subtotal">
                  <span class="amount"><b>Rs.2500.00</b></span>
                </td>
              </tr>
              <tr>
                <td class="">
                  <div class="p-relative product-thumbnail">
                    <a href="#">
                      <figure>
                        <img src="img/oxymeter.jpg" alt="product" width="300" height="338">
                      </figure>
                    </a>
                    <button class="btn btn-close"><i class="fas fa-times"></i></button>
                  </div>
                </td>
                <td class="product-name">
                  <a href="#">
                    Digital Fingertip Pulse Oximeter
                  </a>
                </td>
                <td class="product-price"><span class="amount">Rs.2500/-</span></td>
                <td class="product-quantity">
                  <div class="input-group">
                    <input class="quantity form-control" type="number" min="1" max="100000" value="2">
                    <button class="quantity-plus fas fa-plus"></button>
                    <button class="quantity-minus fas fa-minus"></button>
                  </div>
                </td>
                <td class="product-subtotal">
                  <span class="amount"><b>Rs.5000/-</b></span>
                </td>
              </tr>
              <tr>
                <td class="">
                  <div class="p-relative product-thumbnail">
                    <a href="#">
                      <figure>
                        <img src="../../../../../assets/img/oxymeter.jpg" alt="product" width="300" height="338">
                      </figure>
                    </a>
                    <button class="btn btn-close"><i class="fas fa-times"></i></button>
                  </div>
                </td>
                <td class="product-name">
                  <a href="#">
                    Digital Fingertip Pulse Oximeter
                  </a>
                </td>
                <td class="product-price"><span class="amount">Rs.2500/-</span></td>
                <td class="product-quantity">
                  <div class="input-group">
                    <input class="quantity form-control" type="number" min="1" max="100000" value="2">
                    <button class="quantity-plus fas fa-plus"></button>
                    <button class="quantity-minus fas fa-minus"></button>
                  </div>
                </td>
                <td class="product-subtotal">
                  <span class="amount"><b>Rs.5000/-</b></span>
                </td>
              </tr>
              <tr>
                <td class="">
                  <div class="p-relative product-thumbnail">
                    <a href="#">
                      <figure>
                        <img src="img/oxymeter.jpg" alt="product" width="300" height="338">
                      </figure>
                    </a>
                    <button class="btn btn-close"><i class="fas fa-times"></i></button>
                  </div>
                </td>
                <td class="product-name">
                  <a href="#">
                    Digital Fingertip Pulse Oximeter
                  </a>
                </td>
                <td class="product-price"><span class="amount">Rs.2500/-</span></td>
                <td class="product-quantity">
                  <div class="input-group">
                    <input class="quantity form-control" type="number" min="1" max="100000" value="2">
                    <button class="quantity-plus fas fa-plus"></button>
                    <button class="quantity-minus fas fa-minus"></button>
                  </div>
                </td>
                <td class="product-subtotal">
                  <span class="amount"><b>Rs.5000/-</b></span>
                </td>
              </tr>
            </tbody>
          </table>


        </div>
        <div class="col-lg-4 mb-4 sticky-sidebar-wrapper">
          <div class="order-summary-wrapper sticky-sidebar">
            <div class="order-summary clearfix">
              <h3 class="title text-uppercase ls-10 pt-0">Your Order</h3>

              <div>
                <div class="ordered-products">
                  <h3 class="title text-uppercase ls-10">Products</h3>
                  <ul>
                    <li><span>Pulse Oxymeter</span> * <span>1</span> <span class="float-right">Rs.7500/-</span></li>
                    <li><span>Digital Pulse Oxymeter</span> * <span>1</span> <span
                        class="float-right">Rs.7500/-</span></li>
                    <li><span>Covid Care</span> * <span>1</span> <span class="float-right">Rs.7500/-</span></li>
                  </ul>
                </div>
                <h3 class="title text-uppercase ls-10">Subotal <span class="float-right">Rs.7500/-</span></h3>
                <h3 class="title text-uppercase ls-10">Total <span class="float-right">Rs.7500/-</span></h3>
              </div>

              <div class="payment-methods" id="payment_method">
                <h4 class="title font-weight-bold ls-25 pb-0 mb-1">Payment Methods</h4>
                <ul class="payment-card">
                  <li>
                    <div class="custom-radio">
                      <input type="radio" id="esewa" class="custom-control-input" name="payment">
                      <label for="esewa" class="custom-control-label color-dark"><img src="/../../../assets/images/esewa.png"
                          alt="esewa" /></label>
                    </div>
                  </li>
                  <li>
                    <div class="custom-radio">
                      <input type="radio" id="khalti" class="custom-control-input" name="payment">
                      <label for="khalti" class="custom-control-label color-dark"><img src="/../../../assets/images/khalti.png"
                          alt="khelti" /></label>
                    </div>
                  </li>
                  <li>
                    <div class="custom-radio">
                      <input type="radio" id="connectIPS" class="custom-control-input" name="payment">
                      <label for="connectIPS" class="custom-control-label color-dark"><img src="/../../../assets/images/connectIPS.png"
                          alt="esewa" /></label>
                    </div>
                  </li>
                </ul>
                <h4 class="title font-weight-bold ls-25 pb-0 mb-1">We also Accept</h4>
                <ul class="payment-bank-card">
                  <li>
                    <div class="custom-radio">
                      <input type="radio" id="visa-card" class="custom-control-input" name="bank-card">
                      <label for="visa-card" class="custom-control-label color-dark"><img src="/../../../assets/images/visa.png"
                          alt="visa" /></label>
                    </div>
                  </li>
                  <li>
                    <div class="custom-radio">
                      <input type="radio" id="master-card" class="custom-control-input" name="bank-card">
                      <label for="master-card" class="custom-control-label color-dark"><img
                          src="/../../../assets/images/master-card.png" alt="master" /></label>
                    </div>
                  </li>
                  <li>
                    <div class="custom-radio">
                      <input type="radio" id="union-pay" class="custom-control-input" name="bank-card">
                      <label for="union-pay" class="custom-control-label color-dark"><img
                          src="/../../../assets/images/UnionPay_logo.png" alt="union" /></label>
                    </div>
                  </li>
                  <li>
                    <div class="custom-radio">
                      <input type="radio" id="nabil" class="custom-control-input" name="bank-card">
                      <label for="nabil" class="custom-control-label color-dark"><img
                          src="/../../../assets/images//nabil-bank.png" alt="union" /></label>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="form-group place-order pt-6">
                <button type="submit" class="btn btn-primary btn-block btn-rounded" data-toggle="modal"
                  data-target="#exampleModalCenter">Proceed to Checkout</button>
              </div>

              <!-- login lodal popup  -->
              <!-- Modal -->
              <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">Login to continue</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="login-container">
                        <div class=" justify-content-center h-100 login-flex px-4">
                          <div class="text-center">
                            <a href="" class="login-logo" style="margin-bottom: 0px"><img alt="" height="50"
                                src="/../../../assets/images//logo_final.png"></a>
                            <div class="card text-left">
                              <div class="card-header">
                                <div class="info">
                                  <h2> Stay Home Stay Safe<br>
                                    <p> Connect with your <strong>Doctor</strong> from your home </p>
                                  </h2>
                                </div>
                              </div>
                              <div class="card-body" style="padding-top: 10px">
                                <div class="row" style="margin-bottom: 10px"></div>
                                <form>
                                  <div class="input-group form-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i
                                          class="fas fa-mobile-alt"></i></span></div><input
                                      autocomplete="new-phonenumber"
                                      class="form-control ng-untouched ng-pristine ng-invalid"
                                      formcontrolname="phonenumber" placeholder="Phone Number" type="text">

                                  </div>
                                  <div class="input-group form-group">
                                    <div class="input-group-prepend"><span class="input-group-text">
                                        <i class="fas fa-lock"></i></span></div><input autocomplete="new-password"
                                      class="form-control ng-untouched ng-pristine ng-invalid"
                                      formcontrolname="password" placeholder="Password" type="password">

                                  </div>
                                  <div class="input-group form-group mt-4"><input class="showPassword" id="Password"
                                      name="Password" type="checkbox"><label for="Password">Show Password</label>
                                  </div>
                                  <div class="row d-flex align-items-center">
                                    <div class="col">
                                      <div class="input-group form-group">
                                        <input class="rememberpassword" id="RememberPassword"
                                          name="RememberPassword" type="checkbox">
                                        <label for="RememberPassword">Remember Me</label>
                                      </div>
                                    </div>
                                    <div class="col">
                                        <a [routerLink]="['/billing-details']" class="btn float-right btn-primary" type="submit" data-dismiss="modal"><span>Login</span></a>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <div class="card-footer">
                                <div class="d-flex justify-content-center"><a
                                    href="/api/ForgotPassword/ForgotPassword">Forgot your
                                    password?</a></div>
                                <div class="d-block justify-content-center links text-center">
                                  Don't have an account? <a class="text-primary">Register</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>