import { Component, OnInit } from "@angular/core";

@Component({
    templateUrl: './checkout.html',
    styleUrls: ['checkout-style.css']
})

export class CheckoutComponent implements OnInit {



    constructor() {

    }
    ngOnInit() {

    }
}