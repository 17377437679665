

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { WebsiteService } from '../websiteservice/website.service';

import { DanpheCareContact } from '../../cms/models/danphecare.cms.model';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

import { trigger } from '@angular/animations';
import { NotificationService } from '../services/notification.service';
import { DanpheCareReachUsQuicklyModel } from '../../cms/models/danphecare/danphecare.model';
import { DanphecareService } from '../services/danphe.service';
import { ContactUsModel } from '../../cms/models/contactUs/contactUs.model';
import { from } from 'rxjs';
import { LoadingServiceService } from '../websiteservice/loadingService/loading-service.service';


@Component({
  selector: 'app-contact-page',
  templateUrl: './contact.component.html',
  styleUrls: ["./contact.component.css"]
})
export class ContactPageComponent implements OnInit, AfterViewInit {
  public DhcareRuq: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public contact: DanpheCareContact = new DanpheCareContact();
  public contactUsData: ContactUsModel = new ContactUsModel();
  public loading: boolean = false;
  public submitted: boolean = false;
  public isCompany: boolean = false;
  public form: FormGroup;
  constructor(public websiteService: WebsiteService, private notifyService: NotificationService, private formBuilder: FormBuilder,
    public danphecareservice: DanphecareService, private loadingService: LoadingServiceService) {
    //
  }

  ngOnInit() {
    this.GetContact();
    //this.loadScripts();
    this.form = this.formBuilder.group({
      ContactNumber: ['', [Validators.required]],
      Name: ['', [Validators.required]],
      Email: ["", [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)],
      ],
      Message: ['', Validators.required],
      Subject: ['', ''],
      Address: ['', '']
      //hospitalIdentifier: this.hospitalIdentifier != null ? this.hospitalIdentifier : "NA"
      //password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}')]],
      //confirmpassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}')]]
    });


  }
  ngAfterViewInit() {
    //this.loadScripts();
  }
  loadScripts() {
    const dynamicScripts = [
      //'../content/js/jquery.min.js',
      './content/js/custom.js',
      './content/js/move-img-effect.js',
      './content/js/plugins.js',
      //'../content/js/popper.min.js',
      './content/js/slick.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
  get PhoneNumberControl() {
    return this.form.get("phone");
  }
  get EmailControl() {
    return this.form.get("email");
  }
  get NameControl() {
    return this.form.get("name");
  }
  GetContact() {
    this.websiteService.GetContact().subscribe(res => {
      if (res && res.length > 0) {
        this.contact = Object.assign(this.contact, res[0]);

      }
    },
      res => {
        //this.notifyService.showError("Error", "Internal Error")
      });
  }

  onSubmitReachUsQuickly() {

    if (this.form.valid) {
      this.loading = true;
      this.loadingService.requestStarted();
      this.danphecareservice.reachUsQuickly(this.DhcareRuq)
        .subscribe(res => {
          if (res) {
            this.loadingService.requestEnded();
            this.SuccessPostReachUsQuickly(res);
          }
          else {
            this.loadingService.requestEnded();
            this.Error(res);
          }
          this.loading = false;
        },
          err => {
            this.loadingService.resetSpinner();
            this.loading = false;
            this.Error(err);
          })


    }
    else {
      this.loadingService.resetSpinner();
      this.loading = false;
      this.submitted = true;
      this.notifyService.showInfo("Info", "Please enter the required field");
      (this.submitted) ? setTimeout(() => { this.submitted = false }, 5250) : null
    }

  }
  SuccessPostReachUsQuickly(res) {
    this.loading = false;
    this.DhcareRuq = new DanpheCareReachUsQuicklyModel();
    this.notifyService.showSuccess('Success', 'application submitted successfully ');
    this.form.reset();
  }

  Error(res) {
    this.loading = false;
    const response = res;
    this.notifyService.showError("Error", " Please fill up the required field")
  }
  SendMail(form) {
    if (form.valid) {
      this.loading = true;
      this.loadingService.requestStarted();
      this.contactUsData = Object.assign(this.contactUsData, form.value);
      this.websiteService.PostContactDetils(this.contactUsData).subscribe(

        res => {
          if (res && res.statusCode == 200) {
            this.loadingService.requestEnded();
            this.loading = false;
            this.notifyService.showSuccess("We will contact you shortly. ", "Successfully Submitted");
            this.form.reset();
          }
          else {
            this.notifyService.showSuccess("Unable to Post your Queries", "Error");
            this.loading = false;
            this.loadingService.requestEnded();
          }
        },
        err => {
          this.loadingService.requestEnded();
          this.loading = false;
          this.notifyService.showError(err, "Error");
        });

    }
    else {
      this.loading = false;
      this.notifyService.showError("Error", "Please provide valid Inputs");
    }
  }

}
