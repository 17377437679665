<section id="photo-gallery">
  <div class="section-title main-title">
    <h5>Gallery</h5>
    <h1>Photos</h1>
  </div>
  <div class="photos-container">
    <div class="gallery">
      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/1.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/2.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/3.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>

      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/4.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/5.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/6.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>

      <div class="gallery__row">

        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/7.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/8.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/9.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>

      <div class="gallery__row">

        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/10.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/11.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/12.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>
      <div class="gallery__row">

        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/13.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/14.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/15.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>

      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/16.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/17.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/18.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>

      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/19.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/20.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/21.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>
      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/22.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/23.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/24.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>

      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/25.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/26.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/27.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>
      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/28.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/29.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/30.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>
      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/31.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/32.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/33.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>
      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/34.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/35.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/36.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>
      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/37.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/38.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/39.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>
      <div class="gallery__row">
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/40.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
        <figure class="gallery__thumb">
          <img src="/assets/images/gallery/photos/41.jpg" alt="We Care Photos" class="gallery__image">
          <figcaption class="gallery__caption">Portrait by We Care</figcaption>
        </figure>
      </div>

    </div>
  </div>
</section>
