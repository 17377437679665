import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Base64ImageString } from '../interface/base64Image';
import { HealthAtHomeRequestViewModel } from '../interface/HealthAtHomeRequestViewModel';
import { DanphecareService } from '../services/danphe.service';
import { NotificationService } from '../services/notification.service';
import { LoadingServiceService } from '../websiteservice/loadingService/loading-service.service';

@Component({
  selector: 'app-home-sample-collection-form',
  templateUrl: './home-sample-collection-form.component.html',
  styleUrls: ['./home-sample-collection-form.component.css']
})
export class HomeSampleCollectionFormComponent implements OnInit {

  public sampleCollectionForm: FormGroup;

  public loading: boolean = false;
  public bookNowForm: FormGroup;

  public isFullNameError = false;
  public fullNameErrorText = '';

  public isAgeError = false;
  public ageErrorText = '';

  public isAddressError = false;
  public addressErrorText = '';

  public isGenderError = false;
  public genderErrorText = '';

  public isPhoneNumberError = false;
  public phoneNumberErrorText = '';

  public isEmailError = false;
  public emailErrorText = '';

  public isFileError = false;
  public fileErrorText = "";

  public selectedFiles = [];

  upoloadedImagesinBase64: Base64ImageString[] = []
  @ViewChild("fileDropRef") fileDropEl: ElementRef;

  @ViewChild("closeModal") closeModal: ElementRef;

  constructor(

    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private danphecareservice: DanphecareService,
    private loadingService: LoadingServiceService
  ) { }

  ngOnInit(): void {
    this.sampleCollectionForm = this.formBuilder.group({
      FullName: ['', [Validators.required]],
      Age: ['', [Validators.required, Validators.max(100)]],
      ReferalDoctor: [''],
      LabTest: ['',],
      Address: ['', [Validators.required]],
      Gender: ['', Validators.required],
      PhoneNumber: ['', Validators.required],
      Email: ['', [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)]],
      Attender: [''],
    })
  }


  get FullName() {
    return this.sampleCollectionForm.controls.FullName;
  }
  get Age() {
    return this.sampleCollectionForm.controls.Age;
  }

  get Address() {
    return this.sampleCollectionForm.controls.Address as FormArray;
  }
  get Gender() {
    return this.sampleCollectionForm.controls.Gender;
  }
  get PhoneNumber() {
    return this.sampleCollectionForm.controls.PhoneNumber;
  }
  get Email() {
    return this.sampleCollectionForm.controls.Email;
  }
  get Attender() {
    return this.sampleCollectionForm.controls.Attender;
  }

  get ReferringDoctorName() {
    return this.sampleCollectionForm.controls.ReferringDoctorName;
  }

  changeGender(event) {
    const gender = event.target.value;
    this.sampleCollectionForm.controls.Gender.patchValue(gender);
  }
  onFileSelected(event: any){
    this.selectedFiles = event.target.files;
  }

  checkNull() {
    let isNull = false;
    if (this.FullName.value == null || this.FullName.value.trim() == '') {
      this.isFullNameError = true;
      this.fullNameErrorText = "Full Name is required!"
      isNull = true;
    } else {
      this.isFullNameError = false;
      this.fullNameErrorText = "";
    }

    if (this.Age.value == null || this.Age.value == '') {
      this.isAgeError = true;
      this.ageErrorText = "Age is required!"
      isNull = true;
    } else {
      this.isAgeError = false;
      this.ageErrorText = "";
    }

    if (this.Gender.value == null || this.Gender.value.trim() == '') {
      this.isGenderError = true;
      this.genderErrorText = "Gender is required!"
      isNull = true;
    } else {
      this.isGenderError = false;
      this.genderErrorText = "";
    }

    if(this.Address.value == null || this.Address.value.trim() == '') {
        this.isAddressError = true;
        this.addressErrorText = "Address field are required!";
        isNull = true;
      } else {
        this.isAgeError = false;
        this.ageErrorText = "";
      }


    if (this.PhoneNumber.value == null) {
      this.isPhoneNumberError = true;
      this.phoneNumberErrorText = "Phone number is required!"
      isNull = true;
    } else {
      this.isPhoneNumberError = false;
      this.phoneNumberErrorText = "";
    }

    if (this.Email.value == null || this.Email.value.trim() == '') {
      this.isEmailError = true;
      this.emailErrorText = "Email is required!"
      isNull = true;
    } else {
      this.isEmailError = false;
      this.emailErrorText = "";
    }
    console.log(this.fileDropEl.nativeElement.value)
    if(this.fileDropEl.nativeElement == null){
      this.isFileError = true;
      this.fileErrorText = "OPD Report is required!"
    }else{
      this.isFileError = false;
      this.fileErrorText = ""

    }
    return isNull;
  }

  checkAgeError() {
    if (this.Age.value < 0) {
      this.isAgeError = true;
      this.ageErrorText = "Age cannot be negative";
    }
    if (this.Age.value >= 101) {
      this.isAgeError = true;
      this.ageErrorText = "Age cannot be more than 100";
    }
    if (this.Age.value == 0) {
      this.isAgeError = true;
      this.ageErrorText = "Age cannot be 0";
    }
    return this.isAgeError;
  }

  resetError() {
    this.isFullNameError = false;
    this.fullNameErrorText = '';

    this.isAgeError = false;
    this.ageErrorText = '';

    this.isGenderError = false;
    this.genderErrorText = '';

    this.isPhoneNumberError = false;
    this.phoneNumberErrorText = '';

    this.isEmailError = false;
    this.emailErrorText = '';

    this.closeModal.nativeElement.click();
  }


  async handleSubmit() {
    if (this.checkNull()) {
      this.notificationService.showError("Please fill the required fields.", "Error!");
      return;
    }
    if (this.checkAgeError()) {
      this.notificationService.showError(this.ageErrorText, "Error!");
      return;
    }
    if (this.Email.invalid) {
      this.notificationService.showError("Please enter a valid email!", "Error!");
      return;
    }
    let fileBase64Url: any;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      await this.convertImagetoBase64(this.selectedFiles[i])
                  .then(
                    res => fileBase64Url = res
                  );
      const fileBase64 = fileBase64Url.split(',')[1];
      const base64Image: Base64ImageString = {
        base64String: fileBase64,
        fileName: this.selectedFiles[i].name
      }
      this.upoloadedImagesinBase64.push(base64Image);
    }
    //call api to send the form and packageName;
    const request: HealthAtHomeRequestViewModel = {
      Form: this.sampleCollectionForm.value,
      Base64ImageList: this.upoloadedImagesinBase64
    }
    this.loadingService.requestStarted();
    this.danphecareservice.requestHealthAtHome(request).subscribe(res => {
      if (res) {
        this.loadingService.requestEnded();
        this.selectedFiles = []
        this.SuccessPost();
      }
      else {
        this.loadingService.requestEnded();
        this.Error(res);
        this.selectedFiles = []
      }
      this.loading = false;
    },
      err => {
        this.loadingService.resetSpinner();
        this.loading = false;
        this.selectedFiles = []
        this.Error(err);
      })

  }
  SuccessPost() {
    this.loading = false;
   this.notificationService.showSuccess('Success', 'application submitted successfully ');
   this.closeModal.nativeElement.click();

 }
  Error(err) {
    this.loading = false;
    this.notificationService.showError("Error", "Something went wrong please check console for details");
    console.log(err);
  }

  convertImagetoBase64 = (img:any) => new Promise((resolve,reject) =>{
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = () => resolve(reader.result);
    reader.onerror= error => reject(error);
})

}
