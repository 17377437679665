export class Career {

    public id: number;
    public hospitalId: number;
    public title: string;
    public description: string;
    public qualification: string;
    public openingDate: Date = new Date();
    public closingDate: Date;
    public isClosed: boolean;
    public picturePath: string;
    public ShowOnHomepage: boolean;
    public MetaKeywords: string;
    public MetaDescription: string;
    public MetaTitle: string;
    public PermaLink: string;
    public MarkAsNew: boolean;
    public MarkAsNewStart: boolean;
    public MarkAsNewEnd: boolean;
    public IsActive: boolean;
    public CreatedBy: string;
    public CreatedOn: Date;
    public UpdatedBy: string;
    public UpdatedOn: Date;

    constructor() {


    }
}