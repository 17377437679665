

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Blogs, MetaTag } from '../../cms/models/danphecare.cms.model';
import { Router } from '@angular/router';
import { WebsiteService } from '../websiteservice/website.service';
import { Title, Meta } from '@angular/platform-browser';
import { NotificationService } from '../services/notification.service';
import { MetaService } from '@ngx-meta/core';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog.component.html'
})
export class BlogPageComponent implements OnInit, AfterViewInit {
  public blogsList: Array<Blogs> = new Array<Blogs>();
  public blogId: number;
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public tag: MetaTag = new MetaTag();
  constructor(public http: HttpClient, public websiteService: WebsiteService, public notifyService: NotificationService,
    public router: Router, private titleService: Title, private meta: MetaService) {
    //
  }

  ngOnInit() {
    this.websiteService.GetBlogs().subscribe(res => {
      if (res) {
        this.blogsList = [];
        this.blogsList = Object.assign(this.blogsList, res);
        this.blogsList.forEach((item, index) => {
          item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
        });
        this.meta.setTitle(this.blogsList[0].metaTitle);
        this.meta.setTag("description", this.blogsList[0].metaContent);
        this.meta.setTag("keywords", this.blogsList[0].metaKeywords);
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Blogs Found!");
      });

  }
  ngAfterViewInit() {
    //this.loadScripts();
  }
  
  GoToBlogDetails(permaLink) {
    this.router.navigate(['/blog', permaLink]); 
  }
}
