
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TeleMedService } from '../websiteservice/teleMedService';
import { SingleProductViewModel } from './single-product.model';

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.css']
})
export class SingleProductComponent implements OnInit {

  @ViewChild("formComponent") formComponent;

  public ProductId: any;
  public showPopup: boolean = true;

  public productImageExist: boolean = false;
  public previewImgSrc: any;
  public productDiscoundPercent: any;
  public ProductDetails: SingleProductViewModel = new SingleProductViewModel();
  public timePeriod: number = 1;
  showProductNotFoundMessage: boolean = false;


  constructor(public route: ActivatedRoute, public teleMedService: TeleMedService) {
    this.route.params.subscribe((params: Params) => {
      this.ProductId = params.permalink;
    })
  }

  ngOnInit() {
    this.Preview();
  }
  public Preview() {
    this.teleMedService.GetSingleProductViewById(this.ProductId).subscribe(
      res => {
        if (res) {
          this.showProductNotFoundMessage = false;
          this.ProductDetails = new SingleProductViewModel();
          this.productImageExist = false;
          this.ProductDetails = Object.assign(this.ProductDetails, res);
          this.productDiscoundPercent = this.ProductDetails.OldPrice ? ((1 - (this.ProductDetails.Price) / this.ProductDetails.OldPrice) * 100) : 0;
          if (this.ProductDetails.PictureId) {
            this.productImageExist = true;
            this.GetProductImage(this.ProductDetails.PictureId);
          }
          this.TotalPrice(1);
        }
      },
      err => {
        this.showProductNotFoundMessage = true;
      });
  }

  public GetProductImage(productImageId) {
    this.teleMedService.GetProductPictureById(productImageId).subscribe(
      res => {
        if (res) {
          this.previewImgSrc = 'data:image/jpeg;base64,' + res;
        }

      },
      err => {
        alert(err);
      }
    );
  }

  public TotalPrice(timePeriod) {

    this.ProductDetails.OldPrice *= timePeriod;
    this.ProductDetails.Price *= timePeriod;

  }

  public resetError() {
    this.formComponent.resetError();
  }

}
