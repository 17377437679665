

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { OurServiceModel } from '../../cms/models/OurServices.model';
import { HttpClient } from '@angular/common/http';

import { WebsiteService } from '../websiteservice/website.service';
import { DanpheCareDepartment, MetaTag } from '../../cms/models/danphecare.cms.model';

import { MediaCoverage, TeamMember, Testimonial, TestimonialMain, DanpheCareContact } from '../../cms/models/danphecare.cms.model';

import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { Router } from '@angular/router';
import * as moment from 'moment';

import { ScriptService } from '../scriptservice/script.service';

import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '@ngx-meta/core';
import { DanphecareModel, DanpheCareReachUsQuicklyModel } from '../../cms/models/danphecare/danphecare.model';
import { NotificationService } from '../services/notification.service';
import { DanphecareService } from '../services/danphe.service';
import { AboutUsModel } from '../../cms/models/aboutUs.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-home-page',
  templateUrl: './homepage.component.html',
  styleUrls: ["./homepage.component.css", "../danphehr/danpheehr.component.css"]
})
export class HomePageComponent implements OnInit, AfterViewInit {
  slideConfig = { "slidesToShow": 7, "slidesToScroll": 7, "infinite": false, "dots": false, "autoplay": true, "autoplaySpeed": 5000, 'rows': 1, "arrows": true };
  slickInit(e) {
    console.log('slick initialized');
  }
  public Dhcare: DanphecareModel = new DanphecareModel();

  public DhcareRuq: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  homeIsolationApplyForm: FormGroup;
  reachUsQuicklyForm: FormGroup;
  grecaptcha: any;
  loginVal: any;
  dataFromAppsettings: any;
  captchaRes: any[];
  public loading: boolean = false;
  loading_ruq = false;
  public emlError = false;
  public phnError = false;
  public nameErrror = false;
  public contact: DanpheCareContact = new DanpheCareContact();
  public showPaymentMethod: boolean = false;
  public showMoreContent: boolean = false;
  public submitted: boolean = false;
  public submitted_ruq: boolean = false;
  public reCAPTCHAstatus: boolean = false;

  public Usrlist: Array<any> = new Array<any>();
  loadAPI: Promise<any>;
  star: number;
  registerForm = new FormGroup({ name: new FormControl(), phonenumber: new FormControl(), email: new FormControl() });
  submitForm: boolean = false;
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public tag: MetaTag = new MetaTag();
  public content: string;
  //  icon = require("src/assets/images/danphecarelogo.png");
  public ourServicesList: Array<OurServiceModel> = new Array<OurServiceModel>();
  public departmentList: Array<DanpheCareDepartment> = new Array<DanpheCareDepartment>();
  ourTeamMemberList: TeamMember[];
  coreTeamMemberList: TeamMember[];
  ourMediaCoverageList: MediaCoverage[];
  testimonialList: Array<Testimonial> = new Array<Testimonial>();
  testimonialMain: TestimonialMain = new TestimonialMain();
  public aboutUs: AboutUsModel = new AboutUsModel();
  slides = [];
  slideConfig35 = {
    "slidesToShow": 3, "slidesToScroll": 1, "infinite": true, "dots": false, "autoplay": true, "autoplaySpeed": 3000, 'rows': 1, "arrows": true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 924,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
  };
  slides1 = [
    { img: "fal fa-procedures text-primary", title: "Inpatient & ward" },
    { img: "fas fa-user-injured text-danger", title: "ICU/CCU" },
    { img: "far fa-stethoscope text-warning", title: "Outpatient" },
    { img: "fas fa-hospital-user text-secondary", title: "Departmental Integration" },

    { img: "fad fa-vials text-success", title: "Laboratory" },
    { img: "fal fa-inventory text-info", title: "Inventory" },
    { img: "fas fa-x-ray text-primary", title: "Radiology Module" },
    { img: "fal fa-file-invoice-dollar text-success", title: "General Account" },

    { img: "fal fa-ambulance text-info", title: "Emergency" },
    { img: "fal fa-prescription-bottle-alt text-warning", title: "Pharmacy" },
    { img: "fad fa-file-medical-alt text-danger", title: "ADT" },
    { img: "fad fa-money-check-alt text-info", title: "Insurance" },


  ];

  constructor(public http: HttpClient, public websiteService: WebsiteService, public notifyService: NotificationService,
    private formBuilder: FormBuilder, public danphecareservice: DanphecareService, public scriptService: ScriptService,
    public routing: Router, private titleService: Title, private metaService: Meta, private readonly meta: MetaService
  ) {



  }


  ngOnInit() {

    this.websiteService.GetCoreTeamMembers().subscribe(res => {
      if (res) {
        this.ourTeamMemberList = [];
        this.ourTeamMemberList = Object.assign(this.ourTeamMemberList, res);
        this.coreTeamMemberList = this.ourTeamMemberList.filter(t => t.isCoreTeam === true);
        this.coreTeamMemberList.forEach((item, index) => {
          if (item.imagePath !== null) {
            item.imagePath = environment.url + item.imagePath.substr(2);
          }
          if (item.coverPhoto !== null) {
            item.coverPhoto = environment.url + item.coverPhoto.substr(2);
          }
          this.meta.setTitle(this.coreTeamMemberList[0].metaTitle);
          this.meta.setTag("description", this.coreTeamMemberList[0].metaContent);
          this.meta.setTag("keywords", this.coreTeamMemberList[0].metaKeywords);
          this.meta.setTag('og:title', this.coreTeamMemberList[0].metaTitle);
          this.meta.setTag('og:image', "https://cms.wecarehealthcenter.org/assets/img/6a9c11ad72582f58ae75e45856e93596.png");
          this.meta.setTag('og:image:width', "800");
          this.meta.setTag('og:image:height', "800");
          this.meta.setTag('og:url', location.href);
          this.meta.setTag('og:type', "Home");
          this.meta.setTag('og:description', this.coreTeamMemberList[0].metaContent);
          this.meta.setTag('twitter:card', location.href);
          this.meta.setTag('twitter:title', this.coreTeamMemberList[0].metaTitle);
          this.meta.setTag('twitter:description', this.coreTeamMemberList[0].metaContent);
          this.meta.setTag('twitter:image', "https://cms.wecarehealthcenter.org/assets/img/6a9c11ad72582f58ae75e45856e93596.png");
          this.meta.setTag('og:app_id', "260103905625004");

        });
      }
    },
      res => {
        /*  console.log("Error Message: " + res);*/
        // this.notifyService.showError("Info", "No Services Found!");
      });


    this.GetAboutUs();
    this.GetOurServices();
    this.GetDepartment();
    this.GetOurMediaCoverage();
    this.GetTestimonialMain();
    this.GetTestimonials();
    this.GetContact();
    this.CaptchaCallback();
    //}
  }
  ngAfterViewInit() {
    //this.loadScripts();
  }

  GetAboutUs() {
    this.websiteService.GetAboutUs().subscribe(res => {
      if (res && res.length > 0) {
        this.aboutUs = Object.assign(this.aboutUs, res[0]);
        this.aboutUs.imagePath = environment.url + this.aboutUs.imagePath.substr(2);

      }
    },
      res => {
        //this.notifyService.showError("Error", "Internal Error")
      });



  }

  GetOurServices() {
    this.websiteService.GetOurServices().subscribe(res => {
      if (res) {
        this.ourServicesList = res;
        // this.ourServicesList = res.filter((dep, idx) => idx < 3);
        this.ourServicesList.forEach((item, index) => {
          if (item.imagePath !== null) {
            item.imagePath = environment.url + item.imagePath.substr(2);
            let image = "";
            image = item.imagePath;
            this.slides.push({ img: image, title: item.serviceName, permaLink: item.permaLink })

          }
          // if (item.iconPath !== null) {
          //   item.iconPath = "https://cms.danphecare.com" + item.iconPath.substr(2);
          // }
          // if (item.imagePath !== null) {
          //   item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
          // }
          // if (item.coverImage !== null) {
          //   item.coverImage = "https://cms.danphecare.com" + item.coverImage.substr(2);
          // }
          // if (item.subscriptionImage !== null) {
          //   item.subscriptionImage = "https://cms.danphecare.com" + item.subscriptionImage.substr(2);
          // }

        });

      }
    },
      res => {
        console.log(res);
        console.log(typeof res);
        //this.notifyService.showError("Info", "No Services Found!");
      });
  }

  GetMetaTag() {
    this.websiteService.GetMetaTag()
      .subscribe(res => this.SuccessMetaTag(res),
        res => this.ErrorMetaTag(res));
  }
  SuccessMetaTag(res) {
    this.metaTag = res;
    if (this.metaTag.length !== 0) {
      this.metaTag = res.filter(x => x.page === 'Home Page');

      this.tag = this.metaTag[0];
      this.titleService.setTitle(this.tag.title);
      this.metaService.updateTag({ name: 'keywords', content: `${this.tag.keywords}` });
      this.metaService.updateTag({ name: 'description', content: `${this.tag.content}` });
      //this.metaService.addTags([
      //  { name: 'keywords', content: `${this.tag.keywords}` },
      //  { name: 'description', content: `${this.tag.content}` },
      //  { name: 'robots', content: 'index, follow' }
      //]);
    }
  }
  ErrorMetaTag(res) {
    //this.notifyService.showError("Info", "No Meta tag Found!");
  }



  GetDepartment() {
    this.websiteService.GetDepartment().subscribe(res => {
      if (res) {
        this.departmentList = [];
        this.departmentList = res.filter((dep, idx) => idx < 4);
        this.departmentList.forEach((item, index) => {
          if (item.imagePath !== null) {
            item.imagePath = environment.url + item.imagePath.substr(2);
          }
          if (item.iconPath !== null) {
            item.iconPath = environment.url + item.iconPath.substr(2);
          }


        });
        //this.departmentList = Object.assign(this.departmentList, res);
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Department Found!");
      });
  }
  get PhoneNumberControl() {
    return this.registerForm.get("phonenumber");
  }
  get EmailControl() {
    return this.registerForm.get("email");
  }
  get NameControl() {
    return this.registerForm.get("name");
  }


  GetOurMediaCoverage() {
    this.websiteService.GetOurMediaCoverage().subscribe(res => {
      if (res) {
        this.ourMediaCoverageList = [];
        this.ourMediaCoverageList = Object.assign(this.ourMediaCoverageList, res);
        this.ourMediaCoverageList.forEach((item, index) => {
          item.imagePath = environment.url + item.imagePath.substr(2);
        });
      }
    },
      res => {
        console.log(typeof res);
        console.log("Error Message: " + res);
        // this.notifyService.showError("Info", "No Services Found!");
      });
  }

  GetTestimonialMain() {
    this.websiteService.GetTestimonialMain().subscribe(res => {
      if (res) {
        let testimonialMain = [];
        this.testimonialMain = Object.assign(testimonialMain, res[0]);
      }
    },
      res => {
        console.log("Error Message: " + res);
        // this.notifyService.showError("Info", "No Services Found!");
      });
  }

  GetTestimonials() {
    this.websiteService.GetTestimonials().subscribe(res => {
      if (res) {
        this.testimonialList = [];
        this.testimonialList = Object.assign(this.testimonialList, res);
        this.testimonialList.forEach(t => {
          t.imagePath = environment.url + t.imagePath.substring(2);
          let starString = this.GenerateStar(t.star);
          t.starString = starString;
          this.star = Number(t.star);
        })
      }
    },
      res => {
        console.log("Error Message: " + res);
        // this.notifyService.showError("Info", "No Services Found!");
      });
  }

  GenerateStar(StarCount): string {
    let stringStar: string;
    switch (StarCount) {
      case 1: {
        stringStar = `
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star-o" data-jest="empty star"></span>
        <span class="fa fa-star-o" data-jest="empty star"></span>
        <span class="fa fa-star-o" data-jest="empty star"></span>
        <span class="fa fa-star-o" data-jest="empty star"></span>
        `;
        break;
      }
      case 2: {
        stringStar = `
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star-o" data-jest="empty star"></span>
        <span class="fa fa-star-o" data-jest="empty star"></span>
        <span class="fa fa-star-o" data-jest="empty star"></span>
        `;
        break;

      }
      case 3: {
        stringStar = `
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star-o" data-jest="empty star"></span>
        <span class="fa fa-star-o" data-jest="empty star"></span>
        `;
        break;

      }
      case 4: {
        stringStar = `
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star-o" data-jest="empty star"></span>
        `;
        break;

      }
      case 5: {
        stringStar = `
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        `;
        break;
      }
      default: {
        stringStar = `
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        <span class="fa fa-star" data-jest="empty star"></span>
        `;
        break;
      }
    }
    return stringStar;
  }


  get f() {
    return this.homeIsolationApplyForm.controls;
  }
  get g() {
    return this.reachUsQuicklyForm.controls;
  }

  GetAppliedUsrlist() {
    this.danphecareservice.GetAppliedUsrlist()
      .subscribe(res => this.SuccessGetAppliedUsrlist(res),
        res => this.GetAppliedUsrlistError(res));

  }

  SuccessGetAppliedUsrlist(res) {
    this.Usrlist = res;
    for (var i = 0; i < this.Usrlist.length; i++) {
      this.Usrlist[i].CreatedOn = moment(this.Usrlist[i].CreatedOn).format("YYYY-MM-DD");
    }
    //this.notifyService.showSuccess('Success', ' User list Load');
  }
  GetAppliedUsrlistError(res) {
    //this.notifyService.showError("Error", " field to load User list")
  }

  onSubmit() {
    // this.CaptchaCallback();
    this.submitted = true;
    this.loading = true;
    if (this.homeIsolationApplyForm.invalid) {
      //this.submitted = false;
      this.loading = false;
      this.reCAPTCHAstatus == false;
      //this.notifyService.showError("Error", " Please fill up the form properly")
    }
    if (this.homeIsolationApplyForm.valid) {
      if (this.reCAPTCHAstatus == true) {
        if (this.Dhcare != null) {
          this.danphecareservice.applytoIsolation(this.Dhcare)
            .subscribe(res => this.SuccessPostapplyIso(res),
              res => this.Error(res));
        }
      } else {
        //this.notifyService.showError("Error", "Human verification failed.");
        this.loading = false;
      }
    }

  }
  onSubmitReachUsQuickly() {
    if (this.registerForm.valid) {
      this.submitForm = true;
      this.loading = true;
      this.danphecareservice.reachUsQuickly(this.DhcareRuq)
        .subscribe(res => this.SuccessPostReachUsQuickly(res),
          res => this.Error(res));
    }
    else {
      this.submitForm = true;
      this.loading = false;
      this.notifyService.showInfo("Info", "Please enter the required field");
      //this.registerForm.reset();

      (this.submitForm) ? setTimeout(() => { this.submitForm = false }, 5250) : null

    }
  }
  SuccessPostapplyIso(res) {
    this.submitForm = true;
    this.homeIsolationApplyForm.reset();
    // this.GetAppliedUsrlist();
    this.notifyService.showSuccess('success', 'application submitted successfully');
    this.reCAPTCHAstatus = false;
    this.loading = false;
    this.submitForm = false;

  }
  SuccessPostReachUsQuickly(res) {
    this.loading = false;
    this.DhcareRuq = new DanpheCareReachUsQuicklyModel();

    // this.GetAppliedUsrlist();
    this.notifyService.showSuccess('success', 'application submitted successfully ');
    this.submitForm = false;
    this.reCAPTCHAstatus = false;
    this.registerForm.reset();
    //  this.reCAPTCHAstatusRUQ = false;

    //this.CaptchaCallback();
  }

  Error(res) {
    var response = res;
    this.submitted = false;
    this.submitted_ruq = false;
    this.loading = false;
    this.loading_ruq = false;
    this.notifyService.showError("Error", " Please fill up the required field")
  }



  PaymentMethod() {
    this.showPaymentMethod = true;
  }
  hide() {
    this.showPaymentMethod = false;
    this.showMoreContent = false;
  }
  knowmore() {
    this.showMoreContent = true;
  }
  handleSuccess(recaptchaRes: any[]) {
    this.captchaRes = recaptchaRes;
    if (this.captchaRes.length > 1) {
      this.reCAPTCHAstatus = true;
    }
    console.log("recaptchaResponse");
    console.log(this.captchaRes);
  }

  GotoTelemedicine() {
    this.routing.navigate(['/login']);
  }
  public CaptchaCallback() {

    this.registerForm = this.formBuilder.group({
      phonenumber: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)],
      ],

    });
  }

  GoToServiceDetails(permaLink) {
    // this.servicesId = id;
    this.routing.navigate(['/service', permaLink]);


  }

  DepartmentDetails(permaLink) {
    this.routing.navigate(['/department', permaLink]);
  }

  GoToTeamMemberDetails(id) {
    this.routing.navigate(['/coreteamdetails'], { queryParams: { id: id } });
  }

  GetContact() {
    this.websiteService.GetContact().subscribe(res => {
      if (res) {



        this.contact = Object.assign(this.contact, res[0]);
      }
    },
      res => {
        console.log(res);
        console.log(typeof res);
        //this.notifyService.showError("Info", "No Contact Found!");
      });
  }
}
