
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

//import { identifierName } from '@angular/compiler';

// import { AuthService } from '../../services/auth.service';
import { EndpointBase } from '../services/endpoint-base.service';
import { environment } from 'src/environments/environment';
import { ContactUsModel } from '../../cms/models/contactUs/contactUs.model';


@Injectable()
export class WebsiteEndpoint extends EndpointBase {

  private readonly _websiteUrl: string = "api/danphecarewebsite";
  private readonly _teleMedUrl: string = "api"


  constructor(http: HttpClient) {
    super(http);
  }

  GeAboutUsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getAboutUs`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GeAboutUsEndpoint()));
      }));
  }

  GetServiceByIdEndpoint<T>(permaLink: string): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getServiceById/${permaLink}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetServiceByIdEndpoint(permaLink)));
      }));
  }
  GetOurServicesEndpoint<T>(): Observable<T> {
    //  const endpointUrl = `${environment.url}/${this._websiteUrl}/getOurServices`;
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getOurServices`;
    return this.http.get<T>(endpointUrl, this.requestHeaders);

    //.pipe(
    //catchError(error => {
    //  return throwError(this.handleError(error, () => this.GetOurServicesEndpoint()));
    //}));
  }


  //#region Danphe Department


  GetDepartmentByIdEndpoint<T>(permalink: string): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getDepartmentById/${permalink}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetDepartmentByIdEndpoint(permalink)));
      }));
  }

  GetDepartmentEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getDepartmentList`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetDepartmentEndpoint()));
      }));
  }

  GetSubHeadingByDepartmentIdEndpoint<T>(permalink: string): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getSubHeadingByDepartmentId/${permalink}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetSubHeadingByDepartmentIdEndpoint(permalink)));
      }));
  }
  GetSubHeadingByDoctorIdEndpoint<T>(permalink: string): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getSubHeadingByDoctorId/${permalink}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetSubHeadingByDoctorIdEndpoint(permalink)));
      }));

  }
  //#endregion Danphe Department

  //#region Danphe Doctor

  GetDoctorByIdEndpoint<T>(permalink: string): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getDoctorById/${permalink}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetDoctorByIdEndpoint(permalink)));
      }));
  }

  GetDoctorEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getDoctorList`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetDoctorEndpoint()));
      }));
  }
  GetDoctorSortingEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getDoctorSortingList`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetDoctorSortingEndpoint()));
      }));
  }

  //#endregion Danphe Doctor

  //#region Danphe DepartmentConsultation


  GetConsultationByIdEndpoint<T>(ConId: number): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getConsultationById/${ConId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetConsultationByIdEndpoint(ConId)));
      }));
  }

  GetDepConsultationEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getConsultationList`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetDepConsultationEndpoint()));
      }));
  }


  //#endregion Danphe Department Consultation

  //#region Danphe Resourceful Articles

  GetArticlesByIdEndpoint<T>(ArticlesId: number): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getArticlesById/${ArticlesId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetArticlesByIdEndpoint(ArticlesId)));
      }));
  }
  GetArticlesIdEndpoint<T>(ArticlesId: number): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getArticlesId/${ArticlesId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetArticlesIdEndpoint(ArticlesId)));
      }));
  }
  GetArticlesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getArticles`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetArticlesEndpoint()));
      }));
  }


  //#endregion Danphe Resourceful Articles

  //#region Danphe News

  GetNewsByIdEndpoint<T>(NewsId: number): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getNewsById/${NewsId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetNewsByIdEndpoint(NewsId)));
      }));
  }

  GetNewsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getNews`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetNewsEndpoint()));
      }));
  }


  //#endregion Danphe News

  //#region Danphe Blogs

  GetBlogsByIdEndpoint<T>(permaLink: string): Observable<T> {

    const endpointUrl = `${environment.url}/${this._websiteUrl}/getBlogsById/${permaLink}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetBlogsByIdEndpoint(permaLink)));
      }));
  }

  GetBlogsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getBlogs`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetBlogsEndpoint()));
      }));
  }


  //#endregion Danphe Blogs

  //#region Danphe MetaTag


  GetMetaTagByIdEndpoint<T>(MetaTagId: number): Observable<T> {

    const endpointUrl = `${environment.url}/${this._websiteUrl}/getMetaTagById/${MetaTagId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetMetaTagByIdEndpoint(MetaTagId)));
      }));
  }

  GetMetaTagEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getMetaTag`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetMetaTagEndpoint()));
      }));
  }


  //#endregion Danphe MetaTag

  //#region Danphe Contact
  GetContactEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getDanpheCareContact`;
    return this.http.get<T>(endpointUrl, this.requestHeaders);
    //.pipe(
    //catchError(error => {
    //  return throwError(this.handleError(error, () => this.GetContactEndpoint()));
    //}));
  }

  //#endregion Danphe Contact

  //#region Danphe Expat
  GetExpatEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getExpat`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetExpatEndpoint()));
      }));
  }


  //#endregion Danphe Expat

  //#region Danphe Webinar
  GetWebinarEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getWebinar`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetWebinarEndpoint()));
      }));
  }


  //#endregion Danphe Webinar

  //#region Get Team Members

  GetOurTeamMembersEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getOurTeamMembers`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetOurTeamMembersEndpoint()));
      }));
  }


  GetCoreTeamMembersEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getCoreTeamMembers`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetCoreTeamMembersEndpoint()));
      }));
  }


  GetSortingEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getTeamSortingList`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetDepartmentEndpoint()));
      }));
  }

  //#endregion

  //#region GET Media coverage
  GetOurMediaCoverageEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getOurMediaCoverage`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetOurMediaCoverageEndpoint()));
      }));
  }
  //#endregion

  //#region : TestimonialsMain
  GetTestimonialMainEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getTestimonialMain`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetTestimonialMainEndpoint()));
      }));
  }
  //#endregion

  //#region Get Testimonials
  GetTestimonialsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getTestimonials`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetTestimonialsEndpoint()));
      }));
  }
  //#endregion

  //#region Department Details

  GetDepartmentTabContentByIdEndpoint<T>(permaLink: string): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getDepartmentContentById/${permaLink}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetDepartmentTabContentByIdEndpoint(permaLink)));
      }));
  }

  GetServiceByDepartmentIdEndpoint<T>(permalink: string): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getServiceByDepartmentId/${permalink}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetServiceByDepartmentIdEndpoint(permalink)));
      }));
  }

  GetDoctorByDepartmentIdEndpoint<T>(permalink: string): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getDoctorByDepartmentId/${permalink}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetDoctorByDepartmentIdEndpoint(permalink)));
      }));
  }

  GetArticlesByDepartmentIdEndpoint<T>(DepartmentId: number): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getArticlesByDepartmentId/${DepartmentId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetArticlesByDepartmentIdEndpoint(DepartmentId)));
      }));
  }

  GetCoreTeamByIdEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getCoreTeamById/${id}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetCoreTeamByIdEndpoint(id)));
      }));
  }
  //here is
  GetPackageDetailsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.telMedUrl}/${this._teleMedUrl}/product/GetPackageList`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetPackageDetailsEndpoint()));
      }));
  }

  GetSubServicesByIdEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getSubServicesById/${id}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetSubServicesByIdEndpoint(id)));
      }));
  }

  GetSubServicesDetailsByIdEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/getSubServicesDetailsById/${id}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetSubServicesDetailsByIdEndpoint(id)));
      }));
  }

  GetCareerDetailsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/GetCareerList`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetCareerDetailsEndpoint()));
      }));
  }

  GetCareerDetailEndpoint<T>(permaLink: number): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/GetCareerDetailByid/${permaLink}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetCareerDetailEndpoint(permaLink)));
      }));
  }


  GetLabEquipmentsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${environment.url}/${this._teleMedUrl}/DanpheCareCMS/GetLabEquipmentsList`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetLabEquipmentsEndpoint()));
      }));
  }


  GetLabEquipmentByServiceIdEndpoint<T>(permalink: number): Observable<T> {
    const endpointUrl = `${environment.url}/${this._websiteUrl}/GetLabEquipmentsByServiceId/${permalink}`;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetLabEquipmentByServiceIdEndpoint(permalink)));
      }));
  }
  PostContactDetilsEndpoint<T>(contactDetailsObjectString: string) {
    // let contactData = JSON.stringify(contactDetails);
    const endpointUrl = `${environment.url}/${this._teleMedUrl}/ContactUs/SendUserDetails`;
    return this.http.post<T>(endpointUrl, contactDetailsObjectString, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.PostContactDetilsEndpoint(contactDetailsObjectString)));
      }));

  }
  PostContactNumberEndpoint<T>(contactDetailsObjectString: string) {
    // let contactData = JSON.stringify(contactDetails);
    const endpointUrl = `${environment.url}/${this._teleMedUrl}/ContactUs/SendUserDetails`;
    return this.http.post<T>(endpointUrl, contactDetailsObjectString, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.PostContactDetilsEndpoint(contactDetailsObjectString)));
      }));

  }
}
