<!-------------------------------- Content Wrapper Starts ---------------------------------->
<div id="content-wrapper">
  <div id="about-page">
    <section class="about-section">
      <div class="custom-container">
        <div class="section-title type-full-width type-center">
          <h5>Know More</h5>
          <h1>ABOUT US</h1>
          <div class="content" [innerHTML]="aboutUs.content">
          </div>
        </div>
        <div class="content-container">
          <h3>To be an institution that offers comprehensive quality healthcare services under one roof through transparent patient centric care.</h3>
        </div>
        <div class="content-container">
          <h3 style="color:#3DDB9B;">WE CARE - Dedicated to Well-being</h3>
          <p>&nbsp;</p>
          <p>We care is set to transform the overall wellness of you and your loved ones with its state-of-the-art facility and expertise like no other in the country.</p>
          <p>&nbsp;</p>
          <p>We uniquely aim to create a culture of continuous quality care by engaging our team consisting internationally reputed doctors, nurses, therapist and other staffs in partnership with the patient and their family members in safe environment.</p>
          <p>At the core of we care lies our patient whom we aim to provide overall care of highest standard with outmost convenient.</p>
          <p>We care being a multi-specialty healthcare provider houses many diverse facilities such as: highly equipped pathology lab, expert consultation, therapies, all dental solutions, USG, X-Ray, pharmacy, wellness packages and health at home (Home sample collection, Physiotherapy) in one central location.</p>
          <p>We care health center located in Bhagwatibahal, Naxal, Kathmandu, Nepal.&nbsp; We are the only super specialty dental clinic in the country, which offers complete digital solution to all your dental problems through our in-house CBCT, CAD/CAM, 3D printer and intra oral scanner.</p>
          <p>As our motto says it&rsquo;s your health that truly matters; we stand committed to uphold the ethos and values of patient care while forging everlasting bond with our patients.</p>
        </div>
      </div>
    </section>
  </div>
</div>
<!--------------------------------- Content Wrapper Ends ----------------------------------->
