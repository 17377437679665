import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './modules/general/home/home.component';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { WebsiteService } from './modules/website/websiteservice/website.service';
import { WebsiteEndpoint } from './modules/website/websiteservice/website-endpoint.service';
import { ScriptService } from './modules/website/scriptservice/script.service';
import { HeaderComponent } from './modules/website/others/header.component';
import { DanpheFooterComponent } from './modules/website/others/footer.component';
import { HomePageComponent } from './modules/website/home/homepage.component';
import { AboutUsPageComponent } from './modules/website/aboutus/aboutus.component';
import { BlogPageComponent } from './modules/website/blog/blog.component';
import { BlogDetailsPageComponent } from './modules/website/blog/blogdetails.component';
import { ContactPageComponent } from './modules/website/contact/contact.component';
import { ExpatPageComponent } from './modules/website/expat/expat.component';
import { NewsPageComponent } from './modules/website/news/news.component';
import { ProfilePageComponent } from './modules/website/profile/profile.component';
import { ServicesPageComponent } from './modules/website/services/services.component';
import { ServiceDetailsPageComponent } from './modules/website/services/servicedetails.component';
import { TeamPageComponent } from './modules/website/team/team.component';
import { NewsDetailsPageComponent } from './modules/website/news/newsdetails.component';
import { SpecialistPageComponent } from './modules/website/profile/specialist.component';
import { DepartmentDetailsPageComponent } from './modules/website/department/departmentdetails-page.component';
import { ArticlesPageComponent } from './modules/website/profile/articles-page.component';
import { CoreTeamProfileComponent } from './modules/website/team/core-team-profile.component';
import { DepartmentDetailsListComponent } from './modules/website/department/departmentdetails-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NotificationService } from './modules/website/services/notification.service';
import { NotificationEndpoint } from './modules/website/services/notification-endpoint.service';
import { ToastaModule } from 'ngx-toasta';
import { MetaModule } from '@ngx-meta/core';
import { DanphecareService } from './modules/website/services/danphe.service';
import { DanphecareEndpoint } from './modules/website/services/danphecare-endpoint.service';
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HamroPatroComponent } from './modules/website/hamropatra/hamropatra.component';
import { SwasthpalikaComponent } from './modules/website/swasthpalika/swasthpalika.component';
import { DanpheEHRComponent } from './modules/website/danphehr/danpheehr.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PackageComponent } from './modules/website/package/package.component';
import { SubServicesDetailsPageComponent } from './modules/website/subservices/sub-services.component';
import { CheckoutComponent } from './modules/website/checkout/checkout.component';
import { TeleMedService } from './modules/website/websiteservice/teleMedService';
import { TeleMedEndpoint } from './modules/website/websiteservice/teleMed-endpoint.service';
import { SingleProductComponent } from './modules/website/single-product/single-product.component';
import { CareerListComponent } from './modules/website/career/career-list.component';
import { HealthAtHomeComponent } from './modules/website/health-at-home/health-at-home.component';
import { PopupComponent } from './modules/website/others/popup/popup.component';
import { PhotoGalleryComponent } from './modules/website/gallery/photo-gallery.component';
import { MessageFromCeoComponent } from './modules/website/message-from-ceo/message-from-ceo.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { VideoGalleryComponent } from './modules/website/gallery/video/video-gallery.component';
import { LoadingServiceService } from './modules/website/websiteservice/loadingService/loading-service.service';
import { LoadingComponent } from './modules/website/loading/loading.component';
import { BookNowComponent } from './modules/website/buy-package/book-now.component';
import { HomeSampleCollectionFormComponent } from './modules/website/home-sample-collection-form/home-sample-collection-form.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    HeaderComponent,
    DanpheFooterComponent,
    HomePageComponent,
    AboutUsPageComponent,
    BlogPageComponent,
    BlogDetailsPageComponent,
    ContactPageComponent,
    ExpatPageComponent,
    NewsPageComponent,
    ProfilePageComponent,
    ServicesPageComponent,
    ServiceDetailsPageComponent,
    TeamPageComponent,
    NewsDetailsPageComponent,
    SpecialistPageComponent,
    ProfilePageComponent,
    DepartmentDetailsPageComponent,
    ArticlesPageComponent,
    CoreTeamProfileComponent,
    DepartmentDetailsListComponent,
    HamroPatroComponent,
    SwasthpalikaComponent,
    DanpheEHRComponent,
    PackageComponent,
    SubServicesDetailsPageComponent,
    CheckoutComponent,
    SingleProductComponent,
    CareerListComponent,
    HealthAtHomeComponent,
    PopupComponent,
    PhotoGalleryComponent,
    VideoGalleryComponent,
    MessageFromCeoComponent,
    LoadingComponent,
    BookNowComponent,
    HomeSampleCollectionFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    ToastaModule.forRoot(),
    MetaModule.forRoot(),
    SlickCarouselModule,
    YouTubePlayerModule
  ],
  providers: [WebsiteService, WebsiteEndpoint, ScriptService, NotificationService,
    NotificationEndpoint, DanphecareEndpoint, DanphecareService, DanphecareEndpoint, TeleMedService, TeleMedEndpoint, LoadingServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
