<header id="header-wrapper" [ngClass]="navOpen ? 'nav-open' : '' ">
  <div class="top-header">
    <div class="custom-container remove-top-bottom-padding">
      <ul class="tag-line">
        <li><a>Dedicated to Wellbeing</a></li>
      </ul>
      <ul>
      </ul>
      <ul>
        <li><a href="{{telemedUrl}}" target="_blank">Telemedicine</a></li>
        <li><a href="{{telemedUrl}}/#/labreportupload" target="_blank">Reports</a></li>
        <li><a [routerLink]="['/HealthAtHome']">Health at home</a>
      </ul>
    </div>
  </div>
  <div class="bottom-header">
    <div class="custom-container remove-top-bottom-padding">
      <div class="block-container">
        <div class="logo-container">
          <a [routerLink]="[ '/' ]"><img src="../../../../assets/img/logo_final.png" alt="" width="140" height="123" class="ccm-image-block img-responsive bID-250"></a>
        </div>
        <div class="nav-container">
          <div class="nav-item">
            <ul>
              <li class="mobile-menu"><a routerLink="/ceomessage">Founders Desk</a></li>

              <li id="nav-link-about-us" class="has-sub-menu">
                <a [routerLink]="[ '/aboutpage' ]" class="">
                  About Us
                </a>
                <ul>
                  <li style="width:  100%;">
                    <a routerLink="/ceomessage">
                      Founders Desk
                    </a>
                  </li>
                  <li style="width:  100%;">
                    <a routerLink="/carrier">
                      Career
                    </a>
                  </li>
                </ul>
              </li>
              <li class=" has-sub-menu ">
                <a [routerLink]="[ '/', 'servicepage' ]" class="">Services</a>
                <ul *ngIf="isservice2 == false">
                  <li>
                    <a *ngFor="let data of service1" [routerLink]="[ '/service', data.permaLink ]">{{data.serviceName}}
                    </a>
                  </li>
                </ul>
                <ul *ngIf="isservice2 == true" style="display: flex;
                          flex-direction: row;
                          align-items: start;">
                  <li>
                    <a *ngFor="let data of service1" [routerLink]="[ '/service', data.permaLink ]">{{data.serviceName}}
                    </a>
                  </li>
                  <li>
                    <a *ngFor="let data of service2" [routerLink]="[ '/service', data.permaLink ]">{{data.serviceName}}
                    </a>
                  </li>
                  <li>
                    <a *ngFor="let data of service3" [routerLink]="[ '/service', data.permaLink ]">{{data.serviceName}}
                    </a>
                  </li>
                </ul>
              </li>
              <li class="">
                <a [routerLink]="[ '/', 'package']" class="">
                  Packages
                </a>
              </li>
              <li class="">
                <a [routerLink]="[ '/', 'specialist' ]" class="">
                  Find A Doctor
                </a>
              </li>
              <li class="mobile-menu">
                <a href="{{telemedUrl}}" target="_blank" class="">
                  Telemedicine
                </a>
              </li>
              <li class="mobile-menu">
                <a href="{{telemedUrl}}/#/labreportupload" target="_blank" class="">
                  Reports
                </a>
              </li>

              <li class="mobile-menu"><a routerLink="/carrier"> Career</a></li>
              <li id="nav-link-gallery" class="has-sub-menu ">
                <a [routerLink]="[ '/gallery' ]" class="">
                  Gallery
                </a>
                <ul>
                  <li style="width:  100%;">
                    <a routerLink="/gallery/photo">
                      Photo
                    </a>
                  </li>
                  <li style="width:  100%;">
                    <a routerLink="/gallery/video">
                      Video
                    </a>
                  </li>
                </ul>
              </li>
              <li class="mobile-menu"><a routerLink="/gallery/video"> Videos</a></li>
              <li class="">
                <a [routerLink]="[ '/', 'contactpage' ]" class="">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="toggle" (click)="clickEvent()">
          <div class="image-container">
            <img src="../../../../assets/img/menu.svg" alt="menu">
          </div>
        </div>
      </div>
    </div>
  </div>
</header>