<div id="content-wrapper" class="mt-0">

  <div id="doctors-page">

    <section class="doctors-section">
      <div class="custom-container">
        <div class="section-title main-title">
          <h5>Meet our Doctors</h5>
          <h1>KNOW ABOUT<br />
            YOUR DOCTORS</h1>
        </div>
        <div class="section-content">
          <div class="block-container">
            <div class="block-item" *ngFor="let data of specialistList">
              <div class="common-doctor-container">
                <div class="inner">
                  <!--<a data-toggle="modal" data-target="#doctorModal">-->
                  <a [routerLink]="['/profilepage', data.permaLink]">
                    <div class="image-container">
                      <img [src]="data.imagePath" 
                        alt="{{data.fullName}}" width="326" height="316">
                    </div>
                    <div class="background-overlay">
                    </div>
                    <div class="link-text">
                      Know More
                    </div>
                  </a>
                </div>
                <div class="content-container">
                  <h5>
                    {{data.fullName}} </h5>
                  <p>
                    {{data.designation}} </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>





</div>