<section class="main-section cart-listing cart  billing-detail-page">
  <div class="custom-container pt-5 pb-5">
    <div class="row gutter-lg mb-10">
      <div class="col-lg-8 pr-lg-4 mb-6">
        <div class="billing-form">
          <form action="">
            <form>
              <div class="dual-form-group">
                <div class="form-group">
                  <label for="exampleInputEmail1">First Name <span class="required">*</span> </label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter First Name">
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Last Name <span class="required">*</span> </label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter Last Name">
                </div>
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Company Name (Opetional) </label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Enter email">
              </div>
              <div class="dual-form-group">
                <div class="form-group">
                  <label for="exampleInputEmail1">Country <span class="required">*</span> </label>
                  <select class="form-control" id="exampleFormControlSelect1">
                    <option>Nepal</option>
                    <option>USA</option>
                    <option>India</option>
                    <option>UK</option>
                    <option>Mynmar</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Provience <span class="required">*</span> </label>
                  <select class="form-control" id="exampleFormControlSelect1">
                    <option>Provience No 1</option>
                    <option>Provience No 2</option>
                    <option>Provience No 3</option>
                    <option>Provience No 4</option>
                    <option>Provience No 5</option>
                    <option>Provience No 6</option>
                    <option>Provience No 7</option>
                  </select>
                </div>

              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Address <span class="required">*</span> </label>
                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Enter your Address">
              </div>

              <div class="dual-form-group">
                <div class="form-group">
                  <label for="exampleInputEmail1">Town/City <span class="required">*</span> </label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Newroad Pokhara">
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Phone <span class="required">*</span> </label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="9865322145">
                </div>
              </div>

              <div class="form-group">
                <label for="exampleInputEmail1">Email Address <span class="required">*</span> </label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="example@gmail.com">
              </div>

              <div class="form-group">
                <label for="exampleFormControlTextarea1">Order Notes (Optional)</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="4"
                  placeholder="Write your notes Here"></textarea>
              </div>

            </form>
          </form>
        </div>


      </div>
      <div class="col-lg-4 mb-4 sticky-sidebar-wrapper">
        <div class="order-summary-wrapper sticky-sidebar">
          <div class="order-summary clearfix">
            <h3 class="title text-uppercase ls-10 pt-0">Your Order</h3>

            <div>
              <div class="ordered-products">
                <h3 class="title text-uppercase ls-10">Products</h3>
                <ul>
                  <li><span>Pulse Oxymeter</span> * <span>1</span> <span class="float-right">Rs.7500/-</span></li>
                  <li><span>Digital Pulse Oxymeter</span> * <span>1</span> <span
                      class="float-right">Rs.7500/-</span></li>
                  <li><span>Covid Care</span> * <span>1</span> <span class="float-right">Rs.7500/-</span></li>
                </ul>
              </div>
              <h3 class="title text-uppercase ls-10">Subotal <span class="float-right">Rs.7500/-</span></h3>
              <h3 class="title text-uppercase ls-10">Total <span class="float-right">Rs.7500/-</span></h3>
            </div>

            <div class="payment-methods" id="payment_method">
              <h4 class="title font-weight-bold ls-25 pb-0 mb-1">Payment Methods</h4>
              <ul class="payment-card">
                <li>
                  <div class="custom-radio">
                    <input type="radio" id="esewa" class="custom-control-input" name="payment">
                    <label for="esewa" class="custom-control-label color-dark"><img src="img/esewa.png"
                        alt="esewa" /></label>
                  </div>
                </li>
                <li>
                  <div class="custom-radio">
                    <input type="radio" id="khalti" class="custom-control-input" name="payment">
                    <label for="khalti" class="custom-control-label color-dark"><img src="img/khalti.png"
                        alt="esewa" /></label>
                  </div>
                </li>
                <li>
                  <div class="custom-radio">
                    <input type="radio" id="connectIPS" class="custom-control-input" name="payment">
                    <label for="connectIPS" class="custom-control-label color-dark"><img src="img/connectIPS.png"
                        alt="esewa" /></label>
                  </div>
                </li>
              </ul>
              <h4 class="title font-weight-bold ls-25 pb-0 mb-1">We also Accept</h4>
              <ul class="payment-bank-card">
                <li>
                  <div class="custom-radio">
                    <input type="radio" id="visa-card" class="custom-control-input" name="bank-card">
                    <label for="visa-card" class="custom-control-label color-dark"><img src="img/visa.png"
                        alt="visa" /></label>
                  </div>
                </li>
                <li>
                  <div class="custom-radio">
                    <input type="radio" id="master-card" class="custom-control-input" name="bank-card">
                    <label for="master-card" class="custom-control-label color-dark"><img
                        src="img/master-card.png" alt="master" /></label>
                  </div>
                </li>
                <li>
                  <div class="custom-radio">
                    <input type="radio" id="union-pay" class="custom-control-input" name="bank-card">
                    <label for="union-pay" class="custom-control-label color-dark"><img
                        src="img/UnionPay_logo.png" alt="union" /></label>
                  </div>
                </li>
                <li>
                  <div class="custom-radio">
                    <input type="radio" id="nabil" class="custom-control-input" name="bank-card">
                    <label for="nabil" class="custom-control-label color-dark"><img src="img/nabil-bank.png"
                        alt="union" /></label>
                  </div>
                </li>
              </ul>
            </div>
            <div class="form-group place-order pt-6">
              <a routerLink="['billing-final']" target="_blank" type="" class="btn btn-primary btn-block btn-rounded">Proceed to Checkout</a>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>