import { Component } from "@angular/core";

@Component({
  selector: 'video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.css']
})
export class VideoGalleryComponent {

}
