<style>
  .detail-section {
    background-color: #fff;
  }
  .service-content-item p {
    margin-bottom: 1rem !important;
    font-size: 14px !important;
  }
  .section-heading {
    margin: 0;
    width: 100%;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .faq-block .nav-tabs li a:hover, .faq-block .nav-tabs li a:focus {
    border-top: 3px solid #608e75 !important;
    background-color: #fff !important;
    color: #444 !important;
    border-radius: 0;
  }
  .faq-block .nav-tabs {
    border-radius: 0;
  }
  #hero {
    height: auto;
  }
  section.innitiation .card-title {
    font-weight: 700;
    font-size: 1.25rem;
}
</style>
<div class="container-fluid department-container">
  <div class="row">
    <div class="col-md-3 pl-0 d-none d-lg-block d-lg-block d-xl-block">
      <div class="left-menu">
        <div class="nav-top">
          <!--<div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <a class="nav-link" href=""><span class="icon-span"><i class="fa fa-home"></i></span>Home</a>
          <a class="nav-link" href=""><span class="icon-span"><img src="assets/img/video-call.svg"></span>Telemedicine</a>
          <a class="nav-link" href=""><span class="icon-span"><i class="fa fa-user-md"></i></span>Our Specialist</a>
          <a class="nav-link" href=""><span class="icon-span"><i class="fa fa-home"></i></span>Home Isolation</a> </div>-->
          <div class="promo-card-black mt-5">
            <h1 class="section-title"> <i class="fa fa-calendar"></i>&nbsp;&nbsp;Book your specialist </h1>
            <div class="promo-card" style="margin-bottom: 10px;" *ngFor="let data of doctorList">
              <div class="r mr-3"> <img [src]="data.imagePath" class="img-round" (click)="GoToProfile(data.permaLink)"> </div>
              <div class="l">
                <h4>{{data.fullName}}</h4>
                <p>{{data.designation}}</p>
                <p>{{data.departmentName}}</p>
                <a href="" class="btn btn-primary" data-toggle="modal" data-target="#contactModal"  >Book Now</a>

              </div>
              <hr />
            </div>
          </div>
          <!--<div class="promo-card-black mt-3">
            <div class="promo-card">
              <div class="r mr-3"> <img src="assets/img/team/team-1.jpg" class="img-round"> </div>
              <div class="l">
                <h4>Dr. Pravat Adhikari</h4>
                <p>Infecticious Disease & Control</p>
                <a href="" class="btn btn-primary btn-small" data-toggle="modal" data-target="#contactModal">Book Now</a>
              </div>
            </div>
          </div>-->
          <!--<div class="ads-banner"> <a href="https://danphecare.com/" target="_black"> <img src="../../../assets/images/initiation1.png" class="img-fluid"> </a> </div>-->
          <!--<div class="promo-card-black mt-3">
            <div class="promo-card d-block">
              <h1 class="section-title"> <i class="fa fa-calendar"></i>&nbsp;&nbsp;Upcoming Events </h1>
              <div class="l">
                <h6 class="d-block mt-3" style="font-weight: 500;" >1. Antibiotics Resistance</h6>
                <h6 class="d-block mt-3" style="font-weight: 500;">2. Corporate Safety Guideline</h6>
                <h6 class="d-block mt-3" style="font-weight: 500;">3. Covid safety during winter</h6>
              </div>
              <span class="d-block small mt-5" style="font-size: .875em; margin-top: 3rem!important;display: block!important;">for more information</span> <a href="" class="btn btn-sm btn-primary d-block mt-2" data-toggle="modal" data-target="#contactModal">Contact us</a>
            </div>
          </div>-->
          <!-- Contact Modal -->

          <div class="services mt-4">
            <div class="icon-box ask w-100" data-aos-delay="300" style="box-shadow: none;">
              <h4 class="title">
                Talk with our specialist
                <p class="small">
                  Talk with our Specialist before booking
                  You can describe the issue without signup. Our representive will call you and make appointment if needed
                </p>
              </h4>
              <form [formGroup]="reachForm3" (ngSubmit)="onSubmitReachUsQuickly1()" class="ask-form mt-3">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="text" formControlName="name3" id="name3" placeholder="Enter your name *" [(ngModel)]="DhcareRuq2.name"
                             class="form-control" [ngClass]="{ 'is-invalid': submitForm3 && nameControl3.errors }" />
                      <div *ngIf="nameControl3.invalid && (nameControl3.dirty || nameControl3.touched || (nameControl3.untouched && submitForm3) )" class="alert-sm alert-danger">
                        <div *ngIf="nameControl3.errors.required">Name is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <input type="text" formControlName="email3" id="email3" placeholder="E-mail address *" [(ngModel)]="DhcareRuq2.email"
                             class="form-control" [ngClass]="{ 'is-invalid': submitForm3 && EmailControl3.errors }" />
                      <div *ngIf="EmailControl3.invalid && (EmailControl3.dirty || EmailControl3.touched || (EmailControl3.untouched && submitForm3))" class="alert-sm alert-danger">
                        <div *ngIf="EmailControl3.errors.required">Email is required</div>
                        <div *ngIf="EmailControl3.errors.pattern">Enter valid email address</div>
                      </div>
                    </div>
                    <div class="form-group">

                      <input type="text" formControlName="phonenumber3" id="phonenumber3" placeholder="Phone Number *" [(ngModel)]="DhcareRuq2.phone"  (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                             class="form-control" [ngClass]="{ 'is-invalid': submitForm3 && PhoneNumberControl3.errors }" />
                      <div *ngIf="PhoneNumberControl3.invalid && (PhoneNumberControl3.dirty || (PhoneNumberControl3.untouched && submitForm3))" class="alert-sm alert-danger">
                        <div *ngIf="PhoneNumberControl3.errors.required">Phone Number is required</div>
      
                      </div>

                    </div>
                    <div class="form-group mt-5">
                      <button class="btn-get-started btn-small w-100" type="submit">
                        <span *ngIf="loading3" class="spinner-border" role="status">Sending ...</span>
                        <span *ngIf="!loading3">Send Message</span>
                      </button>
                    </div>
                    <!--<div class="form-group mt-5">
        <input type="submit" name="btnSubmit" class="btn-get-started btn-small w-100" value="Send Message" />
      </div>-->
                  </div>
                </div>
              </form>
              <div class="promo-card-black">
                <div class="promo-card left p-0">
                  <div class="r">
                    <i class="fa fa-volume-control-phone" style="font-size: 27px;
                margin-right: 28px;"></i>
                  </div>
                  <div class="l">
                    <p class="mb-1">Give us a call</p>
                    <a class="cta-btn align-middle" href="tel:{{contact.phone}}">
                      <h4>{{contact.phone}}</h4>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9 col-xs-12">
      <!--<section id="hero" class="d-flex align-items-center">
          <div class="row">
            <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>Gynocologic Services</h1>
              <h2 data-aos-delay="400" class="font-weight-normal">Our dedicated team of doctors, nurses and other health professionals provide compassionate care with a personal touch. </h2>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 hero-img"> <img src="../../../assets/images/gyno-img.png" class="img-fluid" alt=""> </div>
          </div>
      </section>-->
      <section id="hero" class="d-flex align-items-center pt-5">
        <div class="container">
           <div class="row">
            <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1 *ngIf="department != null">{{department.title}}</h1>
              <span data-aos-delay="400" class="font-weight-normal" *ngIf="department != null"  [innerHTML]="department.introduction">
              </span>
              <!--<nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page" *ngIf="departmentService!= null">{{departmentService.serviceName}}</li>
                </ol>
              </nav>-->
            </div>
            <div class="col-lg-6 order-1 order-lg-2 hero-img" *ngIf="department != null"> <img [src]="department.imagePath" class="img-fluid" alt=""> </div>
          </div>
        </div>
      </section>

      <!-- End Hero -->

    <main id="main">

      <section class="specialist-section" *ngIf="IsSubHeading">
        <div class="section-heading">
          <h1 class="section-title w-100 text-left font-weight-bold">
            <p class="d-block font-weight-lighter w-75"></p>
          </h1>
        </div>
        <div class="carousel slide multi-item-carousel" id="theCarousel">
          <div class="carousel-inner row w-100 mx-auto" >
            <div class="carousel-items col-md-3 col-lg-3"  *ngFor="let department of subheadingList" style="">
              <div class="special-item text-center" style="height:95%">
                <div class="icon"><img [src]="department.iconPath" alt="logo" style="width:80%" /> </div>
                <h3>{{department.title}}</h3>
                <span [innerHTML]="department.shortDescription"></span>
                <!--<a (click)="DepartmentDetails(department.departmentSubHeadingId)" class="links">Consult Now <i class="fa fa-angle-right"></i></a>-->
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#theCarousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#theCarousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </section>

      <!--<section class="detail-section">
        <div class="row">
          <div class="col-md-12">
            <div class="section-heading">
              <h1 class="section-title w-100 text-left font-weight-bold">
                Things that we care
               
              </h1>
            </div>
          </div>
          <div class="row" *ngIf="servicesList!= null">
            <div class="col-md-4" *ngFor="let data of servicesList">
              <div class="service-content-item" (click)="GotToService(data.serviceId)">
                <div class="item-title">
                  <div class="service-content-icon"> <img [src]="data.iconPath" alt=""> </div>
                  <h3><a (click)="GotToService(data.serviceId)">{{data.serviceName}}</a></h3>
                </div>
                <p [innerHTML]="data.content" style="margin-bottom: 0;"></p>
              </div>
            </div>

          </div>

        </div>
        <div class="row">
          <div class="col-md-4"> </div>
          <div class="bx-widget-content">
           
            <button class="btn btn-info" [routerLink]="[ '/', 'servicepage' ]" style="text-decoration:underline">View All</button>
          </div>

          <div class="col-md-4"></div>
        </div>
      </section>-->
      <section class="specialist-section">
        <div class="section-heading">
          <h1 class="section-title w-100 text-center font-weight-bold">
            25 + Specialities
            <p class="d-block font-weight-lighter text-center w-100"> Consult with top doctors across specialitiesss</p>
          </h1>
        </div>
        <div class="carousel slide multi-item-carousel" id="theCarousel">
          <div class="carousel-inner row w-100 mx-auto" style="padding:10px">
            <div class="carousel-items col-md-3" *ngFor="let department of departmentList">
              <div class="special-item text-center" (click)="DepartmentDetails(department.permaLink)" style="height:95%">
                <div class="icon"><img [src]="department.iconPath" alt="logo" style="width:80%" /> </div>
                <h3>{{department.departmentName}}</h3>
                <a (click)="DepartmentDetails(department.permaLink)" class="links">Consult Now <i class="fa fa-angle-right"></i></a>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#theCarousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#theCarousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div class="row">
          <div class="col-md-4"> </div>
          <div class="bx-widget-content">
           
            <button class="btn btn-more" [routerLink]="[ '/', 'departmentdetailslist' ]">View All</button>
          </div>

          <div class="col-md-4"></div>
        </div>

      </section>
    </main>
        <!-- ======= Services Section ======= -->
        <section id="services" class="services">

          <div class="row" >

            <div class="col-md-12 col-lg-12 d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="faq-block" *ngIf="deptFaq == true">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation"> <a class="nav-link active" id="faq-tab" data-toggle="tab" href="#faq" role="tab" aria-controls="faq" aria-selected="true">FAQ's</a> </li>
                  <!--<li class="nav-item" role="presentation"> <a class="nav-link" id="component-tab" data-toggle="tab" href="#component" role="tab" aria-controls="component" aria-selected="false">Components</a> </li>
                  <li class="nav-item" role="presentation"> <a class="nav-link" id="shock-setup-tab" data-toggle="tab" href="#shock-setup" role="tab" aria-controls="shock-setup" aria-selected="false">Shock Setup</a> </li>
                  <li class="nav-item" role="presentation"> <a class="nav-link" id="warranty-tab" data-toggle="tab" href="#warranty" role="tab" aria-controls="warranty" aria-selected="false">Warranty & Registration</a> </li>-->
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                    <div class="accordion" id="accordionExample">
                      <div class="card" *ngFor="let data of departmentFaq">
                        <div class="card-header" id="headingOne">
                          <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> {{data.title}}</button>
                          </h2>
                        </div>
                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                          <div class="card-body" [innerHTML]="data.content"></div>
                        </div>
                      </div>
                      <!--<div class="card">
                        <div class="card-header" id="headingTwo">
                          <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Collapsible Group Item #2 ?</button>
                          </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                          <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" id="headingThree">
                          <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Collapsible Group Item #3 ?</button>
                          </h2>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                          <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                        </div>
                      </div>-->
                    </div>
                  </div>
                  <div class="tab-pane fade" id="component" role="tabpanel" aria-labelledby="component-tab">
                    <div class="accordion" id="accordionExample1">
                      <div class="card" *ngFor="let data of departmentComponents">
                        <div class="card-header" id="headingOne1">
                          <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1"> {{data.title}}</button>
                          </h2>
                        </div>
                        <div id="collapseOne1" class="collapse show" aria-labelledby="headingOne1" data-parent="#accordionExample1">
                          <div class="card-body" [innerHTML]="data.content">  </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                  <div class="tab-pane fade" id="shock-setup" role="tabpanel" aria-labelledby="shock-setups-tab">
                    <div class="accordion" id="accordionExample2">
                      <div class="card" *ngFor="let data of departmentSetup" >
                        <div class="card-header" id="headingOne2">
                          <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2"> {{data.title}}</button>
                          </h2>
                        </div>
                        <div id="collapseOne2" class="collapse show" aria-labelledby="headingOne2" data-parent="#accordionExample2">
                          <div class="card-body" [innerHTML]="data.content">  </div>
                        </div>
                      </div>
                      <!--<div class="card">
                        <div class="card-header" id="headingTwo2">
                          <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2"> Collapsible Group Item #8 ?</button>
                          </h2>
                        </div>
                        <div id="collapseTwo2" class="collapse" aria-labelledby="headingTwo2" data-parent="#accordionExample2">
                          <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" id="headingThree2">
                          <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree2" aria-expanded="false" aria-controls="collapseThree2"> Collapsible Group Item #9 ?</button>
                          </h2>
                        </div>
                        <div id="collapseThree2" class="collapse" aria-labelledby="headingThree2" data-parent="#accordionExample2">
                          <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                        </div>
                      </div>-->
                    </div>
                  </div>
                  <div class="tab-pane fade" id="warranty" role="tabpanel" aria-labelledby="warranty-tab">
                    <div class="accordion" id="accordionExample3">
                      <div class="card" *ngFor="let data of departmentWarranty">
                        <div class="card-header" id="headingOne3">
                          <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne3" aria-expanded="true" aria-controls="collapseOne3"> {{data.title}}</button>
                          </h2>
                        </div>
                        <div id="collapseOne3" class="collapse show" aria-labelledby="headingOne3" data-parent="#accordionExample3">
                          <div class="card-body" [innerHTML]="data.content"> </div>
                        </div>
                      </div>
                      <!--<div class="card">
                        <div class="card-header" id="headingTwo3">
                          <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3"> Collapsible Group Item #11 ?</button>
                          </h2>
                        </div>
                        <div id="collapseTwo3" class="collapse" aria-labelledby="headingTwo3" data-parent="#accordionExample3">
                          <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" id="headingThree3">
                          <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree3" aria-expanded="false" aria-controls="collapseThree3"> Collapsible Group Item #12 ?</button>
                          </h2>
                        </div>
                        <div id="collapseThree3" class="collapse" aria-labelledby="headingThree3" data-parent="#accordionExample3">
                          <div class="card-body"> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS. </div>
                        </div>
                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
        <!-- End Services Section -->
        <!--<section id="pricing" class="pricing">

          <div class="section-title">
            <h2>Our Pricing Modality</h2>
            <p>Sit sint consectetur velit nemo qui impedit suscipit alias ea</p>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="box aos-init aos-animate" data-aos="zoom-in-right" data-aos-delay="200">
                <h3>Free</h3>
                <h4><sup>$</sup>0<span> / month</span></h4>
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li class="na">Pharetra massa</li>
                  <li class="na">Massa ultricies mi</li>
                </ul>
                <div class="btn-wrap"> <a href="#" class="btn-buy">Buy Now</a> </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
              <div class="box recommended aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                <h3>Business</h3>
                <h4><sup>$</sup>19<span> / month</span></h4>
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li>Pharetra massa</li>
                  <li class="na">Massa ultricies mi</li>
                </ul>
                <div class="btn-wrap"> <a href="#" class="btn-buy">Buy Now</a> </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
              <div class="box aos-init aos-animate" data-aos="zoom-in-left" data-aos-delay="200">
                <h3>Developer</h3>
                <h4><sup>$</sup>29<span> / month</span></h4>
                <ul>
                  <li>Aida dere</li>
                  <li>Nec feugiat nisl</li>
                  <li>Nulla at volutpat dola</li>
                  <li>Pharetra massa</li>
                  <li>Massa ultricies mi</li>
                </ul>
                <div class="btn-wrap"> <a href="#" class="btn-buy">Buy Now</a> </div>
              </div>
            </div>
          </div>

        </section>-->
        <section>
          <div class="section-title mb-0 pb-0">
            <h2>Resourceful articles </h2>
           
          </div>
          <section class="blog-wrapper pt-5 pb-5">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let data of blogsList">
                  <div class="blog-post" (click)="GoToBlogDetails(data.permaLink)">
                    <div class="blog-img" (click)="GoToBlogDetails(data.permaLink)"> <img [src]="data.imagePath" alt=""> </div>
                    <div class="blog-content">
                      <h4><a routerLink="blog" (click)="GoToBlogDetails(data.permaLink)">{{data.title}}</a></h4>
                      <div class="post">
                        <ul>
                          <li><a href="#"><svg class="svg-inline--fa fa-clock fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg><!-- <i class="far fa-clock"></i> -->{{data.createdDate | date:'mediumDate'}}</a></li>

                          <li><a href="#"><svg class="svg-inline--fa fa-user fa-w-14" aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"></path></svg><!-- <i class="far fa-user"></i> -->Posted by {{data.postedBy}}</a></li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!--<div class="row">
    <div class="col-md-3" *ngFor="let data of articlesList">
      <div class="section-title small stats aos-init aos-animate" data-aos="fade-up" data-aos-delay="100" (click)="GoToArticlesPage(data.resourcefulArticlesId)">
        <div class="stat-img">
          <img [src]="data.imagePath">
          <div class="stat-value">
            <h1>{{data.title}}</h1>
            <p>- {{data.doctorName}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>-->
        </section>
        <!-- ======= More Services Section ======= -->
        <!-- End More Services Section -->
        <!-- End Features Section -->
        <!-- Section Innitiations -->
        <section class="innitiation">
          <div class="container">
            <div class="row">
              <div class="section-title w-100 mb-0 pb-0 mb-5">
                <h2 class="">Our Initiation</h2>
                <p style="text-align:center">We have been organizing webinar and seminars to spread knowlegde regarding COVID Nationally</p>
              </div>
              <div class="row">
                <div class="col-md-4 d-flex align-items-stretch">
                  <div class="card" data-aos-delay="100">
                    <div class="card-body">
                      <img src="../../../assets/images/home-isolationy.png">
                      <h5 class="card-title"><a href="">Home Isolation Care</a></h5>
                      <p class="card-text">Our Dedicated medical team provide Home Isolation Care for the mildly symptomic as well as asymptomatic COVID Patients</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 d-flex align-items-stretch mt-4 mt-md-0">
                  <div class="card" data-aos-delay="200">
                    <div class="card-body">
                      <img src="../../../assets/images/initiation2.png">
                      <h5 class="card-title"><a href="">Webinar Safety</a></h5>
                      <p class="card-text">We will provide each and everyone a safety guidelines</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 d-flex align-items-stretch mt-4 mt-md-0">
                  <div class="card" data-aos-delay="200">
                    <div class="card-body">
                      <img src="../../../assets/images/initiation3.png">
                      <h5 class="card-title"><a href="">Covid Learning Materials</a></h5>
                      <p class="card-text">We will provide Authentic resources to keep you safety and informed</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Section Innitiation END -->


    </div>
    <div class="col-md-3 pl-0 d-xs-block d-sm-block d-md-none d-lg-none">
      <div class="left-menu">
        <div class="nav-top">
          <!--<div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical"> <a class="nav-link" href=""><span class="icon-span"><i class="fa fa-home"></i></span>Home</a>
          <a class="nav-link" href=""><span class="icon-span"><img src="assets/img/video-call.svg"></span>Telemedicine</a>
          <a class="nav-link" href=""><span class="icon-span"><i class="fa fa-user-md"></i></span>Our Specialist</a>
          <a class="nav-link" href=""><span class="icon-span"><i class="fa fa-home"></i></span>Home Isolation</a> </div>-->
          <!--<div class="promo-card-black mt-5">
            <h1 class="section-title"> <i class="fa fa-calendar"></i>&nbsp;&nbsp;Book your specialist </h1>
            <div class="promo-card" *ngFor="let data of doctorList">
              <div class="r mr-3"> <img [src]="data.ImagePath" class="img-round"> </div>
              <div class="l">
                <h4>{{data.FullName}}</h4>
                <p>{{data.DepartmentName}}</p>
                <a href="" class="btn btn-primary btn-small" data-toggle="modal" data-target="#contactModal">Book Now</a>
              </div>
            </div>
          </div>-->
          <!--<div class="promo-card-black mt-3">
            <div class="promo-card">
              <div class="r mr-3"> <img src="assets/img/team/team-1.jpg" class="img-round"> </div>
              <div class="l">
                <h4>Dr. Pravat Adhikari</h4>
                <p>Infecticious Disease & Control</p>
                <a href="" class="btn btn-primary btn-small" data-toggle="modal" data-target="#contactModal">Book Now</a>
              </div>
            </div>
          </div>-->
          <!--<div class="ads-banner mt-4"> <a href="https://danphecare.com/" target="_black"> <img src="../../../assets/images/initiation1.png" class="img-fluid"> </a> </div>
          <div class="promo-card-black mt-3">
            <div class="promo-card d-block">
              <h1 class="section-title"> <i class="fa fa-calendar"></i>&nbsp;&nbsp;Upcoming Events </h1>
              <div class="l">
                <h6 class="d-block mt-3" style="font-size: 12px; font-weight: 500;">1. Antibiotics Resistance</h6>
                <h6 class="d-block mt-3" style="font-size: 12px; font-weight: 500;">2. Corporate Safety Guideline</h6>
                <h6 class="d-block mt-3" style="font-size: 12px; font-weight: 500;">3. Covid safety during winter</h6>
              </div>
              <span class="d-block small mt-5">for more information</span> <a href="" class="btn btn-sm btn-primary d-block mt-2" data-toggle="modal" data-target="#contactModal">Contact us</a>
            </div>
          </div>-->
          <!-- Contact Modal -->

          <!--<div class="services mt-4">
            <div class="icon-box ask w-100" data-aos-delay="300" style="box-shadow: none;">
              <h4 class="title">
                Talk with our specialist
                <p class="small">
                  Talk with our Specialist before booking
                  You can describe the issue without signup. Our representive will call you and make appointment if needed
                </p>
              </h4>
              <form  [formGroup]="reachForm1" (ngSubmit)="onSubmitReachUsQuickly()" class="ask-form mt-3">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="text" formControlName="name1" id="name1" placeholder="Enter your name *" [(ngModel)]="DhcareRuq.name"
                             class="form-control" [ngClass]="{ 'is-invalid': submitForm1 && NameControl1.errors }" />
                      <div *ngIf="NameControl1.invalid && (NameControl1.dirty || NameControl1.touched || (NameControl1.untouched && submitForm1))" class="alert-sm alert-danger">
                        <div *ngIf="NameControl1.errors.required">Name is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <input type="text" formControlName="email1" id="email1" placeholder="E-mail address *" [(ngModel)]="DhcareRuq.email"
                             class="form-control" [ngClass]="{ 'is-invalid': submitForm1 && EmailControl1.errors }" />
                      <div *ngIf="EmailControl1.invalid && (EmailControl1.dirty || EmailControl1.touched || (EmailControl1.untouched && submitForm1) )" class="alert-sm alert-danger">
                        <div *ngIf="EmailControl1.errors.required">Email is required</div>
                        <div *ngIf="EmailControl1.errors.pattern">Enter valid email address</div>
                      </div>
                    </div>
                    <div class="form-group">

                      <input type="text" formControlName="phonenumber1" id="phonenumber1" placeholder="Phone Number *" [(ngModel)]="DhcareRuq.phone"  (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                             class="form-control" [ngClass]="{ 'is-invalid': submitForm1 && PhoneNumberControl1.errors }" />
                      <div *ngIf="PhoneNumberControl1.invalid && (PhoneNumberControl1.dirty || PhoneNumberControl1.touched || (PhoneNumberControl1.untouched && submitForm1))" class="alert-sm alert-danger">
                        <div *ngIf="PhoneNumberControl1.errors.required">Phone Number is required</div>
                      </div>
                      
                    </div>
                    <div class="form-group mt-5">
                      <button class="btn-get-started btn-small w-100" type="submit">
                        <span *ngIf="loading1" class="spinner-border" role="status">Sending ...</span>
                        <span *ngIf="!loading1">Send Message</span>
                      </button>
                      </div>-->
                      <!--<div class="form-group mt-5">
                        <input type="submit" name="btnSubmit" class="btn-get-started btn-small w-100" value="Send Message" />
                      </div>-->
                    <!--</div>
                  </div>
              </form>
              <div class="promo-card-black">
                <div class="promo-card left p-0">
                  <div class="r">
                    <i class="fa fa-volume-control-phone" style="font-size: 27px;
              margin-right: 28px;"></i>
                  </div>
                  <div class="l">
                    <p class="mb-1">Give us a call</p>
                    <a class="cta-btn align-middle" href="tel:{{contact.phone}}">
                      <h4>{{contact.phone}}</h4>
                    </a>
                      
                  </div>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
<!--</main>-->
<!-- End #main -->
<!--<section class="contact-info-section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 text-center text-lg-left">
        <h3>Opens 24*7 Everyday</h3>
        <p>
          If you have any COVID related queries, please contact us. We will provide you with guidance and support to solve your health concern . For emergencies, we suggest you contact the nearest ambulance services or go to nearby hospital for immediate attention.

          If you are in quarantine or home isolation, our team of nurses, medical officers and specialists will provide physical, mental and social support to improve your well being and overcome your anxiety.
        </p>
      </div>
      <div class="col-lg-6 cta-btn-container text-center">
        <a class="cta-btn align-middle" href="#">
          Call us to know more<br>
          <span style="font-size: 20px; font-weight: 700;">{{contact.phone}}</span>
        </a>
        <div class="email-contact"> <span>OR</span> Email us @ <a href="">{{contact.email}}</a> </div>
      </div>
    </div>
  </div>
</section>-->
<!-- Modal -->
<div class="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel" aria-hidden="true" [style.display]="showModelBox ? 'block' : 'none'" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="contactModalLabel">Contact Us</h5>
        <a class="close" data-dismiss="modal" aria-label="Close" (click)="hide()" > <i class="fa fa-times" aria-hidden="true"></i> </a>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-body">
        <div class="icon-box ask w-100" data-aos-delay="300">
          <p class="small">If you want to know more about our services, want to book with one of our specialists or if you want to know about our upcoming events feel free to contact us. Our care coordinator will assist you & do the needful. </p>
          <form  [formGroup]="reachForm2" (ngSubmit)="onSubmitBook()" novalidate class="ask-form mt-3">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group"> </div>
                <div class="form-group">

                  <input type="text" formControlName="name2" id="name2" placeholder="Enter your name *" [(ngModel)]="DhcareRuq1.name"
                         class="form-control" [ngClass]="{ 'is-invalid': submitForm2 && NameControl2.errors }" />
                  <div *ngIf="NameControl2.invalid && (NameControl2.dirty || NameControl2.touched || (NameControl2.untouched && submitForm2))" class="alert-sm alert-danger">
                    <div *ngIf="NameControl2.errors.required">Name is required</div>
                  </div>
                  
                </div>
                <div class="form-group">
                  <input type="text" formControlName="phonenumber2" id="phonenumber2" placeholder="Phone Number *" [(ngModel)]="DhcareRuq1.phone"  (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                         class="form-control" [ngClass]="{ 'is-invalid': submitForm2 && PhoneNumberControl2.errors }" />
                  <div *ngIf="PhoneNumberControl2.invalid && (PhoneNumberControl2.dirty || PhoneNumberControl2.touched || (PhoneNumberControl2.untouched && submitForm2))" class="alert-sm alert-danger">
                    <div *ngIf="PhoneNumberControl2.errors.required">Phone Number is required</div>
                  </div>
                  
                </div>
                <div class="form-group">
                  <textarea class="form-control" name="message" placeholder="Your Message" [(ngModel)]="DhcareRuq1.message" [ngModelOptions]="{standalone: true}" #message="ngModel" required></textarea>
                </div>
                <div class="form-group mt-5">
                  <button class="btn-get-started btn-small w-100" type="submit">
                    <span *ngIf="loading2" class="spinner-border" role="status">Sending ...</span>
                    <span *ngIf="!loading2">Contact Us</span>
                  </button>
                  <!--<input type="submit" name="btnSubmit" class="btn-get-started btn-small w-100" value="Contact Us">-->
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

