<div class="contact-page">
    <div class="section-content">
        <div class="block-container">
            <div class="block-item">
                <div class="form-container">
                    <div class="content-container">
                        <div class="common-form">
                            <div id="formblock490" class="ccm-block-type-form">
                                <form [formGroup]="sampleCollectionForm" enctype="multipart/form-data" class="form-stacked miniSurveyView" id="miniSurveyView490">
                                    <div class="sample-collection-form">

                                        <div class="fields">


                                            <div data-placeholder="Full Name" class="form-group field field-text ">
                                                <input placeholder="Full Name*" class="form-control" formControlName="FullName" type="text" />
                                                <small class="form-invalid-input-text" *ngIf="isFullNameError">
                                                    {{fullNameErrorText}}
                                                </small>
                                            </div>

                                            <div data-placeholder="Age" class="form-group field field-text">
                                                <input placeholder="Age*" class="form-control" formControlName="Age" min="1" max="100" type="number" />
                                                <small class="form-invalid-input-text" *ngIf="isAgeError">
                                                    {{ageErrorText}}
                                                </small>
                                            </div>

                                            <div data-placeholder="Gender" class="form-group field field-text">
                                                <div class="d-flex align-items-center">
                                                    <input type="radio" class="w-auto mr-2" name="Gender" value="male" (change)="changeGender($event)"> <label class="mr-3 text-white">Male</label>
                                                    <!-- </div>
                                                    <div class="d-flex align-items-center"> -->
                                                    <input type="radio" class="w-auto mr-2" name="Gender" value="female" (change)="changeGender($event)"><label class="mr-3 text-white">Female</label>
                                                    <!-- </div>
                                                    <div class="d-flex align-items-center"> -->
                                                    <input type="radio" class="w-auto mr-2" name="Gender" value="other" (change)="changeGender($event)"> <label class="text-white">Other</label>
                                                </div>
                                                <small class="form-invalid-input-text" *ngIf="isGenderError">
                                                    {{genderErrorText}}
                                                </small>
                                            </div>

                                            <div data-placeholder="Address" class="form-group field field-text ">
                                                <input placeholder="Address*" class="form-control" formControlName="Address" type="text" />
                                                <small class="form-invalid-input-text" *ngIf="isAddressError">
                                                    {{addressErrorText}}
                                                </small>
                                            </div>

                                            <div data-placeholder="Phone No" class="form-group field field-text ">
                                                <input placeholder="Phone No*" class="form-control" formControlName="PhoneNumber" type="text" />
                                                <small class="form-invalid-input-text" *ngIf="isPhoneNumberError">
                                                    {{phoneNumberErrorText}}
                                                </small>
                                            </div>

                                            <div data-placeholder="Email" class="form-group field field-email ">
                                                <input placeholder="Email*" class="form-control" formControlName="Email" type="email" />
                                                <small class="form-invalid-input-text" *ngIf="isEmailError">
                                                    {{emailErrorText}}
                                                </small>
                                            </div>

                                            <div data-placeholder="Attender's Name" class="form-group field">
                                                <input placeholder="Attender's Name" class="form-control" formControlName="Attender" type="text" />
                                                <small class="text-white">
                                                    Please fill in attender's name if patient is compromized.
                                                </small>
                                            </div>

                                            <div data-placeholder="Referral Doctor" class="form-group field  ">
                                                <input placeholder="Referal Doctor" class="form-control" formControlName="ReferalDoctor" type="text" />
                                            </div>
                                            <div data-placeholder="Test Name" class="form-group field  ">
                                                <input placeholder="Test Name( separate by comma)" class="form-control" formControlName="LabTest" type="text" />
                                            </div>
                                            <!-- <div data-placeholder="Additional Message" class="form-group field field-textarea ">
                                                <textarea placeholder="Message" class="form-control" formControlName="Message" cols="50" rows="3"></textarea>
                                            </div> -->
                                            <section id="file-upload" class="ml-3">
                                                <div class="form-group field  ">
                                                    <h6 class="text-white">OPD Prescription</h6>
                                                    <input class="form-control" #fileDropRef (change)="onFileSelected($event)" formControlName="file" type="file" accept="image/*"  />
                                                </div>
                                                <small class="text-white">
                                                    Please upload OPD prescription in image format.
                                                </small>
                                                <small class="text-white" *ngIf="isFileError">
                                                    {{fileErrorText}}
                                                </small>
                                                <!-- <div class="col-md-12 d-flex flex-row justify-content-between">
                                                    <div class="col-md-5 text-white">
                                                        <span class="text-white "> OPD Prescription: </span>
                                                    </div>
                                                    <div class="upload-container" appDnd (fileDropped)="onFileDropped($event)">
                                                        <input type="file" accept="image/*" #fileDropRef (change)="fileBrowseHandler($event.target.files)" id="fileDropRef" multiple />
                                                        <p class="text-muted">File Formats: <em>images</em></p>
                                                    </div>
                                                </div> -->
                                                <!-- <div class="files-list d-flex">
                                                    <div class="single-file m-2" *ngFor="let file of files; let i = index">
                                                        <div><em class="fa fa-2x fa-file file-icon mr-2"></em></div>
                                                        <div class="info">
                                                            <span class="name">
                                                                {{ file?.name }}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <em class="delete-icon fa fa-2x fa-trash-o">X</em>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </section>
                                            <div id="miniSurveyView490-button-group" class="form-group btn-wrapper mr-4 mt-2">
                                                <button type="button" #closeModal class="btn btn-cancel" data-dismiss="modal" (click)="resetError()">Cancel</button>
                                                <!-- </div>
                                                <div id="miniSurveyView490-button-group" class="form-group"> -->
                                                <button type="button" class="btn btn-white btn-submit" (click)="handleSubmit()">Submit</button>
                                            </div>
                                        </div>
                                        <div *ngIf="loading" style="margin-block: -2rem;">
                                            <app-loading></app-loading>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>