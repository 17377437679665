

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DanpheCareDoctor, MetaTag } from '../../cms/models/danphecare.cms.model';
import { HttpClient } from '@angular/common/http';
import { WebsiteService } from '../websiteservice/website.service';

import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { NotificationService } from '../services/notification.service';
import { MetaService } from '@ngx-meta/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-specialist-page',
  templateUrl: './specialist.component.html'
})
export class SpecialistPageComponent implements OnInit, AfterViewInit {

  public specialistList: Array<DanpheCareDoctor> = new Array<DanpheCareDoctor>();
  public specialistId: number;
  public departmentId: number;
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public tag: MetaTag = new MetaTag();
  constructor(public http: HttpClient, public websiteService: WebsiteService, public notifyService: NotificationService, public router: Router, private titleService: Title,
    private meta: MetaService) {
    //
  }

  ngOnInit() {
    this.websiteService.GetDoctorSorting().subscribe(res => {
      if (res) {
        this.specialistList = [];
        this.specialistList = Object.assign(this.specialistList, res);
        this.meta.setTitle(this.specialistList[0].metaTitle);
        this.meta.setTag("description", this.specialistList[0].metaContent);
        this.meta.setTag("keywords", this.specialistList[0].metaKeywords);
        this.specialistList.forEach((item, index) => {
          if (item.imagePath !== null) {
            item.imagePath = environment.url + item.imagePath.substr(2);
          }
          if (item.coverPhoto !== null) {
            item.coverPhoto = environment.url + item.coverPhoto.substr(2);
          }
        });

        const doctor = this.specialistList.sort(x => x.sorting).filter(y => y.sorting !== 0);
        const doctorwithzero = this.specialistList.filter(y => y.sorting === 0);
        this.specialistList = doctor;
        for (const item of doctorwithzero) {
          this.specialistList.push(item);
        }

      }
    },
      err => {
        this.notifyService.showError("Info", "No Specialist Found!");
      });

  }
  ngAfterViewInit() {
    //this.loadScripts();
  }




  GoToSpecialistDetails(permalink) {

    this.router.navigate(['/profilepage', permalink]);
  }
}
