
export class DanpheCareDepartment {
  public departmentId: number;
  public departmentName: string;
  public iconPath: any;
  public imagePath: any;
  public title: string;
  public introduction: string;
  public isIconPathUploaded: boolean;
  public isImagePathUploaded: boolean;
  public createdDate: Date;
  public permaLink: string;
  public metaTitle: string;
  public metaContent: string;
  public metaKeywords: string; 
}


export class DanpheCareDoctor {
  public doctorId: number;
  public designation: string;
  public experience: string;
  public departmentId: number;
  public departmentName: string;
  public fullName: string;
  public imagePath: string;
  public isImagePathUploaded: boolean;
  public coverPhoto: string;
  public isCoverPhotoUploaded: boolean;
  public createdDate: Date;
  public content: string;
  public sorting: number;
  public disableSorting: boolean;
  public permaLink: string;
  public metaTitle: string;
  public metaContent: string;
  public metaKeywords: string; 
}


export class DepartmentConsultation {
  public departmentConsultationId: number;
  public title: string;
  public content: string;
  public departmentId: number;
  public departmentName: string;
  public iconPath: string;
  public isIconPathUploaded: boolean;

}


export class ResourcefulArticles {
  public resourcefulArticlesId: number;
  public title: string;
  public content: string;
  public doctorName: string;
  public doctorId: number;
  public imagePath: string;
  public isImagePathUploaded: boolean;
  public artImage: string;
  public metaTitle: string;
  public metaContent: string;
  public metaKeywords: string; 
}


export class News {
  public newsId: number;
  public title: string;
  public content: string;
  public imagePath: string;
  public isImagePathUploaded: boolean;
  public createdDate: Date;
  public postedBy: string;
  public metaTitle: string;
  public metaContent: string;
  public metaKeywords: string; 
}


export class Blogs {
  public blogId: number;
  public title: string;
  public content: string;
  public imagePath: string;
  public isImagePathUploaded: boolean;
  public createdDate: Date;
  public postedBy: string;
  public permaLink: string;
  public metaTitle: string;
  public metaContent: string;
  public metaKeywords: string; 
}
export class MetaTag {
  public metaTagId: number;
  public content: string;
  public title: string;
  public keywords: string;
  public page: string;
}

export class TeamMember {
  public teamMemberId: number;
  public imagePath: string;
  public isImagePathUploaded: boolean;
  public designation: string;
  public fullName: string;
  public department: string;
  public shortDescription: string;
  public isCoreTeam: boolean;
  public content: string;
  public sorting: number;
  public disableSorting: boolean;
  public coverPhoto: string;
  public isCoverPhotoUploaded: boolean;
  public metaTitle: string;
  public metaContent: string;
  public metaKeywords: string; 
}

export class MediaCoverage {
  public mediaId: number;
  public imagePath: string;
  public isImagePathUploaded: boolean;
  public mediaURL: string;
  public name: string;
  public shortDescription: string;
}

export class TestimonialMain {
  public testimonialMainId: number;
  public title: string;
  public content: string;
  public customerCount: string;
}

export class Testimonial {
  public testimonialId: number;
  public star: string;
  public message: string;
  public writerName: string;
  public writerDesignation: string;
  public imagePath: string;
  public isImagePathUploaded: boolean;
  public starString: string;
}




export class DanpheCareContact {
  public danpheCareContactId: number;
  public location: string;
  public phone: string;
  public email: string;
  public fax: string;
  public website: string;
  public googleMap: string;
  public openingHours: string;
}
export class Expat {
  public expatId: number;
  public name: string;
  public imagePath: string;
  public isImagePathUploaded: boolean;
  public createdDate: Date;
  public metaTitle: string;
  public metaContent: string;
  public metaKeywords: string; 
}
export class Webinar {
  public webinarId: number;
  public title: string;
  public webinarVideo: string;
  public createdDate: Date;
  public postedBy: string;
}
export class DepartmentTab {
  public departmentTabId: number;
  public departmentTabName: string;
}
export class DepartmentTabcontent {
  public departmentContentId: number;
  public departmentTabId: number;
  public departmentId: number;
  public departmentTabName: string;
  public departmentName: string;
  public title: string;
  public content: string;
}

export class DepartmentSubHeading {
  public departmentSubHeadingId: number;
  public departmentId: number;
  public iconPath: string;
  public title: string;
  public shortDescription: string;
  public isIconPathUploaded: boolean;
  public departmentName: string;
  public createdDate: Date;
}

export class Package {
  public packageModelId: number;
  public packageName: string;
}

export class PackageDetails {
  public packageDetailId: number;
  public packageName: string;
  public title: string;
  public content: string;
  public PackageNameModelId: number;
  public PictureBinaryData: string;
}

export class SubServices {
  public subServicesId: number;
  public title: string;
  public serviceName: string;
  public imagePath: string;
  public ourServicesId: number;
  public isImagePathUploaded: boolean;
}

export class SubServicesDetails {
  public subServicesDetailsId: number;
  public subTitle: string;
  public title: string;
  public introduction: string;
  public content: string;
  public imagePath: string;
  public subServicesId: number;
  public isImagePathUploaded: boolean;
}


export class LabEquipment {
   public name: string;
  public imagePath: string;

}

