<table width="1080px" align="center" border="0">
  <tr>
    <td style="padding: 20px 0px;">
      <h3>Invoice No. <span>Pl</span></h3>
      <p style="margin-top: 10px; margin-bottom: 10px;"><b>Printed at:</b> <span>15-4-2021</span></p>
      <p style="margin-top: 10px; margin-bottom: 10px;"><b>Due Date:</b> <span>15-4-2021</span></p>
      <p style="margin-top: 10px; margin-bottom: 10px;"><b>Payment Type:</b> <span>BACS</span></p>
    </td>
    <td style="padding: 20px 0px; text-align: right;">
      <span>
        <img src="img/logo_final.png" alt="">
      </span>
    </td>
  </tr>

  <tr style=" justify-content: space-between; width: 100%; ">
    <td style="padding: 20px 0px; border-top: 1px solid #ccc; width: 100%;">
      <h3>Invoice From </h3>
      <p style="margin-top: 10px; margin-bottom: 10px;"> <span>wecare pvt.ltd</span></p>
      <p style="margin-top: 10px; margin-bottom: 10px;"> <span>Kathnandu, Dillibazar</span></p>
      <p style="margin-top: 10px; margin-bottom: 10px;"> <span>Vat Reg No GB 647 68361 123</span></p>
    </td>
    <td style="padding: 20px 0px; border-top: 1px solid #ccc; width: 100%;">
      <h3>Invoice To</h3>
      <p style="margin-top: 10px; margin-bottom: 10px;"><span>john Doe</span></p>
      <p style="margin-top: 10px; margin-bottom: 10px;"><span>Newroad Pokhara, st.15</span></p>
      <p style="margin-top: 10px; margin-bottom: 10px;"><span>Nepal</span></p>
    </td>
  </tr>

  <tr style="border: 1px solid #ccc; margin-top: 30px;">
    <td colspan="2" style="padding: 40px 0px; border-top: 1px solid #ccc;">
      <table width="99%" border="1" cellspacing="0" cellpadding="0" style="border: none;">
        <tr>
          <th style="padding: 8px 8px; background-color: #ccc;" width="60px">No.</th>
          <th style="padding: 8px 8px; background-color: #ccc;">Description</th>
          <th style="padding: 8px 8px; background-color: #ccc;" width="150px">Quantity</th>
          <th style="padding: 8px 8px; background-color: #ccc;" width="200px">Unit Price</th>
          <th style="padding: 8px 8px; background-color: #ccc;" width="200px">Total</th>
        </tr>

        <!-- shopping items list  -->
        <tr>
          <td style="padding: 8px 8px;">1</td>
          <td style="padding: 8px 8px;">Corporate Coivd Care</td>
          <td style="padding: 8px 8px;">3</td>
          <td style="padding: 8px 8px;">1300</td>
          <td style="padding: 8px 8px;">3900</td>
        </tr>

        <tr>
          <td style="padding: 8px 8px;">1</td>
          <td style="padding: 8px 8px;">Corporate Coivd Care</td>
          <td style="padding: 8px 8px;">3</td>
          <td style="padding: 8px 8px;">1300</td>
          <td style="padding: 8px 8px;">3900</td>
        </tr>

        <tr>
          <td style="padding: 8px 8px;">1</td>
          <td style="padding: 8px 8px;">Corporate Coivd Care</td>
          <td style="padding: 8px 8px;">3</td>
          <td style="padding: 8px 8px;">1300</td>
          <td style="padding: 8px 8px;">3900</td>
        </tr>

        <!-- total subtotal and vat calculation  -->
        <tr>
          <td style="padding: 8px 8px; border: none;" colspan="3"></td>
          <td style="padding: 8px 8px;"><b>Subtotal</b></td>
          <td style="padding: 8px 8px;"><b>12806</b></td>
        </tr>
        <tr>
          <td style="padding: 8px 8px; border: none;" colspan="3"></td>
          <td style="padding: 8px 8px;"><b>Vat</b></td>
          <td style="padding: 8px 8px;"><b>500</b></td>
        </tr>
        <tr>
          <td style="padding: 8px 8px; border: none;" colspan="3"></td>
          <td style="padding: 8px 8px;"><b>Total</b></td>
          <td style="padding: 8px 8px;"><b>12306</b></td>
        </tr>
      </table>
    </td>
  </tr>
</table>