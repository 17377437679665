export class SingleProductViewModel {
  Id: number;
  ProductGuid: string;
  ProductType: string;
  Name: string;
  Sku: string;
  NotReturnable: boolean;
  ManufacturerPartNumber: string;
  GroupingLabel: string;
  ShortDescription: string;
  FullDescription: string;
  AdminComment: string;
  ImagePath: string;
  VisibleIndividually: boolean;
  ShowOnHomepage: boolean;
  DisableBuyButton: boolean;
  MarkAsNew: boolean;
  MarkAsNewStartDateTime: Date | null;
  MarkAsNewEndDateTime: Date | null;
  Price: number = 0;
  OldPrice: number = 0;
  MetaKeywords: string;
  MetaDescription: string;
  MetaTitle: string;
  PermaLink: string;
  ChildGroups: ChildGroupViewModel[];
  PictureId: number;
}

export class ChildGroupViewModel {
  GroupingLabel: string;
  ChildProducts: ChildProductViewModel[];
}

export class ChildProductViewModel {
  Id: number;
  ProductGuid: string;
  Name: string;
  Sku: string;
  MarkAsNew: boolean;
  MarkAsNewStartDateTime: string | null;
  MarkAsNewEndDateTime: string | null;
}

export class ParentChildProductsViewModel {
  ParentProductId: number;
  ChildProductId: number;
}
