<style>
  p {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    max-width: 90%;
    position: sticky;
  }

  .btn btn-primary {
    max-width: 100px !important;
    width: 100px !important;
  }
</style>
<div class="ccm-page ccm-page-id-1 page-type-page page-template-full" id="wrapper-outer">
  <div id="wrapper">
    <div class="common-appointment-container">
      <a class="co-btn type-fill type-border">
        <span><img alt="calendar" src="/application/themes/_imagio_c/img/icons/icon_callender.png">
        </span>Plan Your Visit
      </a>
    </div>


    <div id="content-wrapper" class="mt-0">
      <div id="packages-page">
        <section class="packages-section">
          <div class="custom-container">
            <div class="section-title main-title">
              <h5>Best of our services</h5>
              <h1>Health packages</h1>
            </div>
            <div class="section-content">
              <div class="row mt-3" *ngIf="packageDetailsList?.length>0">
                <div class="col-md-12" *ngFor="let data of packageDetailsList; let index=i">
                  <div class="help-list help-list-sm d-flex product-list-item">
                    <div class="product-item-content">
                      <div class="help-list-image">
                        <img alt="product-image" *ngIf="data.PictureBinaryData" [src]="'data:image/jpeg;base64,'+ data.PictureBinaryData">
                        <img alt="noif" *ngIf="!data.PictureBinaryData" src="../../../../../assets/img/no-image.jpg">
                      </div>
                    </div>
                    <div class="product-item-text">
                      <div class="help-content">
                        <div (click)="ReadMore(data.Id)">
                          <h2>{{data.PackageName}}</h2>
                          <p style="-webkit-line-clamp: 3 !important;">{{data.FullDescription}}</p>
                          <a (click)="ReadMore(data.Id)" class="link">Read More</a>
                        </div>
                      </div>
                    </div>
                    <div class="product-add-to-cart-section">
                      <div class="price-section">
                        <h2>NPR {{data.Price}} /-</h2>
                        <button (click)="ReadMore(data.Id)" class="btn btn-primary">
                          <em class="fas fa-shopping-cart"></em> Book Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>