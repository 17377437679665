
<div class="section page-top">
  <div class="ver-2 text-center">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="justify-content-center hero-text-center">
            <div class="hero-text mt-0"><b>Blog</b></div>
            <h4></h4>
          </div>
        </div>
        <div class="col text-right">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="[ '/', 'homepage' ]">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Blog</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="blog-wrapper pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-6" *ngFor="let data of blogsList" >
        <div class="blog-post" >
        <a  >
          <div class="blog-img"> <img [src]="data.imagePath" alt=""> </div>
          <div class="blog-content">
            <h4><a  >{{data.title}}</a></h4>
            <div class="post">
              <ul>
                <li><a href="#"><svg class="svg-inline--fa fa-clock fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg><!-- <i class="far fa-clock"></i> -->{{data.createdDate | date:'mediumDate'}}</a></li>

                <li><a href="#"><svg class="svg-inline--fa fa-user fa-w-14" aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"></path></svg><!-- <i class="far fa-user"></i> -->Posted by {{data.postedBy}}</a></li>
              </ul>
            </div>

          </div>
        </a>
        </div>
      </div>
    </div>
  </div>
</section>
<style>
  .light-over-pages {
    position: absolute;
    background: linear-gradient(180deg, rgb(255 255 255 / 70%), rgb(189 189 189 / 40%));
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    top: 0;
    left: 0;
  }

  .list-style {
    padding: 0;
  }

    .list-style li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin: 5px 0;
    }

      .list-style li span {
        display: block;
      }

      .list-style li:before {
        content: "\f00c";
        font-family: FontAwesome;
        background-color: #0db696;
        color: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 15px;
        height: 15px;
        align-items: center;
        font-size: 0.7rem;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 0;
      }

  .home-isolation .sub-heading, .emmergency-info .sub-heading {
    font-weight: 700;
    font-size: 1.5rem;
  }

  .center-title {
  }

    .center-title h4 {
    }
</style>

