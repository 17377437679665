<section id="video-gallery">
  <div class="section-title main-title">
    <h5>Gallery</h5>
    <h1>Videos</h1>
  </div>
  <div class="video-container">
    <div class="row">
      <div class="col-lg-12">
        <youtube-player class="youtube-video"
                        videoId="qOYTOLrAeLM"
                        [height]="800"
                        suggestedQuality="highres"
                        [startSeconds]="0"
                        [endSeconds]="300">
        </youtube-player>
      </div>
      <div class="col-lg-6">
        <youtube-player class="youtube-video"
                        videoId="0B7H5BBV0YA"
                        [height]="500"
                        suggestedQuality="highres"
                        [startSeconds]="0"
                        [endSeconds]="300">
        </youtube-player>
      </div>
      <div class="col-lg-6">
        <youtube-player class="youtube-video"
                        videoId="RIpjuyJg-MI"
                        [height]="500"
                        suggestedQuality="highres"
                        [startSeconds]="0"
                        [endSeconds]="300">
        </youtube-player>
      </div>
    </div>
  </div>
</section>
