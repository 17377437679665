
export class ContactUsModel {
  public UserContactId: Number;
  public Name: string;
  public ContactNumber: string;
  public Email: string;
  public Company: string;
  public Message: string;
  public CreatedOn: Date;
  public Address: Date;

}