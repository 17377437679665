

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { WebsiteService } from '../websiteservice/website.service';

import { OurServiceModel } from '../../cms/models/OurServices.model';
import { LabEquipment, MetaTag, SubServices } from '../../cms/models/danphecare.cms.model';
import { Title, Meta } from '@angular/platform-browser';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MetaService } from '@ngx-meta/core';
import { DanpheCareReachUsQuicklyModel } from '../../cms/models/danphecare/danphecare.model';
import { NotificationService } from './notification.service';
import { DanphecareService } from './danphe.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'service-details-page',
  templateUrl: './servicedetails.component.html'
})
export class ServiceDetailsPageComponent implements OnInit, AfterViewInit {
  public servicesdetailId: string;
  public servicesId: number;
  public services: OurServiceModel = new OurServiceModel();
  // serviceimg = require("../content/img/expat-about.png");
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public DhcareRuq: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public tag: MetaTag = new MetaTag();
  public selServices: any;
  public submitted_ruq: boolean = false;
  public loading: boolean = false;
  submitForm: boolean = false;
  public reCAPTCHAstatus: boolean = false;
  public submitted: boolean = false;
  registerForm = new FormGroup({ phonenumber: new FormControl(), name: new FormControl() });
  public reachForm = new FormGroup({ phonenumber: new FormControl(), name: new FormControl() });
  loading_ruq = false;

  filteredPage = [
    { id: 1, title: "Kathmandu Services" },
    { id: 2, title: "Lalitpur Services" }
  ];
  public permaLink: any;
  public content: string;
  public serviceId: number;
  public subServices: Array<SubServices> = new Array<SubServices>();
  public labEquipments: LabEquipment[] = [];
  public labEquipmentsOfService: LabEquipment[] = [];
  slideConfig = {
    "slidesToShow": 5, "slidesToScroll": 1, "infinite": true, "dots": false, "autoplay": true, "autoplaySpeed": 3000, 'rows': 1, "arrows": true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
  };
  isHealthAtHomeService: boolean = false;
  constructor(public routing: Router, private route: ActivatedRoute, public formBuilder: FormBuilder,
    public websiteService: WebsiteService, private notifyService: NotificationService, private titleService: Title,
    private metaService: Meta, public danphecareservice: DanphecareService, private readonly meta: MetaService) {
    this.route.params.subscribe((params: Params) => {
      this.permaLink = params.permalink;
      if (this.permaLink == "health-at-home") {
        this.isHealthAtHomeService = true;
      }
      else
        this.isHealthAtHomeService = false;
    });
  }
  ngOnInit() {




    this.websiteService.GetServicesById(this.permaLink).subscribe(res => {
      this.services = res;
      // this.serviceId = this.services.serviceId;
      // if (this.services.iconPath !== null) {
      //   this.services.iconPath = environment.url + this.services.iconPath.substr(2);
      // }
      if (this.services.imagePath !== null) {
        this.services.imagePath = environment.url + this.services.imagePath.substr(2);
      }

      this.GetSubServices(this.services.serviceId);
      this.GetLabEquipmentsOfService(this.services.serviceId);
      // if (this.services.coverImage !== null) {
      //   this.services.coverImage = environment.url + this.services.coverImage.substr(2);
      // }
      // if (this.services.subscriptionImage !== null) {
      //   this.services.subscriptionImage = environment.url + this.services.subscriptionImage.substr(2);
      // }
      this.meta.setTitle(this.services.metaTitle);
      this.meta.setTag("description", this.services.metaContent);
      this.meta.setTag("keywords", this.services.metaKeywords);
      this.meta.setTag('og:title', this.services.serviceName);
      this.meta.setTag('og:image', this.services.imagePath);
      this.meta.setTag('og:image:width', "800");
      this.meta.setTag('og:image:height', "600");
      this.meta.setTag('og:url', location.href);
      this.meta.setTag('og:type', "Service");
      this.meta.setTag('og:description', this.services.metaContent);
      this.meta.setTag('twitter:card', location.href);
      this.meta.setTag('twitter:title', this.services.serviceName);
      this.meta.setTag('twitter:description', this.services.metaContent);
      this.meta.setTag('twitter:image', this.services.imagePath);
      this.meta.setTag('og:app_id', "260103905625004");


    },
      res => {
        //this.notifyService.showError("Info", "No Blogs Found!");
      });

    this.routing.routeReuseStrategy.shouldReuseRoute = () => false;

    this.CaptchaCallback();

    this.selServices = this.filteredPage[0].title;

  }
  ngAfterViewInit() {
    //this.loadScripts();
  }





  public CaptchaCallback() {
    this.reachForm = this.formBuilder.group({
      phonenumber: ['', [Validators.required]],
      name: ['', [Validators.required]]
    });
  }

  onSubmitReachUsQuickly() {
    if (this.reachForm.valid) {
      this.submitForm = true;
      this.loading = true;
      this.DhcareRuq.company = this.selServices.title
      this.danphecareservice.reachUsQuickly(this.DhcareRuq)
        .subscribe(res => this.SuccessPostReachUsQuickly(res),
          res => this.Error1(res));
    }
    else {
      this.submitForm = true;
      this.loading = false;
      this.notifyService.showInfo("Info", "Please enter the required field");
      //this.registerForm.reset();

      (this.submitForm) ? setTimeout(() => { this.submitForm = false }, 5250) : null

    }
  }

  SuccessPostReachUsQuickly(res) {
    this.loading = false;
    this.DhcareRuq = new DanpheCareReachUsQuicklyModel();

    // this.GetAppliedUsrlist();
    this.notifyService.showSuccess('success', 'application submitted successfully ');
    this.submitForm = false;
    this.reCAPTCHAstatus = false;
    this.reachForm.reset();
    //  this.reCAPTCHAstatusRUQ = false;

    //this.CaptchaCallback();
  }

  Error1(res) {
    const response = res;
    this.submitted = false;
    this.submitted_ruq = false;
    this.loading = false;
    this.loading_ruq = false;
    this.notifyService.showError("Error", " Please fill up the required field")
  }
  get PhoneNumberControl() {
    return this.reachForm.get("phonenumber");
  }

  get NameControl() {
    return this.reachForm.get("name");
  }

  GetSubServices(id) {
    this.websiteService.GetSubServicesById(id).subscribe(res => {
      if (res) {
        this.subServices = [];
        this.subServices = Object.assign(this.subServices, res);
        this.subServices.forEach((item, index) => {
          if (item.imagePath !== null) {
            item.imagePath = environment.url + item.imagePath.substr(2);
          }

        });
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Specialist Found!");
      });
  }
  // SubServiceDetails(Id){
  //   this.routing.navigate(['/profilepage'], { queryParams: { id: Id } });
  // }
  //onSubmitReachUsQuickly() {
  //  this.submitted = true;
  //  if (this.DhcareRuq.company == null) {
  //    this.isCompany = true;
  //    this.reachForm.invalid;
  //  }
  //  if (this.reachForm.valid) {
  //    this.submitted = true;
  //    this.DhcareRuq.company = this.selServices.title;
  //    this.loading = true;
  //    this.danphecareservice.reachUsQuickly(this.DhcareRuq)
  //      .subscribe(res => this.SuccessPostReachUsQuickly(res),
  //        res => this.Error1(res));

  //  }
  //  else {

  //    this.submitted == true;
  //    this.loading == false;
  //    this.notifyService.showInfo("Info", "Please enter the required field");
  //    (this.submitted) ? setTimeout(() => { this.submitted = false }, 5230) : null
  //  }

  //}
  //SuccessPostReachUsQuickly(res) {
  //  this.loading = false;
  //  this.DhcareRuq = new DanpheCareReachUsQuicklyModel();
  //  this.notifyService.showSuccess('success', 'application submitted successfully ');
  //  this.submitted = false;
  //  this.reachForm.reset();
  //}

  //Error1(res) {
  //  this.loading = false;
  //  const response = res;
  //  this.notifyService.showError("Error", " Please fill up the required field")
  //  //this.reachForm.reset();
  //}
  //get PhoneNumberControl() {
  //  return this.reachForm.get("phonenumber") as FormControl;
  //}

  //get NameControl() {
  //  return this.reachForm.get("name") as FormControl;
  //}

  //ngOnDestroy() {
  //  this.meta.removeTag('property="og:type"');
  //}

  public GetLabEquipmentsOfService(serviceId: number) {
    this.websiteService.GetLabEquipmentsByServiceId(serviceId).subscribe(res => {
      if (res) {
        this.labEquipmentsOfService = Object.assign(this.labEquipmentsOfService, res);
        this.labEquipmentsOfService.forEach((equipment, index) => {
          if (equipment.imagePath != null) {
            equipment.imagePath = environment.url + equipment.imagePath.substr(2);
          }
        });
      }
    });
  }
}
