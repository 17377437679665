import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-health-at-home',
  templateUrl: './health-at-home.component.html'
})
export class HealthAtHomeComponent {
  constructor(private router: Router) {
    this.router.navigate(['/service', 'health-at-home']);
  }
}
