export class OurServiceModel {
  public serviceId: number;
  public serviceName: string;
  public content: string;
  public imagePath: string;
  public coverImage: string;
  public iconPath: string;
  public subscriptionPlan: string;
  public subscriptionContent: string;
  public subscriptionImage: string;
  public isImagePathUploaded: boolean;
  public isIconPathUploaded: boolean;
  public isSubscriptionImageUploaded: boolean;
  public isCoverImagePathUploaded: boolean;
  public departmentId: number;
  public departmentName: string;
  public shortIntroduction: string;
  public color: string;
  public permaLink: string;
  public metaTitle: string;
  public metaContent: string;
  public metaKeywords: string;
}
