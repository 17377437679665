

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { WebsiteService } from '../websiteservice/website.service';
import { Blogs, MetaTag, SubServicesDetails } from '../../cms/models/danphecare.cms.model';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '@ngx-meta/core';
import { NotificationService } from '../services/notification.service';


@Component({
  selector: 'app-sub-services-detail-page',
  templateUrl: './sub-services.component.html'
})

export class SubServicesDetailsPageComponent implements OnInit, AfterViewInit {

  public blogId: number;
  public subServicesDetails: SubServicesDetails = new SubServicesDetails();
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public tag: MetaTag = new MetaTag();
  public content: string;
  public permaLink: any;
  public url: any;
  public description: string;
  public image: string;
  public subServId: any;
  public subServicesDetailsId:number;
  constructor(public routing: Router, private route: ActivatedRoute, public websiteService: WebsiteService, private notifyService: NotificationService, private titleService: Title, private metaService: Meta, private readonly meta: MetaService) {
    this.subServId = this.route.snapshot.queryParamMap.get('id');
    this.subServicesDetailsId = parseInt(this.subServId, 10);
  }

  ngOnInit() {
    this.websiteService.GetSubServicesDetailsById(this.subServicesDetailsId).subscribe(res => {
      this.subServicesDetails = res;
      this.subServicesDetails.imagePath = "https://localhost:44350" + this.subServicesDetails.imagePath.substr(2);
    //   this.meta.setTitle(this.blog.metaTitle);
    //   this.meta.setTag("description", this.blog.metaContent);
    //   this.meta.setTag("keywords", this.blog.metaKeywords);
    //   this.meta.setTag('og:title', this.blog.title);
    //   this.meta.setTag('og:image', this.blog.imagePath);
    //   this.meta.setTag('og:image:width', "800");
    //   this.meta.setTag('og:image:height', "600");
    //   this.meta.setTag('og:url', location.href);
    //   this.meta.setTag('og:type', "Blog");
    //   this.meta.setTag('og:description', this.blog.metaContent);
    //   this.meta.setTag('twitter:card', location.href);
    //   this.meta.setTag('twitter:title', this.blog.title);
    //   this.meta.setTag('twitter:description', this.blog.metaContent);
    //   this.meta.setTag('twitter:image', this.blog.imagePath);
    //   this.meta.setTag('og:app_id', "260103905625004");      

    },
      res => {
        //this.notifyService.showError("Info", "No Blogs Found!");
      });   

  }
  ngAfterViewInit() {
  //
  }
}
