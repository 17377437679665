

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../services/notification.service';

import { WebsiteService } from '../websiteservice/website.service';


@Component({
  selector: 'app-news-detail-page',
  templateUrl: './newsdetails.component.html'
})

export class NewsDetailsPageComponent implements OnInit, AfterViewInit {

  public newsdetailId: string;
  public newsId: number;
  public news: any;
  constructor(public routing: Router, private route: ActivatedRoute, public websiteService: WebsiteService, private notifyService: NotificationService) {
    this.newsdetailId = this.route.snapshot.queryParamMap.get('id');
    this.newsId = parseInt(this.newsdetailId, 10);
  }

  ngOnInit() {
    this.newsId = parseInt(this.newsdetailId, 10);
    this.GetNews(this.newsId);
    //this.loadScripts();

  }
  ngAfterViewInit() {
    //this.loadScripts();
    //this.blogId = parseInt(this.blogdetailId, 10);
    //this.GetBlogs(this.blogId);
  }
  GetNews(id) {
    this.websiteService.GetNewsById(id)
      .subscribe(res => this.SuccessNews(res),
        res => this.Error(res));
  }
  SuccessNews(res) {
    this.news = res;
    this.news.imagePath = "assets" + this.news.imagePath.substr(2);
  }
  Error(res) {
    this.notifyService.showError("Info", "No News Found!");
  }

  loadScripts() {
    const dynamicScripts = [
      //'../content/js/jquery.min.js',
      './content/js/custom.js',
      './content/js/move-img-effect.js',
      './content/js/plugins.js',
      //'../content/js/popper.min.js',
      './content/js/slick.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }


}
