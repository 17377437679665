import { Component, OnInit } from "@angular/core";

@Component({
    templateUrl: './billing-details.html',
    styleUrls : ['billing-details-style.css']
})

export class BillingDetailsComponent implements OnInit {
    
    constructor(){

    }
    ngOnInit(){

    }
}