import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { HomeComponent } from './modules/general/home/home.component';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';
import { AboutUsPageComponent } from './modules/website/aboutus/aboutus.component';
import { BillingDetailsComponent } from './modules/website/billing-details/billing-details.component';
import { FinalBillingComponent } from './modules/website/billing-details/billing-final/billing-final.component';
import { BlogPageComponent } from './modules/website/blog/blog.component';
import { BlogDetailsPageComponent } from './modules/website/blog/blogdetails.component';
import { BookNowComponent } from './modules/website/buy-package/book-now.component';
import { CareerDetailComponent } from './modules/website/career/career-details/careerDetails.component';
import { CareerListComponent } from './modules/website/career/career-list.component';
import { CheckoutComponent } from './modules/website/checkout/checkout.component';
import { ContactPageComponent } from './modules/website/contact/contact.component';
import { DanpheEHRComponent } from './modules/website/danphehr/danpheehr.component';
import { DepartmentDetailsListComponent } from './modules/website/department/departmentdetails-list.component';
import { DepartmentDetailsPageComponent } from './modules/website/department/departmentdetails-page.component';
import { ExpatPageComponent } from './modules/website/expat/expat.component';
import { PhotoGalleryComponent } from './modules/website/gallery/photo-gallery.component';
import { VideoGalleryComponent } from './modules/website/gallery/video/video-gallery.component';
import { HamroPatroComponent } from './modules/website/hamropatra/hamropatra.component';
import { HealthAtHomeComponent } from './modules/website/health-at-home/health-at-home.component';
import { HomePageComponent } from './modules/website/home/homepage.component';
import { MessageFromCeoComponent } from './modules/website/message-from-ceo/message-from-ceo.component';
import { NewsPageComponent } from './modules/website/news/news.component';
import { NewsDetailsPageComponent } from './modules/website/news/newsdetails.component';
import { PackageComponent } from './modules/website/package/package.component';
import { ArticlesPageComponent } from './modules/website/profile/articles-page.component';
import { ProfilePageComponent } from './modules/website/profile/profile.component';
import { SpecialistPageComponent } from './modules/website/profile/specialist.component';
import { ServiceDetailsPageComponent } from './modules/website/services/servicedetails.component';
import { ServicesPageComponent } from './modules/website/services/services.component';
import { SingleProductComponent } from './modules/website/single-product/single-product.component';
import { SwasthpalikaComponent } from './modules/website/swasthpalika/swasthpalika.component';
import { CoreTeamProfileComponent } from './modules/website/team/core-team-profile.component';
import { TeamPageComponent } from './modules/website/team/team.component';

const routes: Routes = [

  {
    path: '', component: HomePageComponent,

  },
  { path: 'aboutpage', component: AboutUsPageComponent },
  { path: 'ceomessage', component: MessageFromCeoComponent },
  {
    path: 'gallery', children: [
      { path: '', redirectTo: 'photo', pathMatch: "full" },
      { path: 'photo', component: PhotoGalleryComponent },
      { path: 'video', component: VideoGalleryComponent }
    ]
  },
  { path: 'homepage', component: HomePageComponent },
  { path: 'blogpage', component: BlogPageComponent },
  { path: 'subservices/:permalink', component: BlogDetailsPageComponent },
  { path: 'contactpage', component: ContactPageComponent },
  { path: 'expatpage', component: ExpatPageComponent },
  { path: 'newspage', component: NewsPageComponent },
  { path: 'newsdetails', component: NewsDetailsPageComponent },
  { path: 'profilepage/:permalink', component: ProfilePageComponent },
  { path: 'servicepage', component: ServicesPageComponent },
  { path: 'service/:permalink', component: ServiceDetailsPageComponent },
  { path: 'specialist', component: SpecialistPageComponent },
  { path: 'teampage', component: TeamPageComponent },
  { path: 'teamprofile', component: CoreTeamProfileComponent },
  { path: 'department/:permalink', component: DepartmentDetailsPageComponent },
  { path: 'articlespage', component: ArticlesPageComponent },
  { path: 'coreteamdetails', component: CoreTeamProfileComponent },
  { path: 'departmentdetailslist', component: DepartmentDetailsListComponent },
  { path: 'hamropatro', component: HamroPatroComponent },
  { path: 'whims', component: SwasthpalikaComponent },
  { path: 'ehr', component: DanpheEHRComponent },
  { path: 'package', component: PackageComponent },
  { path: 'readmore/:permalink', component: SingleProductComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'billing-details', component: BillingDetailsComponent },
  { path: 'final-billing', component: FinalBillingComponent },
  { path: 'carrier', component: CareerListComponent },
  { path: 'careerDetails/:permalink', component: CareerDetailComponent },
  { path: 'HealthAtHome', component: HealthAtHomeComponent },
  // { path: '', component: HomeComponent, },
  // {
  //   path: 'typescript',
  //   loadChildren: () => import('./modules/application/example-typescript/tutorial.module')
  //     .then(mod => mod.TutorialModule)
  // },
  // {
  //   path: 'crud',
  //   loadChildren: () => import('./modules/application/example-crud/tutorial.module')
  //     .then(mod => mod.TutorialModule)
  // },
  // {
  //   path: 'movies-images-list',
  //   loadChildren: () => import('./modules/application/movies-images-list/movies-images-list.module')
  //     .then(mod => mod.MoviesImagesListModule)
  // },
  // {
  //   path: 'leaflet',
  //   loadChildren: () => import('./modules/application/example-leaflet/tutorial.module')
  //     .then(mod => mod.TutorialModule)
  // },
  // {
  //   path: 'charts',
  //   loadChildren: () => import('./modules/application/example-charts/tutorial.module')
  //     .then(mod => mod.TutorialModule)
  // },
  // {
  //   path: 'httpclient',
  //   loadChildren: () => import('./modules/application/items/items.module')
  //     .then(mod => mod.ItemsModule)
  // },
  // {
  //   path: 'template-driven-forms',
  //   loadChildren: () => import('./modules/application/example-template-driven-forms/tutorial.module')
  //     .then(mod => mod.TutorialModule)
  // },
  // {
  //   path: 'components',
  //   loadChildren: () => import('./modules/application/example-components/tutorial.module')
  //     .then(mod => mod.TutorialModule)
  // },
  // {
  //   path: 'services',
  //   loadChildren: () => import('./modules/application/example-services/tutorial.module')
  //     .then(mod => mod.TutorialModule)
  // },
  // {
  //   path: 'reactive-form',
  //   loadChildren: () => import('./modules/application/example-reactive-form/tutorial.module')
  //     .then(mod => mod.TutorialModule)
  // },
  // {
  //   path: 'bootstrap-prototype',
  //   loadChildren: () => import('./modules/application/example-bootstrap-prototype/example-bootstrap-prototype.module')
  //     .then(mod => mod.ExampleBootstrapPrototypeModule)
  // },
  // {
  //   path: 'contact',
  //   loadChildren: () => import('./modules/general/contact/contact.module')
  //     .then(mod => mod.ContactModule)
  // },
  // {
  //   path: 'about',
  //   loadChildren: () => import('./modules/general/about/about.module')
  //     .then(mod => mod.AboutModule)
  // },
  // {
  //   path: 'signin',
  //   loadChildren: () => import('./modules/general/signin/signin.module')
  //     .then(mod => mod.SigninModule)
  // },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
