

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Expat, Webinar, DanpheCareContact, TeamMember, MediaCoverage, MetaTag } from '../../cms/models/danphecare.cms.model';
import { WebsiteService } from '../websiteservice/website.service';

import { AboutUsModel } from '../../cms/models/aboutUs.model';
import { Title } from '@angular/platform-browser';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DanpheCareReachUsQuicklyModel } from '../../cms/models/danphecare/danphecare.model';
import { DanphecareService } from '../services/danphe.service';
import { NotificationService } from '../services/notification.service';
import { MetaService } from '@ngx-meta/core';


@Component({
  selector: 'app-expat-page',
  templateUrl: './expat.component.html'
})
export class ExpatPageComponent implements OnInit, AfterViewInit {
  // expat = require("../content/img/expat-about.png");
  public expatList: Array<Expat> = new Array<Expat>();
  public webinarList: Array<Webinar> = new Array<Webinar>();
  public imagePath: string;
  public aboutUs: AboutUsModel = new AboutUsModel();
  public contact: DanpheCareContact = new DanpheCareContact();
  ourTeamMemberList: TeamMember[];
  coreTeamMemberList: TeamMember[];
  ourMediaCoverageList: MediaCoverage[];
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public tag: MetaTag = new MetaTag();
  public submitted_ruq: boolean = false;
  public loading: boolean = false;
  submitForm: boolean = false;
  public reCAPTCHAstatus: boolean = false;
  public submitted: boolean = false;
  loading_ruq = false;
  public DhcareRuq: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  registerForm: FormGroup;
  constructor(public websiteService: WebsiteService, private formBuilder: FormBuilder, public danphecareservice: DanphecareService, private notifyService: NotificationService, private titleService: Title,
    private meta: MetaService) {
    

  }

  ngOnInit() {
    this.websiteService.GetExpat().subscribe(res => {
      if (res) {
        this.expatList = [];
        this.expatList = Object.assign(this.expatList, res);
        let image = "";
        this.expatList.forEach((item, index) => {
          if (item.imagePath !== null) {
            item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
            image = item.imagePath;
          }
        });
        this.meta.setTitle(this.expatList[0].metaTitle);
        this.meta.setTag("description", this.expatList[0].metaContent);
        this.meta.setTag("keywords", this.expatList[0].metaKeywords);
        this.meta.setTag('og:title', this.expatList[0].metaTitle);
        this.meta.setTag('og:image', image);
        this.meta.setTag('og:image:width', "800");
        this.meta.setTag('og:image:height', "600");
        this.meta.setTag('og:url', location.href);
        this.meta.setTag('og:type', "Blog");
        this.meta.setTag('og:description', this.expatList[0].metaContent);
        this.meta.setTag('twitter:card', location.href);
        this.meta.setTag('twitter:title', this.expatList[0].metaTitle);
        this.meta.setTag('twitter:description', this.expatList[0].metaContent);
        this.meta.setTag('twitter:image', image);
        this.meta.setTag('og:app_id', "260103905625004");      
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Expat Found!");
      });
    this.CaptchaCallback();
    this.GetWebinar();
    this.GetContact();
    this.GetAboutUs();
    this.GetOurTeamMember();
    this.GetOurMediaCoverage();
    //this.loadScripts();

  }
  ngAfterViewInit() {
    //this.loadScripts();
  }
  

  public CaptchaCallback() {
    this.registerForm = this.formBuilder.group({
      phonenumber: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)],
      ],
     
    });
  }

  
  GetExpat() {
    this.websiteService.GetExpat().subscribe(res => {
      if (res) {
        this.expatList = [];
        this.expatList = Object.assign(this.expatList, res);
        this.expatList.forEach((item, index) => {
          if (item.imagePath !== null) {
            item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
          }
        });
        //this.imagePath = this.expatList[0].imagePath;
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Expat Found!");
      });
  }
  GetWebinar() {
    this.websiteService.GetWebinar().subscribe(res => {
      if (res) {
        this.webinarList = [];
        this.webinarList = Object.assign(this.webinarList, res);
       
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Webinar Found!");
      });
  }
  GetContact() {
    this.websiteService.GetContact().subscribe(res => {
      if (res) {
        this.contact = Object.assign(this.contact, res[0]);
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Contact Found!");
      });
  }
  GetAboutUs() {
    this.websiteService.GetAboutUs().subscribe(res => {
      if (res && res.length > 0) {
        this.aboutUs = Object.assign(this.aboutUs, res[0]);
      
        this.aboutUs.imagePath = "https://cms.danphecare.com" + this.aboutUs.imagePath.substr(2);
    
      }
    },
      res => {
        //this.notifyService.showError("Error", "Internal Error")
      });
  }
  GetOurTeamMember() {
    this.websiteService.GetOurTeamMembers().subscribe(res => {
      if (res) {
        this.ourTeamMemberList = [];
        this.ourTeamMemberList = Object.assign(this.ourTeamMemberList, res);
        this.coreTeamMemberList = this.ourTeamMemberList.filter(t => t.isCoreTeam === true);
        this.coreTeamMemberList.forEach((item, index) => {
          if (item.imagePath !== null) {
            item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
          }
          if (item.coverPhoto !== null) {
            item.coverPhoto = "https://cms.danphecare.com" + item.coverPhoto.substr(2);
          }
        });
      }
    },
      res => {
        console.log("Error Message: " + res);
        // this.notifyService.showError("Info", "No Services Found!");
      });
  }
  GetOurMediaCoverage() {
    this.websiteService.GetOurMediaCoverage().subscribe(res => {
      if (res) {
        this.ourMediaCoverageList = [];
        this.ourMediaCoverageList = Object.assign(this.ourMediaCoverageList, res);
        this.ourMediaCoverageList.forEach((item, index) => {
          item.imagePath = "https://cms.danphecare.com" + item.imagePath.substr(2);
        });
      }
    },
      res => {
        console.log("Error Message: " + res);
        // this.notifyService.showError("Info", "No Services Found!");
      });
  }


  onSubmitReachUsQuickly() {
    if (this.registerForm.valid) {
      this.submitForm = true;
      this.loading = true;
      this.danphecareservice.reachUsQuickly(this.DhcareRuq)
        .subscribe(res => this.SuccessPostReachUsQuickly(res),
          res => this.Error(res));
    }
    else {
      this.submitForm = true;
      this.loading = false;
      this.notifyService.showInfo("Info", "Please enter the required field");
      //this.registerForm.reset();

      (this.submitForm) ? setTimeout(() => { this.submitForm = false }, 5250) : null

    }
  }

  SuccessPostReachUsQuickly(res) {
    this.loading = false;
    this.DhcareRuq = new DanpheCareReachUsQuicklyModel();

    // this.GetAppliedUsrlist();
    this.notifyService.showSuccess('success', 'application submitted successfully ');
    this.submitForm = false;
    this.reCAPTCHAstatus = false;
    this.registerForm.reset();
    //  this.reCAPTCHAstatusRUQ = false;

    //this.CaptchaCallback();
  }

  Error(res) {
    var response = res;
    this.submitted = false;
    this.submitted_ruq = false;
    this.loading = false;
    this.loading_ruq = false;
    this.notifyService.showError("Error", " Please fill up the required field")
  }
  get PhoneNumberControl() {
    return this.registerForm.get("phonenumber");
  }
  get EmailControl() {
    return this.registerForm.get("email");
  }
  get NameControl() {
    return this.registerForm.get("name");
  }
}
