

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { WebsiteService } from '../websiteservice/website.service';

import { DanpheCareContact } from '../../cms/models/danphecare.cms.model';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { DanpheCareReachUsQuicklyModel } from '../../cms/models/danphecare/danphecare.model';
import { DanphecareService } from '../services/danphe.service';
import { NotificationService } from '../services/notification.service';
import { MetaService } from '@ngx-meta/core';
/*import { trigger } from '@angular/animations';*/


@Component({
  selector: 'app-hamropatra-page',
  templateUrl: './hamropatra.component.html',
  styleUrls: ["./hamropatra.component.css"]
})
export class HamroPatroComponent implements OnInit, AfterViewInit {
  public DhcareRuq: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public contact: DanpheCareContact = new DanpheCareContact();
  public loading: boolean = false;
  public submitted: boolean = false;
  public isCompany: boolean = false;
  public form: FormGroup;
  constructor(public websiteService: WebsiteService, private notifyService: NotificationService, private formBuilder: FormBuilder,
    public danphecareservice: DanphecareService, private meta: MetaService) {
    //
  }

  ngOnInit() {
    this.meta.setTitle("An initiative by Danphe Care and Hamro Patro");
    this.meta.setTag("description", "Committed to your Health and Wellbeing. Now offering tele medicine services for covid patients");
    this.meta.setTag('og:title', "An initiative by Danphe Care and Hamro Patro");
    this.meta.setTag('og:image', "https://cms.danphecare.com/assets/images/hamropatrosocialimage.jpg");
    this.meta.setTag('og:image:width', "800");
    this.meta.setTag('og:image:height', "800");
    this.meta.setTag('og:url', location.href);
    this.meta.setTag('og:type', "Hamro Patro");
    this.meta.setTag('og:description', "Committed to your Health and Wellbeing. Now offering tele medicine services for covid patients");
    this.meta.setTag('twitter:card', location.href);
    this.meta.setTag('twitter:title', "An initiative by Danphe Care and Hamro Patro");
    this.meta.setTag('twitter:description', "Committed to your Health and Wellbeing. Now offering tele medicine services for covid patients");
    this.meta.setTag('twitter:image', "https://cms.danphecare.com/assets/images/hamropatrosocialimage.jpg");
    this.meta.setTag('og:app_id', "260103905625004");   
    this.GetContact();

    this.form = this.formBuilder.group({
      phone: ['', [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)],
      ],
      //hospitalIdentifier: this.hospitalIdentifier != null ? this.hospitalIdentifier : "NA"
      //password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}')]],
      //confirmpassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}')]]
    });


  }
  ngAfterViewInit() {
    //
  }
  
  get PhoneNumberControl() {
    return this.form.get("phone") ;
  }
  get EmailControl() {
    return this.form.get("email") ;
  }
  GetContact() {
    this.websiteService.GetContact().subscribe(res => {
      if (res && res.length > 0) {
        this.contact = Object.assign(this.contact, res[0]);

      }
    },
      res => {
        //this.notifyService.showError("Error", "Internal Error")
      });
  }

  onSubmitReachUsQuickly() {

    if (this.form.valid) {
      this.loading = true;
      this.danphecareservice.reachUsQuickly(this.DhcareRuq)
        .subscribe(res => this.SuccessPostReachUsQuickly(res),
          res => this.Error(res));

    }
    else {
      this.loading = false;
      this.submitted = true;
      this.notifyService.showInfo("Info", "Please enter the required field");
      (this.submitted) ? setTimeout(() => { this.submitted = false }, 5250) : null
    }

  }
  SuccessPostReachUsQuickly(res) {
    this.loading = false;
    this.DhcareRuq = new DanpheCareReachUsQuicklyModel();
    this.notifyService.showSuccess('Success', 'application submitted successfully ');
    this.form.reset();
  }

  Error(res) {
    this.loading = false;
    const response = res;
    this.notifyService.showError("Error", " Please fill up the required field")
  }
}
