
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

//import { identifierName } from '@angular/compiler';

// import { AuthService } from '../../services/auth.service';
import { EndpointBase } from '../services/endpoint-base.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class TeleMedEndpoint extends EndpointBase {
  private readonly _teleMedUrl: string = "api"


  constructor( http: HttpClient) {
    super(http);
  }


  GetProductByIdEndpoint<T>(permaLink: number): Observable<T> {
    const endpointUrl = `${environment.telMedUrl}/${this._teleMedUrl}/product/GetProductById/${permaLink}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetProductByIdEndpoint(permaLink)));
      }));
  }
  GetSingleProductViewByIdEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${environment.telMedUrl}/${this._teleMedUrl}/product/GetSingleProductViewById/${id}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetSingleProductViewByIdEndpoint(id)));
      }));
  }


  GetProductPictureByIdEndpoint<T>(productId: number): Observable<T> {
    const endpointUrl = `${environment.telMedUrl}/${this._teleMedUrl}/product/GetProductPictureById/${productId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
      catchError(error => {
        return throwError(this.handleError(error, () => this.GetProductPictureByIdEndpoint(productId)));
      }));
  }



}
