
<div class="section page-top">
  <div class="ver-2 text-center">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="justify-content-center hero-text-center">
            <div class="hero-text mt-0"><b>News List</b></div>
            <h4></h4>
          </div>
        </div>
        <div class="col text-right">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="[ '/', 'homepage' ]">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">News List</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-4" *ngFor="let data of newsList">
        <div class="news-block">
          <figure class="image-box" (click)="GoToNewsDetails(data.newsId)">
            <img [src]="data.imagePath" alt="">
          </figure>
          <div class="lower-box">
            <h4><a routerLink="newsdetails" (click)="GoToNewsDetails(data.newsId)" >{{data.title}}</a></h4>
            <div class="news-date">
              <a routerLink="newsdetails" (click)="GoToNewsDetails(data.newsId)"><i class="fa fa-calendar"></i>{{data.createdDate | date:'mediumDate'}}</a>

            </div>
           <p style=" max-width: 225px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" [innerHTML]="data.content"></p>
            <a routerLink="newsdetails" (click)="GoToNewsDetails(data.newsId)" class="read-more">Read More</a>
          </div>
        </div>
      </div>
      
      
    </div>
  </div>
</section>

<style>
  .light-over-pages {
    position: absolute;
    background: linear-gradient(180deg, rgb(255 255 255 / 70%), rgb(189 189 189 / 40%));
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    top: 0;
    left: 0;
  }

  .list-style {
    padding: 0;
  }

    .list-style li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin: 5px 0;
    }

      .list-style li span {
        display: block;
      }

      .list-style li:before {
        content: "\f00c";
        font-family: FontAwesome;
        background-color: #0db696;
        color: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 15px;
        height: 15px;
        align-items: center;
        font-size: 0.7rem;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 0;
      }

  .home-isolation .sub-heading, .emmergency-info .sub-heading {
    font-weight: 700;
    font-size: 1.5rem;
  }

  .center-title {
  }

    .center-title h4 {
    }
</style>
