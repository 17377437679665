

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebsiteService } from '../websiteservice/website.service';

import {  DepartmentConsultation, TeamMember } from '../../cms/models/danphecare.cms.model';
import { NotificationService } from '../services/notification.service';


@Component({
  selector: 'core-team-profile-page',
  templateUrl: './core-team-profile.component.html'
})
export class CoreTeamProfileComponent implements OnInit, AfterViewInit {
  public profiledetailId: string;
  public profileId: number;
  public profile: TeamMember = new TeamMember();
  // public articlesList: Array<ResourcefulArticles> = new Array<ResourcefulArticles>();
  // public articlesId: number;
  public departmentList: Array<DepartmentConsultation> = new Array<DepartmentConsultation>();
  // public departmentId: number;
  //expert = require("../content/img/about-expert.png");
  constructor(
    public routing: Router,
    private route: ActivatedRoute,
    public websiteService: WebsiteService,
    private notifyService: NotificationService) {
    this.profiledetailId = this.route.snapshot.queryParamMap.get('id');
    this.profileId = parseInt(this.profiledetailId, 10);
  }

  ngOnInit() {
    this.profileId = parseInt(this.profiledetailId, 10);
    this.GetProfile(this.profileId);
    // this.GetArticles(this.profileId);
    // this.GetDepartment();
    //this.loadScripts();

  }
  ngAfterViewInit() {
    //this.loadScripts();
  }
  // loadScripts() {
  //   const dynamicScripts = [
  //     //'../content/js/jquery.min.js',
  //     './content/js/custom.js',
  //     './content/js/move-img-effect.js',
  //     './content/js/plugins.js',
  //     //'../content/js/popper.min.js',
  //     './content/js/slick.min.js'
  //   ];
  //   for (let i = 0; i < dynamicScripts.length; i++) {
  //     const node = document.createElement('script');
  //     node.src = dynamicScripts[i];
  //     node.type = 'text/javascript';
  //     node.async = false;
  //     node.charset = 'utf-8';
  //     document.getElementsByTagName('head')[0].appendChild(node);
  //   }
  // }
  GetProfile(id) {
    this.websiteService.GetCoreTeamMemberById(id)
      .subscribe(res => this.SuccessProfile(res),
        res => this.Error(res));
  }
  SuccessProfile(res) {
    this.profile = res;
    if (this.profile.coverPhoto !== null) {
      this.profile.coverPhoto = "assets" + this.profile.coverPhoto.substr(2);
    }
    if (this.profile.imagePath !== null) {
      this.profile.imagePath = "assets" + this.profile.imagePath.substr(2);
    }
   
  }
  Error(res) {
    this.notifyService.showError("Info", "No Profile Found!");
  }

  // GetArticles(id) {
  //   this.websiteService.GetArticlesById(id)
  //     .subscribe(res => this.SuccessArticles(res),
  //       res => this.ErrorArticles(res));
  // }
  // SuccessArticles(res) {
  //   this.articlesList = res;
  // }
  // ErrorArticles(res) {
  //   this.notifyService.showError("Info", "No Articles Found!");
  // }

  // GetDepartment() {
  //   this.websiteService.GetDepConsultation()
  //     .subscribe(res => this.SuccessDepartment(res),
  //       res => this.ErrorDepartment(res));
  // }
  // SuccessDepartment(res) {
  //   this.departmentList = res;
  // }
  // ErrorDepartment(res) {
  //   this.notifyService.showError("Info", "No Department Found!");
  // }


}
