

<body> 
  <!-- header navbar part -->
  <!-- body part -->
  <!-- hero section -->
  <!-- <div class="lower-header text-center p-0 m-0">
    <div class="top-message  d-none p-0 m-0">
      <p class="p-0 m-0">
        COVID-19 care, testing, and guidance. We're here to help. <a href="">Learn more</a> <i class="fas fa-chevron-right"></i>
      </p>
    </div>
  </div> -->
  <section class="content-section hero-section d-flex align-items-center pb-5">
    <div class="container ">
      <div class="row no-gutters w-100">
        <div class="col-md-7 d-flex align-items-center">
          <div class="banner-content">
            <h1>Your favorite <br /><span>online clinic.</span></h1>
            <div class="hero-list pt-md-4 pt-sm-2 pb-4">
              <ul>
                <li><i class="fas fa-check-circle mr-2"></i>Same-day treatment</li>
                <li><i class="fas fa-check-circle mr-2"></i>Board-certified practitioners</li>
                <li><i class="fas fa-check-circle mr-2"></i>Satisfaction guarantee</li>
              </ul>
            </div>
            <!-- <a href=""><button class="btn btn-primary d-none">Know More</button></a> -->
            <a href="#" class="btn btn-primary">Download Brochure</a>
          </div>
        </div>
        <div class="col-md-5 d-xs-none d-sm-none d-md-none d-lg-block d-xl-block ">
          <!-- <div class="hero-image">
              <img src="assets/image/hero-banner-image.png" alt="">
            </div> -->
          <div class="hero-signup-form hero-signup-form-large">
            <h2>Request for <span>Demo</span></h2>
            <form [formGroup]="form" (ngSubmit)="onSubmitReachUsQuickly()" novalidate #myForm="ngForm"class="mt-3">
              <div class="form-group">
                <i class="fas fa-user"></i>
                <input type="text" class="form-control " id="name" name="name" value="" [(ngModel)]="DhcareRuq.name" required placeholder="Enter your name *" formControlName="name" aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted && nameControl1.errors }">
                <div *ngIf="nameControl1.invalid && (nameControl1.dirty || nameControl1.touched || (nameControl1.untouched && submitted) )" class="alert-sm alert-danger">
                  <div *ngIf="nameControl1.errors.required">Name is required</div>
                </div>
              </div>
              <div class="email-mobile">
                <div class="form-group mr-2">
                  <i class="fas fa-phone-alt"></i>
                  <input type="text" class="form-control " id="phone" name="phone" [(ngModel)]="DhcareRuq.phone" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" required value="phone" placeholder="Enter your phone *" formControlName="phone" aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted && phoneControl1.errors }">
                  <div *ngIf="phoneControl1.invalid && (phoneControl1.dirty || (phoneControl1.untouched && submitted))" class="alert-sm alert-danger">
                    <div *ngIf="phoneControl1.errors.required">Phone Number is required</div>

                  </div>
            
                </div>
                <div class="form-group ml-2">
                  <i class="fas fa-building"></i>
                  <input type="text" class="form-control " id="company" name="company" [(ngModel)]="DhcareRuq.company"  required value="" placeholder="Enter your company *" formControlName="company" aria-describedby="emailHelp" >
              
                </div>
              </div>
              <div class="form-group">
                <i class="fas fa-envelope-open"></i>
                <input type="text" class="form-control " id="email" name="email" value="" [(ngModel)]="DhcareRuq.email" required placeholder="Enter your email *" formControlName="email" aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted && emailControl1.errors }">
                <div *ngIf="emailControl1.invalid && (emailControl1.dirty || emailControl1.touched || (emailControl1.untouched && submitted) )" class="alert-sm alert-danger">
                  <div *ngIf="emailControl1.errors.required">Email is required</div>
                </div>
              </div>
              <div class="form-group">
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" [(ngModel)]="DhcareRuq.message"
                          placeholder="Enter your message here"></textarea>
              </div>
              <div class="form-check d-flex align-items-center my-3">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <p class="form-check-label" for="exampleCheck1">I agree to your <a href="">terms & condition</a></p>
              </div>
              <div class="submit-final my-4">
                <button type="submit" class="btn btn-primary">
                 
                  <span *ngIf="loading" class="spinner-border" role="status">Requesting ...</span>
                  <span *ngIf="!loading"><i class="material-icons mdi mdi-message-outline"></i> Request For Demo</span>
                </button>
                <a href="">Our Payment Method</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="content-section d-md-block d-lg-none  align-items-center pb-0 pt-0 ">
    <div class="container d-flex justify-content-center">
      <div class="row no-gutters w-100">
        <div class="col-md-12 ">
          <!-- <div class="hero-image">
              <img src="assets/image/hero-banner-image.png" alt="">
            </div> -->
          <div class="hero-signup-form hero-signup-form-small">
            <h2>Request for <span>Demo</span></h2>
            <form [formGroup]="form1" (ngSubmit)="onSubmitReachUsQuickly1()" novalidate #myForm1="ngForm" class="mt-3">
              <div class="form-group">
                <i class="fas fa-user"></i>
                <input type="text" class="form-control " id="name" name="name" value="" [(ngModel)]="DhcareRuq1.name" required placeholder="Enter your name *" formControlName="name" aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted1 && nameControl2.errors }">
                <div *ngIf="nameControl2.invalid && (nameControl2.dirty || nameControl2.touched || (nameControl2.untouched && submitted1) )" class="alert-sm alert-danger">
                  <div *ngIf="nameControl2.errors.required">Name is required</div>
                </div>
              </div>
              <div class="email-mobile">
                <div class="form-group mr-2">
                  <i class="fas fa-phone-alt"></i>
                  <input type="text" class="form-control " id="phone" name="phone" [(ngModel)]="DhcareRuq1.phone" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" required value="phone" placeholder="Enter your phone *" formControlName="phone" aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted1 && phoneControl2.errors }">
                  <div *ngIf="phoneControl2.invalid && (phoneControl2.dirty || (phoneControl2.untouched && submitted1))" class="alert-sm alert-danger">
                    <div *ngIf="phoneControl2.errors.required">Phone Number is required</div>

                  </div>
                </div>
                <div class="form-group ml-2">
                  <i class="fas fa-building"></i>
                  <input type="text" class="form-control " id="company" name="company" [(ngModel)]="DhcareRuq1.company" required value="" placeholder="Enter your company *" formControlName="company" aria-describedby="emailHelp" >
                </div>
              </div>
              <div class="form-group">
                <i class="fas fa-envelope-open"></i>
                <input type="text" class="form-control " id="email" name="email" value="" [(ngModel)]="DhcareRuq1.email" required placeholder="Enter your email *" formControlName="email" aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted1 && emailControl2.errors }">
                <div *ngIf="emailControl2.invalid && (emailControl2.dirty || emailControl2.touched || (emailControl2.untouched && submitted1) )" class="alert-sm alert-danger">
                  <div *ngIf="emailControl2.errors.required">Email is required</div>
                </div>
              </div>
              <div class="form-group">
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" [(ngModel)]="DhcareRuq1.message"
                          placeholder="Enter your message here"></textarea>
              </div>
              <div class="form-check d-flex align-items-center my-3 text-center">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <p class="form-check-label" for="exampleCheck1">I agree to your <a href="">terms & condition</a></p>
              </div>
              <div class="submit-final my-4">
                <button type="submit" class="btn btn-primary">
                  <span *ngIf="loading1" class="spinner-border" role="status">Requesting ...</span>
                  <span *ngIf="!loading1"><i class="material-icons mdi mdi-message-outline"></i> Request For Demo</span>
                </button>
                <a href="">Our Payment Method</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- our services section -->
  <section class="content-section services-section">
    <div class="container">
      <div class="content-title text-center">
        <h2>Electronic Data Entry</h2>
      </div>
      <div class="row no-gutters pt-sm-0 pt-md-4">

        <div class="col-md-6 d-flex align-items-center">
          <div class="data-capturing-info">
            <div class="row">

              <div class="col-xs-6 col-sm-6 col-md-4 my-2">
                <div class="data-capturing-list text-center py-4 my-2">
                  <i class="fal fa-credit-card text-primary"></i>
                  <h6 class="mt-3">Payment Gateways</h6>
                </div>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 my-2">
                <div class="data-capturing-list text-center py-4 my-2">
                  <i class="fal fa-id-card fa-primary text-success"></i>
                  <h6 class="mt-3">Smart Card</h6>
                </div>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 my-2">
                <div class="data-capturing-list text-center py-4 my-2">
                  <i class="fal fa-barcode-read text-danger"></i>
                  <h6 class="mt-3">Barcode / RFID</h6>
                </div>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 my-2">
                <div class="data-capturing-list text-center py-4 my-2">
                  <i class="fas fa-fingerprint text-info"></i>
                  <h6 class="mt-3">Biometric</h6>
                </div>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 my-2">
                <div class="data-capturing-list text-center py-4 my-2">
                  <i class="fal fa-wifi text-secondary"></i>
                  <h6 class="mt-3">DICOM Compability</h6>
                </div>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-4 my-2">
                <div class="data-capturing-list text-center py-4 my-2">
                  <i class="fal fa-flask text-warning"></i>
                  <h6 class="mt-3">Laboratory Compability</h6>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-block">
          <div class="data-capturing-img">
            <img src="../assets/images/data-capturing.png" alt="">
          </div>
        </div>
      </div>

    </div>
  </section>


  <section class="content-section services-section bi-statics">
    <div class="container">
      <div class="content-title text-center">
        <h2>BI/Statics</h2>
      </div>

      <div class="row pt-sm-0 pt-md-4">
        <div class="col-md-6 d-none d-md-block">
          <div class="data-capturing-img">
            <img src="../assets/images/bi-statics.png" alt="">
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-center">
          <div class="data-capturing-info">
            <div class="row">

              <div class="col-xl-6 col-sm-6 col-md-6 my-2">
                <div class="data-capturing-list text-center py-4 my-2">
                  <i class="fas fa-users-cog text-primary"></i>
                  <h6 class="mt-3">Customized Dashboard</h6>
                </div>
              </div>
              <div class="col-xl-6 col-sm-6 col-md-6 my-2">
                <div class="data-capturing-list text-center py-4 my-2">
                  <i class="far fa-chart-bar text-danger"></i>
                  <h6 class="mt-3">Customized Statics</h6>
                </div>
              </div>
              <div class="col-xl-6 col-sm-6 col-md-6 my-2">
                <div class="data-capturing-list text-center py-4 my-2">
                  <i class="fal fa-edit text-info"></i>
                  <h6 class="mt-3">Customized Report</h6>
                </div>
              </div>
              <div class="col-xl-6 col-sm-6 col-md-6 my-2">
                <div class="data-capturing-list text-center py-4 my-2">
                  <i class="fal fa-weight text-success"></i>
                  <h6 class="mt-3">Performence Measurement</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>

  <!-- 35 plus  modules -->
  <section class="content-section d-flex align-items-center modules">
    <div class="container ">
      <div class="content-title text-center">
        <h2>35 plus Modules</h2>
      </div>

      <div class="sliding-part pt-sm-0 pt-md-4">
        <ngx-slick-carousel class="carousel slider" 
                                #slickModal="slick-carousel" 
                                [config]="slideConfig35" 
                                (init)="slickInit($event)">
                <div ngxSlickItem *ngFor="let slide of slides"  class="slide module-col-list">                     
                      <div class="module-list">
                        <span><i class="{{ slide.img }}" alt="" width="100%"></i></span>
                        <h4 class="px-1">{{ slide.title }}</h4>
                      </div>
                </div>
        </ngx-slick-carousel>
       

        <div class="slider autoplay" style="display: none;">
    <div class="module-col-list pt-2">
      <div class="module-list">
        <span><i class="fal fa-procedures text-primary"></i></span>
        <h4 class="px-1">Inpatient & ward</h4>
      </div>
      <div class="module-list">
        <span><i class="fas fa-user-injured text-danger"></i></span>
        <h4 class="px-1">ICU/CCU</h4>
      </div>
    </div>
    <div class="module-col-list pt-2">
      <div class="module-list">
        <span><i class="far fa-stethoscope text-warning"></i></span>
        <h4 class="px-1">Outpatient</h4>
      </div>
      <div class="module-list">
        <span><i class="fas fa-hospital-user text-secondary"></i></span>
        <h4 class="px-1">Departmental Integration</h4>
      </div>
    </div>
    <div class="module-col-list pt-2">
      <div class="module-list">
        <span><i class="fad fa-vials text-success"></i></span>
        <h4 class="px-1">Laboratory</h4>
      </div>
      <div class="module-list">
        <span><i class="fal fa-inventory text-info"></i></span>
        <h4 class="px-1">Inventory</h4>
      </div>
    </div>
    <div class="module-col-list pt-2">
      <div class="module-list">
        <span><i class="fas fa-x-ray text-primary"></i></span>
        <h4 class="px-1">Radiology Module</h4>
      </div>
      <div class="module-list">
        <span><i class="fal fa-file-invoice-dollar text-success"></i></span>
        <h4 class="px-1">General Account</h4>
      </div>
    </div>
    <div class="module-col-list pt-2">
      <div class="module-list">
        <span><i class="fal fa-ambulance text-info"></i></span>
        <h4 class="px-1">Emergency</h4>
      </div>
      <div class="module-list">
        <span><i class="fal fa-prescription-bottle-alt text-warning"></i></span>
        <h4 class="px-1">Pharmacy</h4>
      </div>
    </div>
    <div class="module-col-list pt-2">
      <div class="module-list">
        <span><i class="fad fa-file-medical-alt text-danger"></i></span>
        <h4 class="px-1">ADT</h4>
      </div>
      <div class="module-list">
        <span><i class="fad fa-money-check-alt text-info"></i></span>
        <h4 class="px-1">Insurance</h4>
      </div>
    </div>
    <div class="module-col-list pt-2">
      <div class="module-list">
        <span><i class="fad fa-file-medical-alt text-danger"></i></span>
        <h4 class="px-1">ADT</h4>
      </div>
      <div class="module-list">
        <span><i class="fad fa-money-check-alt text-info"></i></span>
        <h4 class="px-1">Insurance</h4>
      </div>
    </div>

  </div>

        <div class="slider-btn">
          <span class="prev"><i class="fal fa-chevron-circle-left"></i></span>
          <span class="next"><i class="fal fa-chevron-circle-right"></i></span>
        </div>
      </div>

    </div>
  </section>


  <!-- management system -->
  <section class="content-section management-system-section d-flex align-items-center">
    <div class="container">
      <div class="content-title text-center">
        <h2>Management Systems</h2>
      </div>
      <div class="management-system-wrapper center pt-sm-0 pt-md-4">

        <ngx-slick-carousel class="carousel slider" #slickModal="slick-carousel" [config]="slideConfigMS"
          (init)="slickInit($event)">
          <div ngxSlickItem class="slide">
            <div class="management-system-list">
              <div class="management-title custom-color-1">
                <h2 class="">Revenue Management</h2>
              </div>
              <div>
                <div class="function-list">
                  <ul>
                    <li><i class="fas fa-check-circle color-1"></i>Accounts and Payroll</li>
                    <li><i class="fas fa-check-circle color-1"></i>Inventory</li>
                    <li><i class="fas fa-check-circle color-1"></i>Pharmacy</li>
                    <li><i class="fas fa-check-circle color-1"></i>Central Store</li>
                    <li><i class="fas fa-check-circle color-1"></i>Leave</li>
                    <li><i class="fas fa-check-circle color-1"></i>Time Attendence</li>
                    <li><i class="fas fa-check-circle color-1"></i>Security and Administration</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>  
          <div ngxSlickItem class="slide">
          <div class="management-system-list">
            <div class="management-title custom-color-2">
              <h2 class="">Patient Management</h2>
            </div>
            <div>
              <div class="function-list">
                <ul>
                  <li><i class="fas fa-check-circle color-2"></i>Registration</li>
                  <li><i class="fas fa-check-circle color-2"></i>Appointment and Queue</li>
                  <li><i class="fas fa-check-circle color-2"></i>OPD/IDD ,Emergency </li>
                  <li><i class="fas fa-check-circle color-2"></i>ADT</li>
                  <li><i class="fas fa-check-circle color-2"></i>Leave</li>
                  <li><i class="fas fa-check-circle color-2"></i>Ward and Bed</li>
                  <li><i class="fas fa-check-circle color-2"></i>Billing</li>
                </ul>
              </div>
            </div>
          </div>
          </div>
        <div ngxSlickItem class="slide">
          <div class="management-system-list">
            <div class="management-title custom-color-3">
              <h2 class="">Clinical Support</h2>
            </div>
            <div>
              <div class="function-list">
                <ul>
                  <li><i class="fas fa-check-circle color-3"></i>Laboratory Integration</li>
                  <li><i class="fas fa-check-circle color-3"></i>System (LIS) </li>
                  <li><i class="fas fa-check-circle color-3"></i>PACS (Picture Archiving Communication System)</li>
                  <li><i class="fas fa-check-circle color-3"></i>Diagnostic Management</li>
                  <li><i class="fas fa-check-circle color-3"></i>OT Management</li>
                  <li><i class="fas fa-check-circle color-3"></i>EMR</li>
                  <li><i class="fas fa-check-circle color-3"></i>Clinical decision-making</li>
                </ul>
              </div>
            </div>
          </div>
          </div>
          <div ngxSlickItem class="slide">
            <div class="management-system-list">
              <div class="management-title custom-color-4">
                <h2 class="">Financial Accounting</h2>
              </div>
              <div>
                <div class="function-list">
                  <ul>
                    <li><i class="fas fa-check-circle color-4"></i>Accounts Payable Receivable</li>
                    <li><i class="fas fa-check-circle color-4"></i>Fixed Assets Accounting</li>
                    <li><i class="fas fa-check-circle color-4"></i>General Ledger</li>
                    <li><i class="fas fa-check-circle color-4"></i>Cost Center Accounting</li>
                    <li><i class="fas fa-check-circle color-4"></i>Doctors Accounting</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div ngxSlickItem class="slide">
            <div class="management-system-list">
              <div class="management-title custom-color-5">
                <h2 class="">HMIS Business Impact</h2>
              </div>
              <div>
                <div class="function-list">
                  <ul>
                    <li><i class="fas fa-check-circle color-5"></i>Lower Cost of Operations</li>
                    <li><i class="fas fa-check-circle color-5"></i>Future Forecasting</li>
                    <li><i class="fas fa-check-circle color-5"></i>Performance Monitoring</li>
                    <li><i class="fas fa-check-circle color-5"></i>Resources Optimization</li>
                    <li><i class="fas fa-check-circle color-5"></i>Research Analytics</li>
                    <li><i class="fas fa-check-circle color-5"></i>Increase Prfotability</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          </ngx-slick-carousel>
          

        


       


        


        


        

        <!-- <div class="slider-btn">
          <span class="prev"><i class="fal fa-chevron-circle-left"></i></span>
          <span class="next"><i class="fal fa-chevron-circle-right"></i></span>
        </div> -->

      </div>
    </div>
  </section>

  <!-- Clients -->
  <section class="content-section clients-section d-flex align-items-center clients happy bg-white" id="clients">
    <div class="container">
      <div class="content-title text-center">
        <h2>Our Clientele</h2>
      </div>
      <div class="client-wrapper pt-sm-0 pt-md-4">
        <div class="row">
          <div class="col-md-12">


            <ngx-slick-carousel class="carousel slider" #slickModal="slick-carousel" [config]="slideConfigHC"
              (init)="slickInit($event)">
              <div ngxSlickItem class="slide">
                <div>
                  <img src="../assets/images/clients/charak.png" class="img-fluid" alt="" data-aos="" data-aos-delay="">
                </div>
            </div>
            <div ngxSlickItem class="slide">
              <div>
                <img src="..//assets/images/clients/manmohan.png" class="img-fluid" alt="" data-aos="">
              </div>
            </div>
            <div ngxSlickItem class="slide">
            <div>
              <img src="../assets/images/clients/tilganga.png" class="img-fluid" alt="" data-aos="" data-aos-delay="">
            </div>
            </div>
            <div ngxSlickItem class="slide">
              <div>
                <img src="../assets/images/clients/mediplus.jpg" class="img-fluid" alt="" data-aos="" data-aos-delay="">
              </div>
            </div>
            <div ngxSlickItem class="slide">
              <div>
                <img src="../assets/images/clients/maya-metric.png" class="img-fluid" alt="" data-aos="" data-aos-delay="">
              </div>
            </div>
            <div ngxSlickItem class="slide">
            <div>
              <img src="../assets/images/clients/mark.png" class="img-fluid" alt="" data-aos="" data-aos-delay="">
            </div>
            </div>
            <div ngxSlickItem class="slide">
            <div>
              <img src="../assets/images/clients/manakamana.png" class="img-fluid" alt="" data-aos="">
            </div>
            </div>
            <div ngxSlickItem class="slide">
              <div>
                <img
                  src="../assets/images/clients/rythem.png" class="img-fluid" alt="" data-aos="" data-aos-delay="">
              </div>
            </div>
            <div ngxSlickItem class="slide">
            <div>
              <img src="../assets/images/clients/matrika.png" class="img-fluid" alt="" data-aos="" data-aos-delay="">
            </div>
            </div>
            <div ngxSlickItem class="slide">
              <div>
                <img src="../assets/images/clients/nepal-mediciti.png" class="img-fluid" alt="" data-aos="" data-aos-delay="">
              </div>
            </div>
            <div ngxSlickItem class="slide">
            <div>
              <img src="../assets/images/clients/budanilkantha.jpg" class="img-fluid" alt="" data-aos="" data-aos-delay="">
            </div>
            </div>
            <div ngxSlickItem class="slide">
              <div><img
                  src="../assets/images/clients/testimonial7.jpg" class="img-fluid" alt="" data-aos="" data-aos-delay="">
              </div>
            </div>
            <div ngxSlickItem class="slide">
            <div>
              <img src="../assets/images/clients/budanilkantha.jpg" class="img-fluid" alt="" data-aos="" data-aos-delay="">
            </div>
            </div>
            <div ngxSlickItem class="slide">
              <div><img
                  src="../assets/images/clients/testimonial7.jpg" class="img-fluid" alt="" data-aos="" data-aos-delay="">
              </div>
            </div>
            </ngx-slick-carousel>





            
          </div>
        </div>
      </div>
    </div>
  </section>
</body>
