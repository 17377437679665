
export class DanphecareModel {
  public userId: string;
  //public FirstName: string;
  //public MiddleName: string;
  //public LastName: string; 
  public name: string; 
  public message: string; 
  public phone: string;
  public email: string;
  public Service: string;
  public ServiceId : number;
  public Price : number;
  public PaymentProvider: string;
  public IsEmailSent: boolean;
  
}
export class DanpheCareReachUsQuicklyModel {
  public name: string;
  public email: string;
  public phone: number;
  public company: string;
  public message: string; 
}
