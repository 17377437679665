<style>
  button.slick-arrow {
    color: #fff !important;
    z-index: 999 !important;
    background: red !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
  }
</style>
<div id="content-wrapper">
  <div id="home-page">

    <section class="common-banner-section">
      <div class="common-banner-slider">
        <div class="common-banner-container slick-initialized slick-slider">
          <div aria-live="polite"
               class="slick-list draggable">
            <div class="slick-track"
                 role="listbox"
                 style="opacity: 1; width: 100%;">
              <div class="banner-item slick-slide slick-current slick-active"
                   data-slick-index="0"
                   aria-hidden="false"
                   tabindex="-1"
                   role="option"
                   aria-describedby="slick-slide00"
                   style="width: 100%; position: relative; left: 0px; top: 0px; z-index: 999; opacity: 1;">


                <div id="homepage-slider"
                     class="carousel slide"
                     data-ride="carousel">
                  <ol class="carousel-indicators">
                    <li data-target="#homepage-slider"
                        data-slide-to="0"
                        class="active"></li>
                    <li data-target="#homepage-slider"
                        data-slide-to="1"></li>
                    <li data-target="#homepage-slider"
                        data-slide-to="2"></li>
                    <li data-target="#homepage-slider"
                        data-slide-to="3"></li>
                    <li data-target="#homepage-slider"
                        data-slide-to="4"></li>
                  </ol>
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src="../../../../assets/img/homepage-slider-image/homepage-slider-img1.jpeg"
                           alt="main-image"
                           class="banner-image d-block w-100">
                      <div class="carousel-caption">
                        <h5>Dedicated to Wellbeing</h5>
                        <p>Every service we provide will be an experience for you.</p>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img src="../../../../assets/img/homepage-slider-image/homepage-slider-img2.jpg"
                           alt="main-image"
                           class="banner-image d-block w-100">
                      <div class="carousel-caption">
                        <h5>Dedicated to Wellbeing</h5>
                        <p>Every service we provide will be an experience for you.</p>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img src="../../../../assets/img/homepage-slider-image/homepage-slider-img3.jpeg"
                           alt="main-image"
                           class="banner-image d-block w-100">
                      <div class="carousel-caption">
                        <h5>Dedicated to Wellbeing</h5>
                        <p>Every service we provide will be an experience for you.</p>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img src="../../../../assets/img/homepage-slider-image/homepage-slider-img4.jpg"
                           alt="main-image"
                           class="banner-image d-block w-100">
                      <div class="carousel-caption">
                        <h5>Dedicated to Wellbeing</h5>
                        <p>Every service we provide will be an experience for you.</p>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <img src="../../../../assets/img/homepage-slider-image/homepage-slider-img5.jpg"
                           alt="main-image"
                           class="banner-image d-block w-100">
                      <div class="carousel-caption">
                        <h5>Dedicated to Wellbeing</h5>
                        <p>Every service we provide will be an experience for you.</p>
                      </div>
                    </div>
                  </div>
                  <a class="carousel-control-prev"
                     href="#homepage-slider"
                     role="button"
                     data-slide="prev">
                    <span class="carousel-control-prev-icon"
                          aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next"
                     href="#homepage-slider"
                     role="button"
                     data-slide="next">
                    <span class="carousel-control-next-icon"
                          aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-section">
      <div class="custom-container remove-bottom-padding">
        <div class="common-block-container">
          <div class="block-item">
            <div class="image-container">
              <img [src]="aboutUs.imagePath"
                   alt="about-us-image"
                   width="500"
                   height="750"
                   class="ccm-image-block img-responsive bID-45">
            </div>
          </div>
          <div class="block-item">
            <div class="section-title remove-margin pt-0">
              <h5>About Us</h5>
              <h2>Dedicated to</h2>
              <h2>Wellbeing</h2>
              <div class="content">
                <div [innerHTML]="aboutUs.content"></div>
                <div class="common-action">
                  <p><a class="co-btn"
                       [routerLink]="[ '/', 'aboutpage' ]">Know
                      More</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="doctor-section">
      <div class="custom-container remove-bottom-padding">
        <div class="common-block-container">
          <div class="block-item">
            <div class="section-title remove-margin pt-0">
              <h2>Know about<br>
                Your doctors
              </h2>
              <div class="content">
                <p>We develop health programs that give opportunities to become more
                  efficient and focused on your goals. We&nbsp;Care combines the finest
                  medical minds and advanced technology to provide holistic treatment
                  within a Multi-Disciplinary Framework.
                </p>
                <div class="common-action">
                  <p><a class="co-btn"
                       [routerLink]="[ '/', 'specialist' ]">Know More</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="block-item">
            <div class="image-container"
                 id="doctor-image">
              <img src="../../../../assets/img/pDoc1.png"
                   alt=""
                   width="720"
                   height="1060"
                   class="ccm-image-block img-responsive bID-46">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="services-section">
      <div class="custom-container">
        <div class="section-title type-white type-center type-full-width pt-0">
          <h5>Our Services</h5>
          <h2>OUR INTERNATIONAL STANDARD FACILITIES</h2>
        </div>
        <div class="services-container">
          <div class="sliding-part pt-sm-0 pt-md-4">
            <ngx-slick-carousel class="carousel slider"
                                #slickModal="slick-carousel"
                                [config]="slideConfig35"
                                (init)="slickInit($event)"
                                style="overflow: visible;">
              <div ngxSlickItem
                   *ngFor="let slide of slides"
                   class="slide module-col-list">
                <div class="module-list "> <span class="overflow-hidden"
                        style="    overflow: hidden;
                  width: 90% !important;
                  height: 400px !important;
                  border-radius: 40px !important;
                  border-top-left-radius: 0px !important;">
                    <img src="{{ slide.img }}"
                         [routerLink]="[ '/service', slide.permaLink ]"
                         alt="{{ slide.title }}"
                         width="100%"
                         style="min-height: 100%; min-width: 100%; object-fit: cover;" /></span>
                  <h4 class="px-1 text-white mt-4">{{ slide.title }}</h4>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </section>

    <section class="testimonial-section">
      <div class="custom-container">
        <div class="section-title type-small-margin">
          <h5>Testimonial</h5>
          <h2>OUR PATIENT&#39;S COMMENT</h2>
        </div>
        <div class="testimonial-container">
          <div class="testimonial-slider-section">
            <div class="testimonial-slider">
              <div class="testimonial-item">
                <ngx-slick-carousel class="carousel slider"
                                    #slickModal="slick-carousel"
                                    [config]="slideConfig35"
                                    (init)="slickInit($event)"
                                    style="overflow: visible;">
                  <div ngxSlickItem
                       *ngFor="let testimonial of testimonialList"
                       class="testimonial-item-containe slide module-col-list">
                    <div class="image-container">
                      <img src="{{ testimonial.imagePath }}"
                           alt="{{ testimonial.message }}"
                           width="100%"
                           style="min-height: 100%; min-width: 100%; object-fit: cover;">
                    </div>
                    <div class="section-content">
                      <p class="px-1 mt-4">{{ testimonial.message }}</p>
                      <h6>{{ testimonial.writerName }}</h6>
                    </div>
                  </div>
                </ngx-slick-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
