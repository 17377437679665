

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DanpheCareDoctor, MetaTag, Package, PackageDetails } from '../../cms/models/danphecare.cms.model';
import { HttpClient } from '@angular/common/http';
import { WebsiteService } from '../websiteservice/website.service';

import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { NotificationService } from '../services/notification.service';
import { MetaService } from '@ngx-meta/core';
import { state } from '@angular/animations';
import { data } from 'jquery';
import { TeleMedService } from '../websiteservice/teleMedService';
import { ProductPicture } from '../../cms/Products/ProductPicture.model';
import { ProductModel } from '../../cms/Products/Product.model';
import { PackageViewModel } from './package-view-model';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent implements OnInit, AfterViewInit {

  public packageDetailsList: Array<PackageViewModel> = new Array<PackageViewModel>();
  public productDeails: Array<ProductModel> = new Array<ProductModel>();
  public previewImgSrc: any;

  constructor(public http: HttpClient, public teleMedService: TeleMedService, public websiteService: WebsiteService, public notifyService: NotificationService, public router: Router, private titleService: Title,
    private meta: MetaService) {
    //
  }

  ngOnInit() {
    this.websiteService.GetPackageDetails().subscribe(res => {
      if (res) {

        this.packageDetailsList = [];
        this.packageDetailsList = Object.assign(this.packageDetailsList, res);
        
        
        // let ParentProductList: Array<ProductModel> = [];
        // ParentProductList = this.productDeails.filter(pro => pro.ParentGroupedProductId == 0 && pro.VisibleIndividually);

        // ParentProductList.forEach((parentPro, i) => {

        //   this.packageDetailsList[i].PackageDeatils.packageName = parentPro.Name;
        //   this.packageDetailsList[i].PackageDeatils.content = parentPro.FullDescription;
        //   this.packageDetailsList[i].PackageDeatils.PictureBinaryData = parentPro.PictureBinaryData;

        //   this.productDeails.forEach(pd => {
        //     if (pd.ParentGroupedProductId == parentPro.Id) {
        //       this.packageDetailsList[i].products.push(pd);
        //     }
        //   })

        // });

        // this.productDeails= Object.assign(this.productDeails, res);
        //this.productDeails.forEach(ProductPicture => {
        // ProductPicture= this.GetProductImage(this.productDeails.);

      }
    },
      err => {
        this.notifyService.showError("Info", "No Package Found!");
      });

  }
  ngAfterViewInit() {
    //this.loadScripts();
  }


  public ReadMore(permalink) {
    // this.teleMedService._productId = productId
    this.router.navigate(['/readmore', permalink]);
  }

  GoToSpecialistDetails(permalink) {

    this.router.navigate(['/profilepage', permalink]);
  }

  public GetProductImage(productImageId) {
    this.teleMedService.GetProductPictureById(productImageId).subscribe(
      res => {
        if (res) {
          var reader = new FileReader();
          for(var i=this.packageDetailsList.length; i>0; i--){
          this.previewImgSrc[i] = 'data:image/jpeg;base64,' + res;
        }
      }

      },
      err => {
        alert(err);
      }
    );
  }
}
