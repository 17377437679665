
export class AboutUsModel {
    public aboutUsId: number;
    public title: string ;
    public content: string;
    public shortDescription: string; 
    public imagePath: string; 
    public isImagePathUploaded: boolean;
    public metaTitle: string;
    public metaContent: string; 
    public metaKeywords: string; 
}
