import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { DanphecareEndpoint } from './danphecare-endpoint.service';
import { DanphecareModel, DanpheCareReachUsQuicklyModel } from '../../cms/models/danphecare/danphecare.model';
import { BookPackageModel } from '../buy-package/book-package.model';
import { HomeSampleCollectionForm } from '../interface/home-sample-collection-form';
import { Base64ImageString } from '../interface/base64Image';
import { HealthAtHomeRequestViewModel } from '../interface/HealthAtHomeRequestViewModel';




@Injectable()
export class DanphecareService {
  constructor(private router: Router, private http: HttpClient, private danphecareEndPoint: DanphecareEndpoint) {
  }

  GetAppliedUsrlist() {
    return this.danphecareEndPoint.GetAppliedUsrlist<DanphecareModel[]>();
  }

  applytoIsolation(danphecare: DanphecareModel) {
    return this.danphecareEndPoint.applytoIsolation<DanphecareModel>(danphecare);
  }
  reachUsQuickly(danphecareruq: DanpheCareReachUsQuicklyModel) {
    return this.danphecareEndPoint.reachUsQuickly<DanpheCareReachUsQuicklyModel>(danphecareruq);
  }
  buyPackage(userAndPackageDeatils: BookPackageModel) {
    return this.danphecareEndPoint.BuyPackage<BookPackageModel>(userAndPackageDeatils);
  }

  requestHealthAtHome(request: HealthAtHomeRequestViewModel){
    return this.danphecareEndPoint.RequestHealthAtHome<HealthAtHomeRequestViewModel>(request);
  }
}
