import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { OurServiceModel } from '../../cms/models/OurServices.model';
import { WebsiteEndpoint } from './website-endpoint.service';
import { AboutUsModel } from '../../cms/models/aboutUs.model';
import { DanpheCareDepartment, DanpheCareDoctor, DepartmentConsultation, ResourcefulArticles, News, Blogs, MetaTag, DanpheCareContact, Expat, Webinar, TeamMember, TestimonialMain, Testimonial, MediaCoverage, DepartmentTabcontent, DepartmentSubHeading, PackageDetails, SubServices, SubServicesDetails } from '../../cms/models/danphecare.cms.model';
import { ProductModel } from '../../cms/Products/Product.model';
import { TeleMedEndpoint } from './teleMed-endpoint.service';
import { SingleProductViewModel } from '../single-product/single-product.model';

@Injectable()
export class TeleMedService {
  constructor(private router: Router, private http: HttpClient, private teleMedEndPoint: TeleMedEndpoint) {
  }


  GetProductPictureById(permaLink?: number) {
    return this.teleMedEndPoint.GetProductPictureByIdEndpoint<OurServiceModel>(permaLink);
  }



  //#region Danphe Department

  GetProductById(permalink?: number) {
    return this.teleMedEndPoint.GetProductByIdEndpoint<ProductModel>(permalink);
  }
  GetSingleProductViewById(id: number) {
    return this.teleMedEndPoint.GetSingleProductViewByIdEndpoint<SingleProductViewModel>(id);
  }


}
