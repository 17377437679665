

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { News } from '../../cms/models/danphecare.cms.model';
import { HttpClient } from '@angular/common/http';
import { WebsiteService } from '../websiteservice/website.service';

import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';


@Component({
  selector: 'app-news-page',
  templateUrl: './news.component.html'
})
export class NewsPageComponent implements OnInit, AfterViewInit {

  public newsList: Array<News> = new Array<News>();
  public newsId: number;
  constructor(public http: HttpClient, public websiteService: WebsiteService, public notifyService: NotificationService, public router: Router) {
    //
  }

  ngOnInit() {
    this.GetNews();
    //this.loadScripts();

  }
  ngAfterViewInit() {
    //this.loadScripts();
  }
  loadScripts() {
    const dynamicScripts = [
      //'../content/js/jquery.min.js',
      './content/js/custom.js',
      './content/js/move-img-effect.js',
      './content/js/plugins.js',
      //'../content/js/popper.min.js',
      './content/js/slick.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  GetNews() {
    this.websiteService.GetNews().subscribe(res => {
      if (res) {
        this.newsList = [];
        this.newsList = Object.assign(this.newsList, res);
        this.newsList.forEach((item, index) => {
          item.imagePath = "assets" + item.imagePath.substr(2);
        });
      }
    },
      res => {
        this.notifyService.showError("Info", "No News Found!");
      });
  }
  GoToNewsDetails(id) {
    this.newsId = id;
    this.router.navigate(['/newsdetails'], { queryParams: { id: this.newsId } });


  }
}
