import { Component, OnInit } from '@angular/core';
import { AboutUsModel } from '../../cms/models/aboutUs.model';
import { WebsiteService } from '../websiteservice/website.service';

import { DanpheCareContact } from '../../cms/models/danphecare.cms.model';
import { NotificationService } from '../services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingServiceService } from '../websiteservice/loadingService/loading-service.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class DanpheFooterComponent implements OnInit {

  public aboutUs: AboutUsModel = new AboutUsModel();
  public form: FormGroup;
  public loading: boolean = false;
  public contact: DanpheCareContact = new DanpheCareContact();
  contactUsData: any;

  constructor(public websiteService: WebsiteService, private notifyService: NotificationService, private formBuilder: FormBuilder, private loadingService: LoadingServiceService) {
    this.GetAboutUs();
    this.GetContact();
  }
  ngOnInit() {
    this.GetAboutUs();
    this.GetContact();
    this.form = this.formBuilder.group({
      ContactNumber: ['', [Validators.required]]
    });
  }
  GetAboutUs() {
    this.websiteService.GetAboutUs().subscribe(res => {
      if (res && res.length > 0) {
        this.aboutUs = Object.assign(this.aboutUs, res[0]);

      }
    },
      res => {
        //this.notifyService.showError("Error", "Internal Error")
      });
  }
  GetContact() {
    this.websiteService.GetContact().subscribe(res => {
      if (res && res.length > 0) {
        this.contact = Object.assign(this.contact, res[0]);

      }
    },
      res => {
        //this.notifyService.showError("Error", "Internal Error")
      });
  }
  DropContactNumber(form) {
    if (form.valid) {
      this.loading = true;
      this.loadingService.requestStarted();
      // this.contactUsData = Object.assign(this.contactUsData, contactForm.value);
      this.contactUsData = form.value;
      this.websiteService.PostContactNumber(this.contactUsData).subscribe(
        res => {
          if (res) {
            this.notifyService.showSuccess("We will contact you shortly. ", "Successfully Submitted");
            form.reset();
          }
          else {
            this.notifyService.showSuccess("Unable to Post your number", "Error")

          }
          this.loadingService.requestEnded();
          this.loading = false;
        },
        err => {
          this.loadingService.requestEnded();
          this.loading = false;
          this.notifyService.showError(err, "Error");
        });
    }
  }
}
