<div id="content-wrapper">

  <div id="contact-page">

    <section class="contact-section">
      <div class="custom-container">
        <div class="section-title main-title">
          <h5>
            Get in touch with us
          </h5>
          <h1>
            Contact
          </h1>
          <div class="content">
            <p>


            <p>Your message will be most welcome. Fill out the form and send your doubts, criticisms and
              suggestions for us.</p>


          </div>
        </div>

        <div class="section-content">
          <div class="block-container">
            <div class="block-item">
              <div class="content-container">
                <ul>
                  <li>
                    <h4>
                      Email
                    </h4>
                    <p>
                      <a href="mailto:{{contact.email}}"></a><a href="mailto:{{contact.email}}" style="color: #7b7b7b;">{{contact.email}}</a>
                    </p>

                  </li>
                  <li>
                    <h4>
                      Phone No
                    </h4>
                    <p>
                      <a href="tel:{{contact.phone}}"></a> <a href="tel:{{contact.phone}}" style="color: #7b7b7b;">{{contact.phone}}</a>
                    </p>

                  </li>
                  <li>
                    <h4>
                      Opening hours
                    </h4>
                    <p>
                      Sunday to Friday: 7AM to 7PM
                    </p>
                    <p>
                      Saturday: 7AM to 2PM
                    </p>

                  </li>
                  <li>
                    <h4>
                      Address
                    </h4>


                    <p>Bhagwati bahal,</p>

                    <p>Naxal, Kathmandu</p>

                  </li>
                </ul>
              </div>
            </div>
            <div class="block-item">
              <div class="form-container">
                <div class="content-container">
                  <div class="common-form">



                    <div id="formblock490" class="ccm-block-type-form">
                      <form enctype="multipart/form-data" [formGroup]="form" class="form-stacked miniSurveyView" id="miniSurveyView490" method="post" (submit)="SendMail(form)">
                        <!-- <form enctype="multipart/form-data" class="form-stacked miniSurveyView" id="miniSurveyView490" method="post" action="http://wecarehealthcenter.org/contact/submit_form/490#formblock490"> -->
                        <!-- <input type="hidden" name="ccm_token" value="1629187191:ca013cf452ba2d986634f7f895d8e321" /> -->
                        <div class="common-form">

                          <div class="fields">

                            <div data-placeholder="Full Name" class="form-group field field-text ">
                              <input placeholder="Full Name" [attr.disabled]="loading ?  true : null" name="Question1" id="Question1" class="form-control" formControlName="Name" type="text" value="" />
                            </div>
                            <div data-placeholder="Email" class="form-group field field-email ">
                              <input placeholder="Email" [attr.disabled]="loading ?  true : null" name="Question2" id="Question2" class="form-control" formControlName="Email" type="email" value="" />
                            </div>
                            <div data-placeholder="Address" class="form-group field field-text ">
                              <input placeholder="Address" [attr.disabled]="loading ?  true : null" name="Question3" id="Question3" class="form-control" formControlName="Address" type="text" value="" />
                            </div>
                            <div data-placeholder="Phone No" class="form-group field field-text ">
                              <input placeholder="Phone No" [attr.disabled]="loading ?  true : null" name="Question4" id="Question4" class="form-control" formControlName="ContactNumber" type="text" value="" />
                            </div>
                            <div data-placeholder="Subject For Message" class="form-group field field-text ">
                              <input placeholder="Subject For Message" [attr.disabled]="loading ?  true : null" name="Question5" id="Question5" formControlName="Subject" class="form-control" type="text" value="" />
                            </div>
                            <div data-placeholder="Message" class="form-group field field-textarea ">
                              <textarea placeholder="Message" [attr.disabled]="loading ?  true : null" name="Question6" class="form-control" formControlName="Message" id="Question6" cols="50" rows="3"></textarea>
                            </div>

                          </div><!-- .fields -->


                          <div *ngIf="!loading" class="form-action co-btn type-white type-input">
                            <input *ngIf="!loading" [attr.disabled]="loading ?  true : null" type="submit" name="Submit" class="" value="Send" />
                          </div>
                          <div *ngIf="loading" style="margin-block: -2rem;">
                            <app-loading></app-loading>
                          </div>


                        </div>

                        <input name="qsID" type="hidden" value="1617517949" />
                        <input name="pURI" type="hidden" value="" />

                      </form>
                    </div><!-- .formblock -->

                    <!-- <script data-cfasync="false"
                      src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
                    <script>

                      var inputsGroups = document.querySelectorAll('.fields > .field');

                      inputsGroups.forEach(inputsGroup => {

                        var placeholders = inputsGroup.dataset.placeholder;
                        var childs = inputsGroup.children[0];
                        childs.setAttribute('placeholder', placeholders);

                      });

                    </script> -->

                  </div>
                  <!-- <div class="common-form">
                        <h4>
                            Send Us A Message
                        </h4>
                        <div class="fields">
                            <div class="flex-field form-group">
                                <input type="text" placeholder="Full Name">
                            </div>
                            <div class="flex-field form-group">
                                <input type="text" placeholder="Address">
                            </div>
                            <div class="flex-field form-group">
                                <input type="text" placeholder="Phone No">
                            </div>
                            <div class="flex-field form-group">
                                <input type="email" placeholder="Email Address">
                            </div>
                            <div class="form-group">
                                <input type="text" placeholder="Subject For Message">
                            </div>
                            <div class="form-group">
                                <textarea placeholder="Message"></textarea>
                            </div>
                        </div>
                        <div class="form-action">
                            <a type="submit" class="co-btn">Send</a>
                        </div>
                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="map-section">


      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14128.659142507537!2d85.330104!3d27.712198!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x575ea5e9caaa2309!2sWecare%20Health%20Center!5e0!3m2!1sen!2snp!4v1620904645466!5m2!1sen!2snp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </section>

  </div>





</div>