

import { Component, OnInit, AfterViewInit } from '@angular/core';

import { WebsiteService } from '../websiteservice/website.service';


import { Title } from '@angular/platform-browser';
import { AboutUsModel } from '../../cms/models/aboutUs.model';
import { MetaTag } from '../../cms/models/danphecare.cms.model';
import { NotificationService } from '../services/notification.service';
import { MetaService } from '@ngx-meta/core';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-about-page',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutUsPageComponent implements OnInit, AfterViewInit {
  // expert = require("../content/img/about-expert.png");
  public aboutUs: AboutUsModel = new AboutUsModel();
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public tag: MetaTag = new MetaTag();
  public content: string;

  constructor(public websiteService: WebsiteService, private notifyService: NotificationService, private titleService: Title, private  meta: MetaService) {
    //
  }

  ngOnInit() {
    this.websiteService.GetAboutUs().subscribe(res => {
      if (res && res.length > 0) {
        this.aboutUs = Object.assign(this.aboutUs, res[0]);
        this.aboutUs.imagePath = environment.url + this.aboutUs.imagePath.substr(2);
        //this.content = "";
        //this.content = this.aboutUs.content.replace(/(<([^>]+)>)/gi, "");
        //this.content = this.content.slice(0, 150);
        this.meta.setTitle(this.aboutUs.title);
        this.meta.setTag("description", this.aboutUs.metaContent);
        this.meta.setTag("keywords", this.aboutUs.metaKeywords);
        this.meta.setTag('og:title', this.aboutUs.title);
        this.meta.setTag('og:image', this.aboutUs.imagePath);
        this.meta.setTag('og:image:width', "800");
        this.meta.setTag('og:image:height', "600");
        this.meta.setTag('og:url', location.href);
        this.meta.setTag('og:type', "About");
        this.meta.setTag('og:description', this.aboutUs.metaContent);
        this.meta.setTag('twitter:card', location.href);
        this.meta.setTag('twitter:title', this.aboutUs.title);
        this.meta.setTag('twitter:description', this.aboutUs.metaContent);
        this.meta.setTag('twitter:image', this.aboutUs.imagePath);
        this.meta.setTag('og:app_id', "260103905625004");
      }
    },
      res => {
        //this.notifyService.showError("Error", "Internal Error")
      });



  }
  ngAfterViewInit() {

    //this.loadScripts();
  }



}
