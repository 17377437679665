<div id="content-wrapper">


    <div id="single-services-page" class="category-page">

    <section class="content-section">
        <div class="custom-container remove-bottom-padding">
          <div class="section-title type-full-width type-small-margin main-title">
            <h5>
              Dedicated to Wellbeing
            </h5>
            <h1>
              {{subServicesDetails.title}}
           </h1>
  
            <div class="content">
  
              <p>{{subServicesDetails.introduction}}</p>
  
            </div>
          </div>
  
          <div class="section-content">
            <div class="image-container">
              <img [src]="subServicesDetails.imagePath" alt="{{subServicesDetails.imagePath}}" width="1260"
                height="600">
            </div>
            <div class="content">
              <p [innerHTML]="subServicesDetails.content"></p>
            </div>
          </div>
        </div>
      </section>
      </div>
      </div>
