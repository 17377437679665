import { Component } from "@angular/core";

@Component({
  selector: 'app-message-from-ceo',
  templateUrl: './message-from-ceo.component.html',
  styleUrls: ['./message-from-ceo.component.css']
})
export class MessageFromCeoComponent {

}
