<section class="newsletter-section">
  <div class="custom-container remove-top-bottom-padding">
    <div class="newsletter-container">
      <div class="block-container">
        <div class="block-item">
          <div class="section-title">
            <h4>DROP YOUR PHONE NUMBER<br>
              WE WILL CALL YOU&nbsp;BACK
            </h4>
          </div>
          <div class="content-container">
            <p>We will call you back for your inquires.</p>
          </div>
        </div>
        <div class="block-item">
          <div id="formblock488" class="ccm-block-type-form">
            <!-- <form enctype="multipart/form-data" class="form-stacked miniSurveyView" id="miniSurveyView488" method="post" (submit)="DropContactNumber()"> -->
            <form [formGroup]="form" (ngSubmit)="DropContactNumber(form)">
              <input type="hidden" name="ccm_token" value="1629017747:06f96b0a9978f5eaa860544119dfdd3b">
              <div class="common-form">
                <div class="fields">
                  <div data-placeholder="Phone Number" class="form-group field field-telephone ">
                    <input name="Question7" [attr.disabled]="loading ?  true : null" id="Question7" class="form-control" type="tel" formControlName="ContactNumber" placeholder="Phone Number">
                  </div>
                </div>
                <!-- .fields -->
                <div *ngIf="!loading" class="form-action co-btn type-input">
                  <input *ngIf="!loading" [attr.disabled]="loading ?  true : null" type="submit" name="Submit" class="" value="Submit">
                </div>
                <div *ngIf="loading">
                  <app-loading style="margin-block: -100px !important;"></app-loading>
                </div>
              </div>
              <input name="qsID" type="hidden" value="1617599371">
              <input name="pURI" type="hidden" value="">
              <!-- <div style="margin-block: -64px;"> -->

              <!-- </div> -->
            </form>
          </div>
          <!-- .formblock -->
          <script>
            var inputsGroups = document.querySelectorAll('.fields > .field');

            inputsGroups.forEach(inputsGroup => {

              var placeholders = inputsGroup.dataset.placeholder;
              var childs = inputsGroup.children[0];
              childs.setAttribute('placeholder', placeholders);

            });

          </script>
          <!-- <div class="common-form">
                        <div class="fields">
                            <div class="form-group">
                                <input type="email" placeholder="Email Address">
                            </div>
                        </div>
                        <div class="form-action">
                            <input type="image" src="/application/themes/_imagio_c../img/icons/right-arrow-green.svg" alt="Submit">
                        </div>
                        </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
<footer id="footer-wrapper">
  <section class="footer-widgets">
    <div class="custom-container">
      <div class="block-container">
        <div class="block-item">
          <div class="title">
            <p>Get in touch with us</p>
          </div>
          <div class="content">
            <table aria-label="contact us detail">
              <tr>
                <th scope="col">Phone No</th>
                <td>{{contact.phone}}</td>
              </tr>
              <tr>
                <th scope="col">Email</th>
                <td>{{contact.email}}</td>
              </tr>
              <tr>
                <th scope="col">Location</th>
                <td>Bhagwatibahal, Naxal, Kathmandu</td>
              </tr>
              <tr style="vertical-align: text-top;">
                <th scope="col">Opening Hours</th>
                <td>7AM to 7PM (Sun-Fri) <br> 7AM to 2PM (Sat)</td>
              </tr>
            </table>
            <div class="social">
              <ul>
                <li>
                  <a class="facebook" href="https://www.facebook.com/wecare.healthcenternp" target="_blank">
                    <i class="fab fa-facebook-f" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a class="instagram" href="https://www.instagram.com/wecare.healthcenter/" target="_blank">
                    <i class="fab fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a class="youtube" href="https://www.youtube.com/channel/UCioxFUUi6-jVbvFwSk0zYHQ/featured" target="_blank">
                    <i class="fab fa-youtube" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="block-item">
          <div class="image-container">
            <a [routerLink]="[ '/' ]"><img src="../../../../assets/img/logo_final.png" alt="" width="140" height="123" class="ccm-image-block img-responsive bID-251"></a>
          </div>
        </div>
        <div class="block-item">
          <div class="title">
            <p>Get Wecare eCLINIC</p>
          </div>
          <div class="content">
            <p>Download our eCLINIC App to book your appointments at your finger tips</p>
            <ul>
              <li><a [routerLink]="[ '/', '' ]" type="_blank"><img src="../../../../assets/img/logo-google-play.png"></a>
              </li>
              <li><a [routerLink]="[ '/', '' ]" type="_blank"><img src="../../../../assets/img/logo-app-store.png"></a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </section>
  <section class="footer-copyright">
    <div class="custom-container">
      <p>Copyright © 2022 imark pvt.ltd. Website by <a href="https://www.imarkdigital.com/" target="_blank">imark pvt. ltd</a> </p>
    </div>
  </section>
</footer>
<!---------------------------------- Footer Wrapper Ends ----------------------------------->