import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { OurServiceModel } from '../../cms/models/OurServices.model';
import { WebsiteEndpoint } from './website-endpoint.service';
import { AboutUsModel } from '../../cms/models/aboutUs.model';
import { DanpheCareDepartment, DanpheCareDoctor, DepartmentConsultation, ResourcefulArticles, News, Blogs, MetaTag, DanpheCareContact, Expat, Webinar, TeamMember, TestimonialMain, Testimonial, MediaCoverage, DepartmentTabcontent, DepartmentSubHeading, PackageDetails, SubServices, SubServicesDetails, LabEquipment } from '../../cms/models/danphecare.cms.model';
import { Career } from '../../cms/models/career.model/career.model';
import { ContactUsModel } from '../../cms/models/contactUs/contactUs.model';
import * as _ from 'lodash';

@Injectable()
export class WebsiteService {
  constructor(private router: Router, private http: HttpClient, private websiteEndPoint: WebsiteEndpoint) {
  }
  GetAboutUs() {
    return this.websiteEndPoint.GeAboutUsEndpoint<AboutUsModel[]>();
  }

  GetServicesById(permaLink?: string) {
    return this.websiteEndPoint.GetServiceByIdEndpoint<OurServiceModel>(permaLink);
  }
  GetOurServices() {

    return this.websiteEndPoint.GetOurServicesEndpoint<OurServiceModel[]>();
  }


  //#region Danphe Department

  GetDepartmentById(permalink?: string) {
    return this.websiteEndPoint.GetDepartmentByIdEndpoint<DanpheCareDepartment>(permalink);
  }

  GetDepartment() {
    return this.websiteEndPoint.GetDepartmentEndpoint<DanpheCareDepartment[]>();
  }
  GetSubHeadingByDepartmentId(permalink?: string) {
    return this.websiteEndPoint.GetSubHeadingByDepartmentIdEndpoint<DepartmentSubHeading>(permalink);
  }
  GetSubHeadingByDoctorId(permalink?: string) {
    return this.websiteEndPoint.GetSubHeadingByDoctorIdEndpoint<DepartmentSubHeading>(permalink);
  }
  //#endregion Danphe Department

  //#region Danphe Doctor
  GetDoctorById(permalink?: string) {
    return this.websiteEndPoint.GetDoctorByIdEndpoint<DanpheCareDoctor>(permalink);
  }
  GetDoctor() {
    return this.websiteEndPoint.GetDoctorEndpoint<DanpheCareDoctor[]>();
  }
  GetDoctorSorting() {
    return this.websiteEndPoint.GetDoctorSortingEndpoint<DanpheCareDoctor[]>();
  }
  //#endregion Danphe Doctor

  //#region Department Consultation
  GetConsultationById(ConsultationId?: number) {
    return this.websiteEndPoint.GetConsultationByIdEndpoint<DepartmentConsultation>(ConsultationId);
  }
  GetDepConsultation() {
    return this.websiteEndPoint.GetDepConsultationEndpoint<DepartmentConsultation[]>();
  }

  //#endregion Department Consultation

  //#region Articles
  GetArticlesById(ArticlesId?: number) {
    return this.websiteEndPoint.GetArticlesByIdEndpoint<ResourcefulArticles>(ArticlesId);
  }
  GetArticlesId(ArticlesId?: number) {
    return this.websiteEndPoint.GetArticlesIdEndpoint<ResourcefulArticles>(ArticlesId);
  }
  GetArticles() {
    return this.websiteEndPoint.GetArticlesEndpoint<ResourcefulArticles[]>();
  }

  //#endregion Articles

  //#region News
  GetNewsById(NewsId?: number) {
    return this.websiteEndPoint.GetNewsByIdEndpoint<News>(NewsId);
  }
  GetNews() {
    return this.websiteEndPoint.GetNewsEndpoint<News[]>();
  }

  //#endregion News

  //#region Blogs
  GetBlogsById(permaLink?: string) {
    return this.websiteEndPoint.GetBlogsByIdEndpoint<Blogs>(permaLink);
  }
  GetBlogs() {
    return this.websiteEndPoint.GetBlogsEndpoint<Blogs[]>();
  }

  //#endregion Blogs

  //#region MetaTag

  GetMetaTagById(MetaTagId?: number) {
    return this.websiteEndPoint.GetMetaTagByIdEndpoint<MetaTag>(MetaTagId);
  }

  GetMetaTag() {
    return this.websiteEndPoint.GetMetaTagEndpoint<MetaTag[]>();
  }

  //#endregion Blogs

  //#region DanpheCareContact
  GetContact() {
    return this.websiteEndPoint.GetContactEndpoint<DanpheCareContact[]>();
  }

  //#endregion Blogs

  //#region Expat
  GetExpat() {
    return this.websiteEndPoint.GetExpatEndpoint<Expat[]>();
  }

  //#endregion Expat

  //#region Webinar
  GetWebinar() {
    return this.websiteEndPoint.GetWebinarEndpoint<Webinar[]>();
  }

  //#endregion Webinar

  //#region GET Our Team Members
  GetOurTeamMembers() {
    return this.websiteEndPoint.GetOurTeamMembersEndpoint<TeamMember[]>();
  }
  GetCoreTeamMembers() {
    return this.websiteEndPoint.GetCoreTeamMembersEndpoint<TeamMember[]>();
  }
  GetSorting() {
    return this.websiteEndPoint.GetSortingEndpoint<TeamMember[]>();
  }
  GetCoreTeamMemberById(id?: number) {
    return this.websiteEndPoint.GetCoreTeamByIdEndpoint<TeamMember>(id);
  }
  //#endregion

  //#region Our Media Coverage
  GetOurMediaCoverage() {
    return this.websiteEndPoint.GetOurMediaCoverageEndpoint<MediaCoverage[]>();
  }
  //#endregion

  //#region Testimonials
  GetTestimonials() {
    return this.websiteEndPoint.GetTestimonialsEndpoint<Testimonial[]>();
  }
  //#endregion

  //#region Testimonial Main
  GetTestimonialMain() {
    return this.websiteEndPoint.GetTestimonialMainEndpoint<TestimonialMain>();
  }
  //#endregion

  //#region Department Details
  GetDepartmentTabContentById(permaLink?: string) {
    return this.websiteEndPoint.GetDepartmentTabContentByIdEndpoint<DepartmentTabcontent>(permaLink);
  }
  GetServiceByDepartmentId(permalink?: string) {
    return this.websiteEndPoint.GetServiceByDepartmentIdEndpoint<OurServiceModel>(permalink);
  }

  GetDoctorByDepartmentId(permalink?: string) {
    return this.websiteEndPoint.GetDoctorByDepartmentIdEndpoint<DanpheCareDoctor>(permalink);
  }
  GetArticlesByDepartmentId(DepartmentId?: number) {
    return this.websiteEndPoint.GetArticlesByDepartmentIdEndpoint<ResourcefulArticles>(DepartmentId);
  }

  //#endregion Department Details

  GetPackageDetails() {
    return this.websiteEndPoint.GetPackageDetailsEndpoint<PackageDetails[]>();
  }

  GetSubServicesById(id?: number) {
    return this.websiteEndPoint.GetSubServicesByIdEndpoint<SubServices>(id);
  }

  GetSubServicesDetailsById(id?: number) {
    return this.websiteEndPoint.GetSubServicesDetailsByIdEndpoint<SubServicesDetails>(id);
  }

  GetCareerDetailsList() {
    return this.websiteEndPoint.GetCareerDetailsEndpoint<Career[]>();
  }

  GetCareerDetails(permaLink: number) {
    return this.websiteEndPoint.GetCareerDetailEndpoint<Career[]>((permaLink));
  }
  GetLabEquipmentList() {
    return this.websiteEndPoint.GetLabEquipmentsEndpoint<LabEquipment[]>();
  }

  GetLabEquipmentsByServiceId(permaLink: number) {
    return this.websiteEndPoint.GetLabEquipmentByServiceIdEndpoint<LabEquipment[]>((permaLink));
  }
  PostContactDetils(contactDetail: ContactUsModel) {
    let contactData = _.omit(contactDetail, ['form']);
    let queryDetails = JSON.stringify(contactData);
    return this.websiteEndPoint.PostContactDetilsEndpoint<ContactUsModel>(queryDetails);
  }
  PostContactNumber(contactDetail: ContactUsModel) {
    let queryDetails = JSON.stringify(contactDetail);
    return this.websiteEndPoint.PostContactNumberEndpoint<ContactUsModel>(queryDetails);
  }
}
