
<section class="blog-wrapper blog-detail-wrap p-0">
  <div class="blog-img" *ngIf="news !=null">
    <img [src]="news.imagePath" alt="">
    <div class="blog-top-content">
      <div class="container">
        <h2>{{news.title}}</h2>
        <div class="post">
          <ul>
            <li>
              <a href="#">
                <!--<ion-icon name="time-outline"></ion-icon>-->
                <i class="fa fa-clock-o"></i>
                {{news.createdDate | date:'mediumDate'}}
              </a>
            </li>
            <li>
              <a href="#">
                <!--<ion-icon name="chatbubbles-outline"></ion-icon>-->
                <i class="fa fa-comment"></i>
                5 Comments
              </a>
            </li>
            <li>
              <a href="#">
                <!--<ion-icon name="person-outline"></ion-icon>-->
                <i class="fa fa-user"></i>
                Posted by {{news.postedBy}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--<div class="container">

    <div class="post" *ngIf="news !=null">
      <ul>
        <li>
          <a href="#">
          <ion-icon name="time-outline"></ion-icon>
          {{news.createdDate | date:'mediumDate'}}
          </a>
        </li>
        <li>
          <a href="#">
            <ion-icon name="chatbubbles-outline"></ion-icon>
            5 Comments
          </a>
        </li>
        <li>
          <a href="#">
          <ion-icon name="person-outline"></ion-icon>
          Posted by: {{news.postedBy}}
          </a>
        </li>
      </ul>
    </div>
  </div>-->
  <div class="container">
    <div class="row">
      <!--<h4 *ngIf="news !=null">{{news.title}}</h4>-->
      <div class="col-lg-12">
        <div class="blog-post blog-detail pt-5 pb-5">
          <div class="blog-content" *ngIf="news !=null">
            <div [innerHTML]="news.content"></div>
          </div>
          <div class="social-share">
            <b>Share: </b>
            <ul>
              <li><a href="#" class="facebook"><i class="fa fa-facebook"></i></a></li>
              <li><a href="#" class="twitter"><i class="fa fa-twitter"></i></a></li>
              <li><a href="#" class="pinterest"><i class="fa fa-pinterest"></i></a></li>
              <li><a href="#" class="google-plus"><i class="fa fa-google-plus"></i></a></li>
            </ul>
          </div>
        </div>
        <!--<div class="review-section cmt-section">
      <h3 class="review-title">Comments</h3>
      <div class="comment-text mb-5">
        <div class="user-icon"><i class="fa fa-user"></i></div>
        <div class="comment-description">
          <div class="clearfix">
            <p class="comment-name">Jhone Doe</p>
            <p class="review__awaiting-approval"><i class="fa fa-calendar-alt"></i> Jun 12, 2019</p>
          </div>
          <div class="description">
            <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
          </div>
        </div>
        <div class="comment-text mt-3">
          <div class="user-icon"><i class="fa fa-user"></i></div>
          <div class="comment-description">
            <div class="clearfix">
              <p class="comment-name">Jhone Doe</p>
              <p class="review__awaiting-approval"><i class="far fa-calendar-alt"></i> Jun 12, 2019</p>
            </div>
            <div class="description">
              <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
            </div>
          </div>
        </div>
      </div>
      <div class="review-form-wrapper">
        <form action="">
          <div class="form-group">
            <textarea id="comment" class="form-control" name="comment" cols="45" rows="4" required="" placeholder="Write a comment..."></textarea>
          </div>
          <p class="form-submit">
            <input name="submit" type="submit" id="submit" class="submit btn btn-default" value="Submit">
          </p>
        </form>
      </div>
    </div>-->
      </div>
    </div>
  </div>
</section>

<style>
  .light-over-pages {
    position: absolute;
    background: linear-gradient(180deg, rgb(255 255 255 / 70%), rgb(189 189 189 / 40%));
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    top: 0;
    left: 0;
  }

  .list-style {
    padding: 0;
  }

    .list-style li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin: 5px 0;
    }

      .list-style li span {
        display: block;
      }

      .list-style li:before {
        content: "\f00c";
        font-family: FontAwesome;
        background-color: #0db696;
        color: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 15px;
        height: 15px;
        align-items: center;
        font-size: 0.7rem;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 0;
      }

  .home-isolation .sub-heading, .emmergency-info .sub-heading {
    font-weight: 700;
    font-size: 1.5rem;
  }

  .center-title {
  }

    .center-title h4 {
    }
</style>
