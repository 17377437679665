
<section class="profile-section pt-0 pb-3">
  <div class="cover-img" > <a href="#"><img  [src]="profile.coverPhoto" alt=""></a> </div>
  <div class="container">
    <div class="profile-img" *ngIf="profile.coverPhoto == null" style="margin-top:160px"> <img [src]="profile.imagePath" alt=""> </div>
    <div class="profile-img" *ngIf="profile.coverPhoto != null" > <img [src]="profile.imagePath" alt=""> </div>
    <div class="profile-content">
      <div class="row">
        <div class="col-md-8">
          <h2>{{profile.fullName}}</h2>
          <p>{{profile.designation}}</p>
          <p>{{profile.department}} </p>
          <p>{{profile.shortDescription}}</p>
        </div>
        <!--<div class="col-md-4">
        <div class="text-right">
          <button type="button" class="btn btn-success">

            Book Appointment<span>No Booking Fee</span>
          </button>
        </div>
      </div>-->
      </div>
    </div>
  </div>
</section>
<section class="detail-section">
  <div class="container">
    <div class="row">
      <div class="col-md-12" [innerHTML]="profile.content">

      </div>
      </div>
    </div>
</section>
    <!--Profile Section end-->
    <!-- <section class="detail-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-heading" style="max-width: inherit; margin: 0 0 45px 0;">
              <h1 class="section-title w-100 text-left font-weight-bold">You can consult on this </h1>
            </div>
          </div>
          <div class="consult row">
            <div class="col-md-4" *ngFor="let data of departmentList">
              <div class="service-content-item">
                <div class="item-title">
                  <div class="service-content-icon"> <img [src]="data.IconPath" alt=""> </div>
                  <h3><a routerLink="">{{data.DepartmentName}}</a></h3>
                </div>
                <p>{{data.Title}}</p>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section> -->
    <!--	Resources Articles-->
    <!-- <section>
      <div class="container">
        <div class="section-title">
          <h2 class="font-weight-bold">Resourceful articles </h2>
        </div>
        <div class="row">
          <div class="col-md-3" *ngFor="let data of articlesList">
            <div class="section-title small stats aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
              <div class="stat-img">
                <img [src]="data.ImagePath">
                <div class="stat-value">
                  <h1>{{data.Title}}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    
    <style>
      .light-over-pages {
        position: absolute;
        background: linear-gradient(180deg, rgb(255 255 255 / 70%), rgb(189 189 189 / 40%));
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 2;
        top: 0;
        left: 0;
      }

      .list-style {
        padding: 0;
      }

        .list-style li {
          list-style: none;
          position: relative;
          padding-left: 25px;
          margin: 5px 0;
        }

          .list-style li span {
            display: block;
          }

          .list-style li:before {
            content: "\f00c";
            font-family: FontAwesome;
            background-color: #0db696;
            color: #fff;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 15px;
            height: 15px;
            align-items: center;
            font-size: 0.7rem;
            border-radius: 50%;
            position: absolute;
            top: 3px;
            left: 0;
          }

      .home-isolation .sub-heading, .emmergency-info .sub-heading {
        font-weight: 700;
        font-size: 1.5rem;
      }

      .center-title {
      }

        .center-title h4 {
        }
    </style>
