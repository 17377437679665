<!-- <div class="ccm-page ccm-page-id-1 page-type-page page-template-full" id="wrapper-outer">
    <div id="wrapper">
      <div id="single-product">
        <section class="single-product-page">
          <div class="custom-container pt-0">
            <div class="row">


              <div class="col-md-7 ">
                <div class="product-item-details pr-5">
                  <div>
                    <div class="section-title-wrapper d-flex justify-content-between align-items-center mt-3">
                      <div>
                        <h2>{{t}}</h2>
                      </div>
                    </div>
                  </div>

                  <div class="product-desc pt-2 mt-3 position-relative">
                    <hr>
                    <p>{{this.careerDetails.Description}}</p>

                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="product-image">
                  <img [src]="careerDetails.PicturePath">
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12">
                <div class="buy-now-section">
                  <div class="row no-gutters h-100">
                    <div class="col-md-3 m-auto">
                      <div class="item-name-rate d-flex ml-3">
                        <div class="hexagon hexagon2 ">
                          <div class="hexagon-in1">
                            <div class="hexagon-in2 bg-primary d-flex align-items-center justify-content-center">
                              <div class="product-image-wrapper"><img [src]="careerDetails" width="120" height="85" style=" margin-left: 2500%; margin-bottom: 50px;" />
                                {{t}} </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div></div>

   -->

<div id="content-wrapper">


  <div id="single-doctor-page">


    <section>
      <div class="custom-container" style="margin-top: 9.2rem;">
        <div class="block-container">
          <div class="block-item">
            <div class="image-container">
              <img [src]="previewImgSrc" width="100" height="95">
            </div>
          </div>
          <div class="block-item">
            <div class="title-container">
              <div class="section-title type-small-margin">
                <h3>{{careerDetails.title}}</h3>
              </div>
            </div>
            <div class="content-container">
              <h6><strong>Qualification / Experience: &nbsp;&nbsp;&nbsp;</strong>{{careerDetails.qualification}}</h6>


              <h6><strong>Description :</strong></h6>
              <p [innerHTML]="careerDetails.description"><strong>.</strong></p>

              <h6><strong>Deadline:</strong>{{careerDetails.closingDate}}</h6>
            </div>
            <button
            title="This is a preview feature for Apply. Please contact admin."
             class="applyNow">
            <i class="far fa-hand-point-right"></i> Apply Now
        </button>
          </div>
        </div>
      </div>
    </section>

  </div>





</div>
