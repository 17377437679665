<style>
  button.slick-arrow {
    color: #fff !important;
    z-index: 999 !important;
    background: red !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
  }

  .slick-initialized .slick-slide {
    margin: 0.5rem;
  }

  .slick-initialized .slick-slide img {
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    border: 1px solid #ECECEC;
  }

  .service-section {
    margin-bottom: 5rem;
  }
</style>
<div id="content-wrapper">
  <div id="single-services-page" class="category-page">
    <section class="content-section">
      <div class="custom-container remove-bottom-padding">
        <div class="section-title type-full-width type-small-margin main-title">
          <h5>
            Dedicated to Wellbeing
          </h5>
          <h1>
            {{services.serviceName}}
          </h1>
          <div class="content">
            <p [innerHTML]="services.shortIntroduction"></p>
          </div>
        </div>
        <div class="section-content">
          <div class="image-container" *ngIf="!isHealthAtHomeService">
            <img [src]="services.imagePath" alt="{{services.imagePath}}" width="1260" height="600">
          </div>
          <div class="content">
            <p [innerHTML]="services.content"></p>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="services.serviceName == 'Radiology And Imaging Science'" class="why-choose-section add-margin-top add-margin-bottom">
      <div class="custom-container">
        <div class="section-title type-white type-center type-full-width pt-0">
          <h5>We Care</h5>
          <h2>Why Choose Us</h2>
        </div>
        <div class="section-content">
          <div class="common-steps">
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/radiology1.png">
                </div>
                <div class="text-container">
                  <h5>Accurate diagnosis leading to the right treatment plan</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/radiology2.png">
                </div>
                <div class="text-container">
                  <h5>Enhanced patient comfort with minimum exposure to radiation</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/radiology3.png">
                </div>
                <div class="text-container">
                  <h5>Focused high frequency radiation beam leading to Low Radiation Exposure</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/radiology4.png">
                </div>
                <div class="text-container">
                  <h5>Enhanced safety due to reduced radiation exposure time</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/radiology5.png">
                </div>
                <div class="text-container">
                  <h5>Real time reporting</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="services.serviceName == 'Diagnostic Pathology Lab'" class="why-choose-section add-margin-top add-margin-bottom">
      <div class="custom-container">
        <div class="section-title type-white type-center type-full-width pt-0">
          <h5>We Care</h5>
          <h2>Why Choose Us</h2>
        </div>
        <div class="section-content">
          <div class="common-steps">
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/pathology1.png">
                </div>
                <div class="text-container">
                  <h5>Efficient and highly trained pathology team</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/pathology2.png">
                </div>
                <div class="text-container">
                  <h5>Accurate results leading to right treatment plan</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/pathology3.png">
                </div>
                <div class="text-container">
                  <h5>Report generation within 24 hours for most of the tests</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/pathology4.png">
                </div>
                <div class="text-container">
                  <h5>Easy Access to online reports</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/pathology5.png">
                </div>
                <div class="text-container">
                  <h5>Provision of sample collection at home</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="services.serviceName == 'Health And Wellness'" class="why-choose-section add-margin-top add-margin-bottom">
      <div class="custom-container">
        <div class="section-title type-white type-center type-full-width pt-0">
          <h5>We Care</h5>
          <h2>Why Choose Us</h2>
        </div>
        <div class="section-content">
          <div class="common-steps">
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/wellness1.png">
                </div>
                <div class="text-container">
                  <h5>Highly efficient and modern technology that gives accurate results</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/wellness2.png">
                </div>
                <div class="text-container">
                  <h5>Consultation with expert doctors having specialization in their field</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/wellness3.png">
                </div>
                <div class="text-container">
                  <h5>We care coordinator helps organize your care inside and outside the clinic</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="services.serviceName == 'Health At Home'" class="why-choose-section add-margin-top add-margin-bottom">
      <div class="custom-container">
        <div class="section-title type-white type-center type-full-width pt-0">
          <h5>We Care</h5>
          <h2>Why Choose Us</h2>
        </div>
        <div class="section-content">
          <div class="common-steps">
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/healthhome1.png">
                </div>
                <div class="text-container">
                  <h5>Easy to book through user friendly and user guided mobile app and secure website.</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/healthhome2.png">
                </div>
                <div class="text-container">
                  <h5>Easy and convenient online payment system.</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/healthhome3.png">
                </div>
                <div class="text-container">
                  <h5>In-house pharmacy</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/healthhome4.png">
                </div>
                <div class="text-container">
                  <h5>Telemedicine consultation for professional consultation and advice.</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/healthhome5.png">
                </div>
                <div class="text-container">
                  <h5>Follow universal precaution of infection prevention</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/healthhome6.png">
                </div>
                <div class="text-container">
                  <h5>Clinical expertise at home</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="services.serviceName == 'General And Specialized Dental Services'" class="why-choose-section add-margin-top add-margin-bottom">
      <div class="custom-container">
        <div class="section-title type-white type-center type-full-width pt-0">
          <h5>We Care</h5>
          <h2>Why Choose Us</h2>
        </div>
        <div class="section-content">
          <div class="common-steps">
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/dental1.png">
                </div>
                <div class="text-container">
                  <h5>Single day/ single visit dentistry for your convenience.</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/dental1.png">
                </div>
                <div class="text-container">
                  <h5>Separate kid’s play zone to keep your little ones busy while you get your treatment stress-free.
                  </h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/dental1.png">
                </div>
                <div class="text-container">
                  <h5>A caring team of professionals who work together for best results.</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/dental1.png">
                </div>
                <div class="text-container">
                  <h5>A highly trained support team for making the patient journey as comfortable as possible.</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/dental1.png">
                </div>
                <div class="text-container">
                  <h5>All facilities under the same roof for your convenience.</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/dental1.png">
                </div>
                <div class="text-container">
                  <h5>Latest and best technology gadgets for CBCT, lasers, and dental X-Rays.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="services.serviceName == 'Telemedicine'" class="why-choose-section add-margin-top add-margin-bottom">
      <div class="custom-container">
        <div class="section-title type-white type-center type-full-width pt-0">
          <h5>We Care</h5>
          <h2>Why Choose Us</h2>
        </div>
        <div class="section-content">
          <div class="common-steps">
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/tele1.png">
                </div>
                <div class="text-container">
                  <h5>Single day/ single visit dentistry for your convenience.</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/tele1.png">
                </div>
                <div class="text-container">
                  <h5>Single sign-in with user access controls</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/tele2.png">
                </div>
                <div class="text-container">
                  <h5>Convenient quality care from a variety of licensed healthcare professionals</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/tele3.png">
                </div>
                <div class="text-container">
                  <h5>Access to online report and prescription</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/tele4.png">
                </div>
                <div class="text-container">
                  <h5>Evaluation of test reports by attending physician</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/tele5.png">
                </div>
                <div class="text-container">
                  <h5>Medicine delivery at your door step</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/tele6.png">
                </div>
                <div class="text-container">
                  <h5>Easy and quick online payment system</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/tele7.png">
                </div>
                <div class="text-container">
                  <h5>Data safety and confidentiality via high security cloud storage.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="services.serviceName == 'OPD Service'" class="why-choose-section add-margin-top add-margin-bottom">
      <div class="custom-container">
        <div class="section-title type-white type-center type-full-width pt-0">
          <h5>We Care</h5>
          <h2>Why Choose Us</h2>
        </div>
        <div class="section-content">
          <div class="common-steps">
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/opd1.png">
                </div>
                <div class="text-container">
                  <h5>Expertise consultations with access to the latest treatment technology available</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/opd2.png">
                </div>
                <div class="text-container">
                  <h5>We care coordinator helps organize your care inside and outside the clinic</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/opd3.png">
                </div>
                <div class="text-container">
                  <h5>Complete care ranging from disease prevention to complex and chronic condition management.</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/opd4.png">
                </div>
                <div class="text-container">
                  <h5>Telemedicine consultations making it convenient for you to get medical advice from the comfort of
                    your home.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="services.serviceName == 'OPD Service'" class="why-choose-section add-margin-top add-margin-bottom">
      <div class="custom-container">
        <div class="section-title type-white type-center type-full-width pt-0">
          <h5>We Care</h5>
          <h2>Why Choose Us</h2>
        </div>
        <div class="section-content">
          <div class="common-steps">
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/opd1.png">
                </div>
                <div class="text-container">
                  <h5>Easy to book through user friendly and user guided mobile app and secure website.</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/opd2.png">
                </div>
                <div class="text-container">
                  <h5>Medicine delivery at your doorstep</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/opd3.png">
                </div>
                <div class="text-container">
                  <h5>Free weight, blood pressure and temperature checking</h5>
                </div>
              </div>
            </div>
            <div class="steps-item">
              <div class="inner">
                <div class="image-container">
                  <img src="../../../../assets/icons/opd4.png">
                </div>
                <div class="text-container">
                  <h5>Counselling by highly trained and qualified pharmacists</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="service-section">
      <div class="custom-container remove-top-padding" *ngIf="subServices?.length">
        <div class="section-title type-smaller-margin">
          <h6>Services</h6>
        </div>
        <div class="section-content">
          <div class="common-service-container">
            <div class="service-item" *ngFor="let data of subServices">
              <div class="image-container">
                <a [routerLink]="['/subservices', data.subServicesId]">
                  <img [src]="data.imagePath" alt="{{data.imagePath}}" width="400" height="260"> </a>
              </div>
              <div class="text-container">
                <h5><a>{{data.title}}</a></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="service-section">
      <div class="custom-container remove-top-padding">
        <div class="section-title type-smaller-margin">
          <h6>Lab Equipments</h6>
        </div>
        <div class="section-content">
          <div class="common-service-container">
            <div class="sliding-part pt-sm-0 pt-md-4">
              <ngx-slick-carousel class="carousel slider" #slickModal="slick-carousel" [config]="slideConfig35" (init)="slickInit($event)" style="overflow: visible;">
                <div ngxSlickItem *ngFor="let slide of labEquipmentsOfService" class="slide module-col-list">
                  <div class="module-list ">
                    <img src="{{ slide.imagePath }}" alt="{{ slide.name }}" width="100%" style="min-height: 100%; min-width: 100%; object-fit: cover;" />
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="lab-equipments" *ngIf="labEquipmentsOfService?.length">
      <div class="custom-container remove-top-padding">
        <div class="section-title type-smaller-margin">
          <h6>Lab Equipments</h6>
        </div>
        <div class="section-content">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of labEquipmentsOfService" class="slide">
              <img src="{{ slide.imagePath }}" alt="slide.name" width="100%">
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </section>
  </div>
</div>