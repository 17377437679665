
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DanphecareService } from '../services/danphe.service';
import { NotificationService } from '../services/notification.service';
import { LoadingServiceService } from '../websiteservice/loadingService/loading-service.service';

@Component({
  selector: 'app-book-now',
  templateUrl: './book-now.component.html',
  styleUrls: ['./book-now.component.css']
})
export class BookNowComponent implements OnInit {

  @Input() PackageName = '';

  @Output() closeButtonEmmitter: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("closeModal") closeModal: ElementRef;

  public loading: boolean = false;
  public bookNowForm: FormGroup;

  public isFullNameError = false;
  public fullNameErrorText = '';

  public isAgeError = false;
  public ageErrorText = '';

  public isGenderError = false;
  public genderErrorText = '';

  public isPhoneNumberError = false;
  public phoneNumberErrorText = '';

  public isEmailError = false;
  public emailErrorText = '';
  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private danphecareservice: DanphecareService,
    private loadingService: LoadingServiceService
  ) { }

  ngOnInit(): void {
    this.bookNowForm = this.formBuilder.group({
      FullName: ['', [Validators.required]],
      Age: ['', [Validators.required, Validators.max(100)]],
      Gender: ['', Validators.required],
      PhoneNumber: ['', Validators.required],
      Email: ['', [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)]],
      Attender: [''],
      Message: ['']
    })
  }

  get FullName() {
    return this.bookNowForm.controls.FullName;
  }
  get Age() {
    return this.bookNowForm.controls.Age;
  }
  get Gender() {
    return this.bookNowForm.controls.Gender;
  }
  get PhoneNumber() {
    return this.bookNowForm.controls.PhoneNumber;
  }
  get Email() {
    return this.bookNowForm.controls.Email;
  }
  get Attender() {
    return this.bookNowForm.controls.Attender;
  }

  get Message() {
    return this.bookNowForm.controls.Message;
  }

  resetError() {
    this.isFullNameError = false;
    this.fullNameErrorText = '';

    this.isAgeError = false;
    this.ageErrorText = '';

    this.isGenderError = false;
    this.genderErrorText = '';

    this.isPhoneNumberError = false;
    this.phoneNumberErrorText = '';

    this.isEmailError = false;
    this.emailErrorText = '';

    this.closeModal.nativeElement.click();
  }

  SendMail(formDetails) {
    if (formDetails.valid) {
      console.log("i am ok here.")
    }
  }

  changeGender(event) {
    const gender = event.target.value;
    this.bookNowForm.controls.Gender.patchValue(gender);
  }

  checkNull() {
    let isNull = false;
    if (this.FullName.value == null || this.FullName.value.trim() == '') {
      this.isFullNameError = true;
      this.fullNameErrorText = "Full Name is required!"
      isNull = true;
      return isNull;
    } else {
      this.isFullNameError = false;
      this.fullNameErrorText = "";
    }

    if (this.Age.value == null || this.Age.value == '') {
      this.isAgeError = true;
      this.ageErrorText = "Age is required!"
      isNull = true;
      return isNull;
    } else {
      this.isAgeError = false;
      this.ageErrorText = "";
    }

    if (this.Gender.value == null || this.Gender.value.trim() == '') {
      this.isGenderError = true;
      this.genderErrorText = "Gender is required!"
      isNull = true;
      return isNull;
    } else {
      this.isGenderError = false;
      this.genderErrorText = "";
    }

    if (this.PhoneNumber.value == null || this.PhoneNumber.value.trim() == '') {
      this.isPhoneNumberError = true;
      this.phoneNumberErrorText = "Phone number is required!"
      isNull = true;
      return isNull;
    } else {
      this.isPhoneNumberError = false;
      this.phoneNumberErrorText = "";
    }

    if (this.Email.value == null || this.Email.value.trim() == '') {
      this.isEmailError = true;
      this.emailErrorText = "Email is required!"
      isNull = true;
      return isNull;
    } else {
      this.isEmailError = false;
      this.emailErrorText = "";
    }
    return isNull;
  }

  checkAgeError() {
    if (this.Age.value < 0) {
      this.isAgeError = true;
      this.ageErrorText = "Age cannot be negative";
    }
    if (this.Age.value >= 101) {
      this.isAgeError = true;
      this.ageErrorText = "Age cannot be more than 100";
    }
    if (this.Age.value == 0) {
      this.isAgeError = true;
      this.ageErrorText = "Age cannot be 0";
    }
    return this.isAgeError;
  }

  handleSubmit() {
    if (this.checkNull()) {
      this.notificationService.showError("Please fill the required fields.", "Error!");
      return;
    }
    if (this.checkAgeError()) {
      this.notificationService.showError(this.ageErrorText, "Error!");
      return;
    }
    if (this.Email.invalid) {
      this.notificationService.showError("Please enter a valid email!", "Error!");
      return;
    }
    //call api to send the form and packageName;
    this.loading = true;
    this.loadingService.requestStarted();
    this.danphecareservice.buyPackage(this.bookNowForm.value).subscribe(res => {
      if (res) {
        this.loadingService.requestEnded();
        this.SuccessPost();
        this.closePopup();
      }
      else {
        this.loadingService.requestEnded();
        this.Error(res);
      }
      this.loading = false;
    },
      err => {
        this.loadingService.resetSpinner();
        this.loading = false;
        this.Error(err);
      })
  }

  closePopup(){
    this.closeButtonEmmitter.emit(false);
  }

  SuccessPost() {
    this.loading = false;
    this.notificationService.showSuccess('Success', 'application submitted successfully ');
    this.closeModal.nativeElement.click();

  }
  Error(err) {
    this.loading = false;
    this.notificationService.showError("Error", "Something went wrong please check console for details");
    console.log(err);
  }

}
