

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TeamMember } from '../../cms/models/danphecare.cms.model';
import { WebsiteService } from '../websiteservice/website.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-team-page',
  templateUrl: './team.component.html'
})
export class TeamPageComponent implements OnInit, AfterViewInit {
  ourTeamMemberList: Array<TeamMember> = new Array<TeamMember>();
  profileId: number;

  constructor(public websiteService: WebsiteService, public router: Router) {
   this.GetTeamMembers();
  }

  ngOnInit() {

    //this.loadScripts();

  }
  ngAfterViewInit() {
    //this.loadScripts();
  }
  loadScripts() {
    const dynamicScripts = [
      //'../content/js/jquery.min.js',
      './content/js/custom.js',
      './content/js/move-img-effect.js',
      './content/js/plugins.js',
      //'../content/js/popper.min.js',
      './content/js/slick.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
  
  GetTeamMembers() {
    this.websiteService.GetSorting().subscribe(res => {
      if (res) {
        this.ourTeamMemberList = [];
        this.ourTeamMemberList = Object.assign(this.ourTeamMemberList, res);
        const team = this.ourTeamMemberList.sort(x => x.sorting).filter(y => y.sorting !== 0);
        const teamwithzero = this.ourTeamMemberList.filter(y => y.sorting === 0);
        this.ourTeamMemberList = team;
        this.ourTeamMemberList.forEach((item, index) => {
          if (item.coverPhoto !== null) {
            item.coverPhoto = "assets" + item.coverPhoto.substr(2);
          }
          if (item.imagePath !== null) {
            item.imagePath = "assets" + item.coverPhoto.substr(2);
          }


        });
        for (const item of teamwithzero) {
          this.ourTeamMemberList.push(item);
        }
      }
    },
      res => {
        console.log("Error Message: " + res);
        // this.notifyService.showError("Info", "No Services Found!");
      });
  }
  GoToDetails(id) {
    this.profileId = id;
    this.router.navigate(['/teamprofile'], { queryParams: { id: this.profileId } });

  }
}
