<div id="content-wrapper">


  <div id="single-services-page" class="category-page">

    <section class="content-section">
      <div class="custom-container remove-bottom-padding">
        <div class="section-title type-full-width type-small-margin main-title d-flex justify-content-between align-items-start">
          <div>
            <h5>
              Dedicated to Wellbeing
            </h5>
            <h1>
              {{subServicesDetails.title}}
            </h1>
            <div class="content">

              <p>{{subServicesDetails.introduction}}</p>

            </div>
          </div>

          <div>
            <button class=" btn btn-buy-now" data-toggle="modal" data-target="#packageFormModal">Book Now</button>
          </div>
        </div>

        <div class="section-content">
          <!-- <div class="image-container">
            <img [src]="subServicesDetails.imagePath" alt="" width="1260" height="600">
          </div> -->
          <div class="content">
            <p [innerHTML]="subServicesDetails.content"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<div id="lightboxOverlay" class="lightboxOverlay" style="display: none;"></div>
<div id="lightbox" class="lightbox" style="display: none;">
  <div class="lb-outerContainer">
    <div class="lb-container">
      <img alt="wecare-logo" class="lb-image" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==">
      <div class="lb-nav"><a class="lb-prev" href="http://wecarehealthcenter.org/"></a><a class="lb-next" routerLink="/"></a></div>
      <div class="lb-loader"><a class="lb-cancel"></a></div>
    </div>
  </div>
  <div class="lb-dataContainer">
    <div class="lb-data">
      <div class="lb-details"><span class="lb-caption"></span><span class="lb-number"></span></div>
      <div class="lb-closeContainer"><a class="lb-close"></a></div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="packageFormModal" tabindex="-1" role="dialog" aria-labelledby="packageFormModalTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content packageForm">
      <div class="modal-header">
        <h5 class="modal-title PackageSelected text-white" id="Title">{{subServicesDetails.title}} </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <!-- (click)="resetError()" -->
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-home-sample-collection-form></app-home-sample-collection-form>
      </div>
    </div>
  </div>
</div>