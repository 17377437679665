

import { Component, OnInit, AfterViewInit, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { WebsiteService } from '../websiteservice/website.service';

import { DanpheCareContact } from '../../cms/models/danphecare.cms.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DanpheCareReachUsQuicklyModel } from '../../cms/models/danphecare/danphecare.model';
import { DanphecareService } from '../services/danphe.service';
import { NotificationService } from '../services/notification.service';
import { MetaService } from '@ngx-meta/core';
/*import { trigger } from '@angular/animations';*/

import * as $ from 'jquery';

import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-danpheehr-page',
  templateUrl: './danpheehr.component.html',
  styleUrls: ["./danpheehr.component.css"]
})
export class DanpheEHRComponent implements OnInit, AfterViewInit {
 
  slides = [
    { img: "fal fa-procedures text-primary", title:"Inpatient & ward"},
    { img: "fas fa-user-injured text-danger", title:"ICU/CCU"},
    { img: "far fa-stethoscope text-warning", title: "Outpatient"},
    { img: "fas fa-hospital-user text-secondary", title: "Departmental Integration" },
    
    { img: "fad fa-vials text-success", title: "Laboratory"},
    { img: "fal fa-inventory text-info", title: "Inventory"},
    { img: "fas fa-x-ray text-primary", title: "Radiology Module"},
    { img: "fal fa-file-invoice-dollar text-success", title: "General Account" },
    
    { img: "fal fa-ambulance text-info", title: "Emergency"},
    { img: "fal fa-prescription-bottle-alt text-warning", title: "Pharmacy"},
    { img: "fad fa-file-medical-alt text-danger", title: "ADT"},
    { img: "fad fa-money-check-alt text-info", title: "Insurance"},
    

  ];
  title = [
    {img: "Inpatient & ward"},
    {img: "ICU/CCU"},
    {img: "Outpatient"},
    { img: "Departmental Integration" },
    
    {img: "Laboratory"},
    {img: "Inventory"},
    {img: "Radiology Module"},
    { img: "General Account" },
    
    {img: "Emergency"},
    {img: "Pharmacy"},
    {img: "ADT"},
    { img: "Insurance" },
    

  ];
  slideConfig35 = {
    "slidesToShow": 5, "slidesToScroll": 5, "infinite": false, "dots": false, "autoplay": true, "autoplaySpeed": 5000, 'rows': 2, "arrows": true,
responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
  };
  slideConfig = { "slidesToShow": 6, "slidesToScroll": 6, "infinite": false, "dots": false, "autoplay": true, "autoplaySpeed": 5000, 'rows': 2, "arrows":true };
  slideConfigHC = {"slidesToShow": 5, "slidesToScroll": 5, "infinite": false, "dots": false, "autoplay": true, "autoplaySpeed": 5000, 'rows': 2, responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]};
  slideConfigMS = {
    "slidesToShow": 3, "slidesToScroll": 1, "infinite": false, "dots": false, "autoplay": true, "autoplaySpeed": 5000, 'rows': 1
,
responsive: [
    {
      breakpoint: 1024,
      settings: "unslick"
    },
    {
      breakpoint: 600,
      settings: "unslick"
    },
    {
      breakpoint: 480,
      settings: "unslick"
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
  };
  
  slickInit(e) {
    console.log('slick initialized');
  }
  public DhcareRuq: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public DhcareRuq1: DanpheCareReachUsQuicklyModel = new DanpheCareReachUsQuicklyModel();
  public contact: DanpheCareContact = new DanpheCareContact();
  public loading: boolean = false;
  public loading1: boolean = false;
  public submitted: boolean = false;
  public submitted1: boolean = false;
  public isCompany: boolean = false;
  public form: FormGroup;
  public form1: FormGroup;

 

  constructor(public websiteService: WebsiteService, private notifyService: NotificationService, private formBuilder: FormBuilder,
    public danphecareservice: DanphecareService, private meta: MetaService, private elementRef: ElementRef, @Inject(PLATFORM_ID) private platformId) {
    //
  }

  ngOnInit() {
    //this.meta.setTitle("An initiative by Danphe Care and Hamro Patro");
    //this.meta.setTag("description", "Committed to your Health and Wellbeing. Now offering tele medicine services for covid patients");
    //this.meta.setTag('og:title', "An initiative by Danphe Care and Hamro Patro");
    //this.meta.setTag('og:image', "https://cms.danphecare.com/assets/images/hamropatrosocialimage.jpg");
    //this.meta.setTag('og:image:width', "800");
    //this.meta.setTag('og:image:height', "800");
    //this.meta.setTag('og:url', location.href);
    //this.meta.setTag('og:type', "Hamro Patro");
    //this.meta.setTag('og:description', "Committed to your Health and Wellbeing. Now offering tele medicine services for covid patients");
    //this.meta.setTag('twitter:card', location.href);
    //this.meta.setTag('twitter:title', "An initiative by Danphe Care and Hamro Patro");
    //this.meta.setTag('twitter:description', "Committed to your Health and Wellbeing. Now offering tele medicine services for covid patients");
    //this.meta.setTag('twitter:image', "https://cms.danphecare.com/assets/images/hamropatrosocialimage.jpg");
    //this.meta.setTag('og:app_id', "260103905625004");
    this.GetContact();
    if (isPlatformBrowser(this.platformId)) {

     /* this.loadSlider();*/
    }
 

    this.form = this.formBuilder.group({
      phone: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)],
      ]
    });

    this.form1 = this.formBuilder.group({
      phone: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)],]
    });


  }

  ngAfterViewInit() {
    //const s = document.createElement("script");
    //s.type = "text/javascript";
    //s.src = "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js";
    //this.elementRef.nativeElement.appendChild(s);
  }

  //loadSlider() {

  //  const slicks = new slick();
  //  slicks.init({
  //    autoplay: {
  //      slidesToShow: 6,
  //      slidesToScroll: 1,
  //      dots: true,
  //      autoplay: true,
  //      infinite: true,
  //      autoplaySpeed: 5000,
  //      prevArrow: $('.prev'),
  //      nextArrow: $('.next'),
  //      responsive: [
  //        {
  //          breakpoint: 1100,
  //          settings: {
  //            arrows: false,
  //            slidesToShow: 4,
  //            slidesToScroll: 4
  //          }
  //        },
  //        {
  //          breakpoint: 768,
  //          settings: {
  //            arrows: false,
  //            slidesToShow: 3,
  //            slidesToScroll: 3
  //          }
  //        },
  //        {
  //          breakpoint: 550,
  //          settings: {
  //            arrows: false,
  //            slidesToShow: 2,
  //            slidesToScroll: 2
  //          }
  //        },
  //        {
  //          breakpoint: 480,
  //          settings: {
  //            arrows: false,
  //            slidesToShow: 1,
  //            slidesToScroll: 1
  //          }
  //        }
  //      ]
  //    },
  //      center: {
  //        centerMode: true,
  //        slidesToShow: 3,
  //        prevArrow: false,
  //        nextArrow: false,
  //        responsive: [
  //          {
  //            breakpoint: 768,
  //            settings: {
  //              arrows: false,
  //              centerMode: true,
  //              centerPadding: '40px',
  //              slidesToShow: 1
  //            }
  //          },
  //          {
  //            breakpoint: 480,
  //            settings: {
  //              arrows: false,
  //              centerMode: true,
  //              centerPadding: '10px',
  //              slidesToShow: 1
  //            }
  //          }
  //        ]
  //      }
  //  });
  //  //$('.autoplay').slick({
  //  //  slidesToShow: 6,
  //  //  slidesToScroll: 1,
  //  //  dots: true,
  //  //  autoplay: true,
  //  //  infinite: true,
  //  //  autoplaySpeed: 5000,
  //  //  prevArrow: $('.prev'),
  //  //  nextArrow: $('.next'),
  //  //  responsive: [
  //  //    {
  //  //      breakpoint: 1100,
  //  //      settings: {
  //  //        arrows: false,
  //  //        slidesToShow: 4,
  //  //        slidesToScroll: 4
  //  //      }
  //  //    },
  //  //    {
  //  //      breakpoint: 768,
  //  //      settings: {
  //  //        arrows: false,
  //  //        slidesToShow: 3,
  //  //        slidesToScroll: 3
  //  //      }
  //  //    },
  //  //    {
  //  //      breakpoint: 550,
  //  //      settings: {
  //  //        arrows: false,
  //  //        slidesToShow: 2,
  //  //        slidesToScroll: 2
  //  //      }
  //  //    },
  //  //    {
  //  //      breakpoint: 480,
  //  //      settings: {
  //  //        arrows: false,
  //  //        slidesToShow: 1,
  //  //        slidesToScroll: 1
  //  //      }
  //  //    }
  //  //  ]
  //  //});





  //  //$('.center').slick({
  //  //  centerMode: true,
  //  //  slidesToShow: 3,
  //  //  prevArrow: false,
  //  //  nextArrow: false,
  //  //  responsive: [
  //  //    {
  //  //      breakpoint: 768,
  //  //      settings: {
  //  //        arrows: false,
  //  //        centerMode: true,
  //  //        centerPadding: '40px',
  //  //        slidesToShow: 1
  //  //      }
  //  //    },
  //  //    {
  //  //      breakpoint: 480,
  //  //      settings: {
  //  //        arrows: false,
  //  //        centerMode: true,
  //  //        centerPadding: '10px',
  //  //        slidesToShow: 1
  //  //      }
  //  //    }
  //  //  ]
  //  //});

  //  //$(document).ready(function () {
  //  //  $('.happy-clients-carousel').slick({
  //  //    rows: 1,
  //  //    slidesToShow: 5,
  //  //    slidesToScroll: 5,
  //  //    autoplay: false,
  //  //    arrows: false,
  //  //    infinite: false,
  //  //    draggable: true,
  //  //    dots: true,
  //  //    speed: 2000,
  //  //    responsive: [
  //  //      {
  //  //        breakpoint: 1024,
  //  //        settings: {
  //  //          slidesToShow: 3,
  //  //          slidesToScroll: 3,
  //  //          infinite: true,
  //  //          dots: true
  //  //        }
  //  //      },
  //  //      {
  //  //        breakpoint: 600,
  //  //        settings: {
  //  //          slidesToShow: 2,
  //  //          slidesToScroll: 2,
  //  //          dots: true,
  //  //        }
  //  //      },
  //  //      {
  //  //        breakpoint: 480,
  //  //        settings: {
  //  //          slidesToShow: 2,
  //  //          slidesToScroll: 2,
  //  //          arrows: false,
  //  //          dots: true
  //  //        }
  //  //      }
  //  //    ]
  //  //  });
  //  //});
  //}

  get phoneControl1() {
    return this.form.get("phone");
  }
  get emailControl1() {
    return this.form.get("email");
  }
  get nameControl1() {
    return this.form.get("name");
  }

  get phoneControl2() {
    return this.form1.get("phone");
  }
  get emailControl2() {
    return this.form1.get("email");
  }
  get nameControl2() {
    return this.form1.get("name");
  }


  GetContact() {
    this.websiteService.GetContact().subscribe(res => {
      if (res && res.length > 0) {
        this.contact = Object.assign(this.contact, res[0]);

      }
    },
      res => {
        //this.notifyService.showError("Error", "Internal Error")
      });
  }

  onSubmitReachUsQuickly() {

    if (this.form.valid) {
      this.loading = true;
      this.danphecareservice.reachUsQuickly(this.DhcareRuq)
        .subscribe(res => this.SuccessPostReachUsQuickly(res),
          res => this.Error(res));

    }
    else {
      this.loading = false;
      this.submitted = true;
      this.notifyService.showInfo("Info", "Please enter the required field");
      (this.submitted) ? setTimeout(() => { this.submitted = false }, 5250) : null
    }

  }
  SuccessPostReachUsQuickly(res) {
    this.loading = false;
    this.DhcareRuq = new DanpheCareReachUsQuicklyModel();
    this.notifyService.showSuccess('Success', 'application submitted successfully ');
    this.form.reset();
  }

  onSubmitReachUsQuickly1() {

    if (this.form1.valid) {
      this.loading1 = true;
      this.danphecareservice.reachUsQuickly(this.DhcareRuq1)
        .subscribe(res => this.SuccessPostReachUsQuickly1(res),
          res => this.Error(res));

    }
    else {
      this.loading1 = false;
      this.submitted1 = true;
      this.notifyService.showInfo("Info", "Please enter the required field");
      (this.submitted1) ? setTimeout(() => { this.submitted1 = false }, 5250) : null
    }

  }
  SuccessPostReachUsQuickly1(res) {
    this.loading1 = false;
    this.DhcareRuq1 = new DanpheCareReachUsQuicklyModel();
    this.notifyService.showSuccess('Success', 'application submitted successfully ');
    this.form1.reset();
  }

  Error(res) {
    this.loading = false;
    const response = res;
    this.notifyService.showError("Error", " Please fill up the required field")
  }
}

