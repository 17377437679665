
<div class="section page-top">
  <div class="ver-2 text-center">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="justify-content-center hero-text-center">
            <div class="hero-text mt-0"><b>Our Team</b></div>
            <h4>Dedicated team for your care</h4>
          </div>
        </div>
        <div class="col text-right">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="index.html">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Team</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section padding-top-bottom over-hide ">
  <div class="container">
    <div class="row">



      <!--
          <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
              <div class="team-div">
                <div class="front"> <img src="img/binod.jpg" alt=""> </div>
                <div class="bx-widget-content">
                  <h4>Dr. Binod Dhungana <span>Chief Business Officer</span></h4>
                  <p><b>Internal Medicine</b></p>
                  <p>MBBS- Hebei Medical College, PR China</p>
                </div>
              </div>
            </div>
      -->
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" *ngFor="let data of ourTeamMemberList; let i = index;">
        <div class="box-widget">
          <div class="bx-img-wrap"> <img [src]="data.imagePath" alt="" (click)="GoToDetails(data.teamMemberId)"> </div>
          <div class="bx-widget-content">
            <h4><a (click)="GoToDetails(data.teamMemberId)">{{data.fullName}}</a> <span>{{data.designation}}</span></h4>

            <p>{{data.shortDescription}}</p>
            <hr>
            <p><b>{{data.department}}</b></p>
            <hr />
            <!-- <button class="btn btn-info" (click)="GoToDetails(data.teamMemberId)" style="text-decoration:underline">View More</button> -->




          </div>
        </div>
      </div>

      <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
        <div class="box-widget">
          <div class="bx-img-wrap"> <img src="img/prabhat.jpg" alt=""> </div>
          <div class="bx-widget-content">
            <h4>Dr. Prabhat Adhikari <span>Chief Business Officer</span></h4>
            <p>MBBS- Hebei Medical College, PR China</p>
            <hr>
            <p><b>Infectious Disease & Critical Care</b></p>



          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
        <div class="box-widget">
          <div class="bx-img-wrap"> <img src="img/kabin.jpg" alt=""> </div>
          <div class="bx-widget-content">
            <h4>Phr. Kabin Malekhu <span>Chief Business Officer</span></h4>
            <p>MBBS- Hebei Medical College, PR China</p>
            <hr>
            <p><b>Program Coordinator</b></p>



          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
        <div class="box-widget">
          <div class="bx-img-wrap"> <img src="img/samikshya.jpg" alt=""> </div>
          <div class="bx-widget-content">
            <h4>RN Samikshya Koirala <span>Nurse, Infection Control</span></h4>
            <p>MBBS- Hebei Medical College, PR China</p>
            <hr>
            <p><b>Internal Medicine</b></p>



          </div>
        </div>
      </div> -->
    </div>
  </div>
</section>

<style>
  .light-over-pages {
    position: absolute;
    background: linear-gradient(180deg, rgb(255 255 255 / 70%), rgb(189 189 189 / 40%));
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    top: 0;
    left: 0;
  }

  .list-style {
    padding: 0;
  }

    .list-style li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin: 5px 0;
    }

      .list-style li span {
        display: block;
      }

      .list-style li:before {
        content: "\f00c";
        font-family: FontAwesome;
        background-color: #0db696;
        color: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 15px;
        height: 15px;
        align-items: center;
        font-size: 0.7rem;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 0;
      }

  .home-isolation .sub-heading, .emmergency-info .sub-heading {
    font-weight: 700;
    font-size: 1.5rem;
  }

  .center-title {
  }

    .center-title h4 {
    }
</style>
