<style>
  .service-section .section-title {
    display: none;
  }
</style>

<div id="content-wrapper">
  <div id="service-page">
    <section class="service-title-section">
      <div class="custom-container remove-bottom-padding service-padding">
        <div class="section-title main-title">
          <h5>Best in town</h5>
          <h1>Our Services</h1>
        </div>
        <div class="section-content"> </div>
      </div>
    </section>
    <section class="service-section">
      <div class="custom-container remove-top-padding pb-1">
        <div class="section-title type-smaller-margin">
          <h6>Services</h6>
        </div>
        <div class="section-content">
          <div class="common-service-container">

            <div class="service-item" *ngFor="let service of servicesList">
              <div class="image-container">
                <a [routerLink]="[ '/service', service.permaLink ]">
                  <img src="{{service.imagePath}}" alt="{{service.serviceName}}" width="1260" height="600"> </a>
              </div>
              <div class="text-container">
                <h5><a [routerLink]="[ '/service', service.permaLink ]"></a>{{service.serviceName}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
