<div id="content-wrapper">


  <div id="single-doctor-page">


    <section>
      <div class="custom-container" style="margin-top: 11rem;">
        <div class="block-container">
          <div class="block-item">
            <div class="image-container">
              <img [src]="profile.imagePath"  alt="{{profile.fullName}}"
                width="326" height="316">
            </div>
          </div>
          <div class="block-item">
            <div class="title-container">
              <div class="section-title type-small-margin pt-3">
                <h3>{{profile.fullName}}</h3>
                <p>{{profile.designation}}</p>
              </div>
            </div>
            <div class="content-container">
              <!-- <h6><strong>Work Experience:&nbsp;&nbsp;{{profile.experience}} of experience</strong></h6> -->

              <div class="col-md-12" [innerHTML]="profile.content">
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>





</div>