import { Component } from "@angular/core";
import { WebsiteService } from "../../websiteservice/website.service";

@Component({
  selector: 'app-pop-up',
  templateUrl: 'popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent {
  showPopUp: boolean = true;
  popUpContent = {
    showPopUp: true,
    showHeading: false,
    Heading: "Super Offer on Packages!",
    showTextContent: false,
    PrimaryInformation: "Now, you can buy our health packages right here in this website. You can also pay using Esewa.",
    SecondaryInformation: "Go to Packages section of our website to know more.",
    showImage: true,
    imagePath: "/assets/images/popups/popup1,jpg"
  }
  constructor(public websiteService: WebsiteService) {
    this.getBlogs();
  }
  /**
   * @description used blogs for now as pop up section is not yet created.
   */
  private getBlogs() {
    this.websiteService.GetBlogs().subscribe(res => {
      if (res) {
        this.popUpContent.imagePath = res[0].imagePath;
        if (!this.popUpContent.imagePath) {
          this.popUpContent.showPopUp = false;
        }
      }
    });
  }

  closePopUp() {
    this.popUpContent.showPopUp = false;
  }

}
