

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { OurServiceModel } from '../../cms/models/OurServices.model';
import { HttpClient } from '@angular/common/http';
import { WebsiteService } from '../websiteservice/website.service';

import { Router } from '@angular/router';
import { LabEquipment, MetaTag } from '../../cms/models/danphecare.cms.model';
import { Title, Meta } from '@angular/platform-browser';
import { NotificationService } from './notification.service';
import { MetaService } from '@ngx-meta/core';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-services-page',
  templateUrl: './services.component.html'
})
export class ServicesPageComponent implements OnInit, AfterViewInit {
  public servicesList: Array<OurServiceModel> = new Array<OurServiceModel>();
  public servicesId: number;
  public metaTag: Array<MetaTag> = new Array<MetaTag>();
  public tag: MetaTag = new MetaTag();
  public services: OurServiceModel = new OurServiceModel();
  public content: string;
  labEquipmentsList: LabEquipment[] = [];
  constructor(public http: HttpClient, public websiteService: WebsiteService, public notifyService: NotificationService, public router: Router, private titleService: Title,
    private meta: MetaService) {
    //
  }

  ngOnInit() {
    this.websiteService.GetOurServices().subscribe(res => {
      if (res) {
        this.servicesList = [];
        this.servicesList = Object.assign(this.servicesList, res);
        this.meta.setTitle(this.servicesList[0].metaTitle);
        this.meta.setTag("description", this.servicesList[0].metaContent);
        this.meta.setTag("keywords", this.servicesList[0].metaKeywords);
        this.services = this.servicesList[0];
        this.servicesList.forEach((item, index) => {
          if (item.iconPath !== null) {
            item.iconPath = environment.url + item.iconPath.substr(2);
          }
          if (item.imagePath !== null) {
            item.imagePath = environment.url + item.imagePath.substr(2);
          }
          if (item.coverImage !== null) {
            item.coverImage = environment.url + item.coverImage.substr(2);
          }
          if (item.subscriptionImage !== null) {
            item.subscriptionImage = environment.url + item.subscriptionImage.substr(2);
          }

        });

      }
    },
      res => {
        //this.notifyService.showError("Info", "No Services Found!");
      });
  }
  ngAfterViewInit() {
    //this.loadScripts();
  }



  GetServices() {
    this.websiteService.GetOurServices().subscribe(res => {
      if (res) {
        this.servicesList = [];
        this.servicesList = Object.assign(this.servicesList, res);
        this.services = this.servicesList[0];
        this.servicesList.forEach((item, index) => {
          if (item.iconPath !== null) {
            item.iconPath = environment.url + item.iconPath.substr(2);
          }
          if (item.imagePath !== null) {
            item.imagePath = environment.url + item.imagePath.substr(2);
          }
          if (item.coverImage !== null) {
            item.coverImage = environment.url + item.coverImage.substr(2);
          }
          if (item.subscriptionImage !== null) {
            item.subscriptionImage = environment.url + item.subscriptionImage.substr(2);
          }

        });
      }
    },
      res => {
        //this.notifyService.showError("Info", "No Services Found!");
      });
  }



  GoToServiceDetails(permaLink) {
    this.router.navigate(['/service', permaLink]);


  }


}
