import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent  {

  constructor() { }

  // ngOnInit(): void {
  //   window.location.href = 'http://202.51.74.168:127/login';
  // }

}
